import Big from 'big.js';

const accountCoinsProvider = requestHandler => (type, resource, params) => {

    if (type === 'GET_ONE' && resource === 'accountcoins') {
        // if (params.id.includes('@')) {
        //     const [id, symbol] = params.id.split('@');
        //     params.id = id;
        //     params.symbol = symbol;
        // }

    }

    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'accountcoins') {
        resource = 'coins';
        // console.log(params);
        // const commissionRateSetting = {
        //     buy: [],
        //     sell: []
        // }
        // Object.keys(params.data).forEach(key => {
        //     if (key.startsWith('commissionRateSetting')) {
        //         if (key.includes('Buy')) {
        //             const obj = {
        //                 max: Number(key.split('_')[1]),
        //                 rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
        //             };
        //             commissionRateSetting.buy.push(obj);
        //         }
        //         if (key.includes('Sell')) {
        //             const obj = {
        //                 max: Number(key.split('_')[1]),
        //                 rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
        //             };
        //             commissionRateSetting.sell.push(obj);
        //         }
        //         delete params.data[key];
        //     }
        // });

        params.id = params.data.coinId;
        params.data.id = params.data.coinId;
        delete params.data.coinId;
        // params.data.commissionRateSetting = commissionRateSetting;
    }

    // console.log('accountCoinsProvider', type, resource, params);

    return requestHandler(type, resource, params);
}

export default accountCoinsProvider;