import React, { useState } from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	required,
	SelectInput,
	BooleanInput,
	FileInput,
	FileField,
	Toolbar,
	SaveButton,
} from "react-admin";

const CustomToolbar = (props) => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

const AppVersionEdit = ({ permissions, ...props }) => {
	return (
		<Edit title="App Version" {...props}>
			<SimpleForm redirect="list" toolbar={<CustomToolbar />}>
				<TextInput source="app" validate={[required()]} />
				<TextInput source="version" validate={[required()]} />
				<TextInput source="description" />
				<FileInput source="attachments">
					<FileField source="src" title="title" />
				</FileInput>
				<BooleanInput
					label="Status: use or not"
					source="status"
					defaultValue={false}
				/>
			</SimpleForm>
		</Edit>
	);
};

export default AppVersionEdit;
