import React, { useState } from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout,
    ReferenceField,
    ChipField,
    ImageField,
    DateField,
} from 'react-admin';
import { getUserURL } from '../api';

const ImageModal = ({ src, alt, onClose }) => (
    <div 
        style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            cursor: 'pointer'
        }}
        onClick={onClose}
    >
        <img 
            src={src} 
            alt={alt}
            style={{
                maxWidth: '90%',
                maxHeight: '90%',
                objectFit: 'contain'
            }}
            onClick={e => e.stopPropagation()}
        />
    </div>
);

const CustomImageField = ({ record, source, label }) => {
    const [showModal, setShowModal] = useState(false);
    
    const imageUrl = record && record[source] ? `${getUserURL()}/${record[source]}` : null;

    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ marginBottom: '0.5rem' }}>
                <label style={{ 
                    display: 'block', 
                    color: 'rgba(0, 0, 0, 0.54)',
                    fontSize: '0.8rem'
                }}>
                    {label}
                </label>
            </div>
            {imageUrl ? (
                <div 
                    style={{ 
                        cursor: 'pointer',
                        display: 'inline-block'
                    }}
                    onClick={() => setShowModal(true)}
                >
                    <img 
                        src={imageUrl}
                        alt={label}
                        style={{ 
                            maxWidth: '150px',
                            maxHeight: '150px',
                            objectFit: 'cover',
                            borderRadius: '4px',
                            border: '1px solid #e0e0e0'
                        }}
                    />
                </div>
            ) : (
                <div>-</div>
            )}
            {showModal && imageUrl && (
                <ImageModal 
                    src={imageUrl}
                    alt={label}
                    onClose={() => setShowModal(false)}
                />
            )}
        </div>
    );
};

const CustomDateField = ({ record, source, label }) => {
    return (
        <div style={{ marginBottom: '1rem' }}>
            <div style={{ 
                color: 'rgba(0, 0, 0, 0.54)',
                fontSize: '0.8rem',
                marginBottom: '0.5rem'
            }}>
                {label}
            </div>
            <div>
                {(!record || !record[source] || isNaN(new Date(record[source]).getTime())) 
                    ? '-'
                    : new Date(record[source]).toLocaleDateString()
                }
            </div>
        </div>
    );
};

const UserShow = ({ ...props }) => (
    <Show title="User Info" {...props}>
        <SimpleShowLayout>
            <TextField source="name" label="User ID" />
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name" />
            <TextField source="middleName" label="Middle Name" />
            <TextField source="email" label="Email" />
            <TextField source="phoneNumber" label="Phone Number" />
            <TextField source="country" label="Country" />
            <CustomDateField source="dateOfBirth" label="Date of Birth" />
            <TextField source="userType" label="User Type" />
            <CustomImageField source="idFront" label="Photo ID Front" />
            <CustomImageField source="idBack" label="Photo ID Back" />
        </SimpleShowLayout>
    </Show>
);

export default UserShow;
