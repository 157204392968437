import React from 'react';
import { NumberField, Labeled } from 'react-admin';

const CustomBuySpreadField = ({ record, ...rest }) =>{
        var platformSpread = record.systemBuySpread
        var exchangeSpread = record.exchangeBuySpread
        var customerSpread = Number(Math.round(record.buySpread+platformSpread+exchangeSpread+'e3')+'e-3');

        return  (
            <div>
            <Labeled label="Buy Spread">
            <NumberField source="buySpread" label="Buy Spread (including platform fee)"  record={record} {...rest} />
            </Labeled>
            <span style={ {fontSize : 14 , color: "grey"}}>(Note: buy spread for customer is <span style={ {color : "red"}}> {customerSpread} </span> , which includes {platformSpread} platform fee and {exchangeSpread} exchange fee.)</span>
            </div>
        )
    
}

export default CustomBuySpreadField;