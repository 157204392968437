import React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    ArrayField,
    Datagrid,
     } from 'react-admin';
import CustomURLField from './CustomURLField';

export const TransactionShow = ({permissions, ...props}) => (
    <Show title=" " {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="transaction_id" label="Transaction ID" />
            <TextField source="btcVolStr" label="Coin Amount" />
            {/* <ReferenceField label="Coin" source="coin_id" reference="coins" linkType="show">
                <TextField source="symbol" />
            </ReferenceField>
            <ReferenceField label="Location" source="location_id" reference="locations" linkType="show">
                <TextField source="nickName" />
            </ReferenceField> */}
            {/* <UrlField source="payMethod" /> */}
            <NumberField source="amount" label={`Amount (${props.record.currency})`}  />
            <NumberField source="price"  label={`Price (${props.record.currency})`}/>
            {/* <DateField source="createdAt" showTime/> */}
            <CustomURLField source="chainTxHash"  />
            <TextField source="note"  />
            {permissions === "system_admin" ?
                <ArrayField source="systemLedgers" label="System Ledgers">
                    <Datagrid>
                        <TextField source="name" label="Name" />
                        <TextField source="type" label="Type" />
                        <NumberField source="amount" label="Amount" />
                        <TextField source="currency" label="Currency" />
                        <TextField source="source" label="Source" />
                    </Datagrid>
                </ArrayField>
            : null }
        </SimpleShowLayout>
    </Show>
);


export default TransactionShow;