import React, { useState,useEffect } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
} from '@material-ui/core';

// 你需要保证有可用的 dataProvider，路径依你的项目情况而定
import dataProvider from '../dataProvider';

// 添加 getUserURL 导入
import { getUserURL } from '../api';

const COUNTRY_SYMBOL_LIST = [
    { id: 'HKG', name: 'HKG' },
    { id: 'TW', name: 'TW' },
    { id: 'CAN', name: 'CAN' },
    { id: 'USA', name: 'USA' },
];


// 验证函数
const validateFirstName = (name) => {
    if (!name) {
        return 'First Name is required.';
    }
    if (name.length < 2 || name.length > 15) {
        return 'First Name must be between 2 and 15 characters.';
    }
    return '';
};

const validateLastName = (name) => {
    if (!name) {
        return 'Last Name is required.';
    }
    if (name.length < 2 || name.length > 15) {
        return 'Last Name must be between 2 and 15 characters.';
    }
    return '';
};

const validateDOB = (dob) => {
    if (!dob) {
        return 'Date of Birth is required';
    }
    return '';
};

const validatePhone = (phone) => {
    if (!phone) {
        return 'Phone Number is required';
    }
    return '';
};

const ImagePreview = ({ imageData }) => {
    if (!imageData || !imageData.url) return null;
    
    return (
        <img 
            src={imageData.url} 
            alt="ID document" 
            style={{
                maxWidth: '200px',
                maxHeight: '150px',
                marginTop: '8px',
                borderRadius: '4px'
            }}
        />
    );
};

// 修改后的UserUpdateForm组件
const UserUpdateForm = ({ handleDialogChange, isMobile }) => {
    // 移除 email 状态
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [countrySymbol, setCountrySymbol] = useState('');
    const [dob, setDob] = useState('');
    const [phone, setPhone] = useState('');
    const [idFront, setIdFront] = useState(null);
    const [idBack, setIdBack] = useState(null);
    
    // 从错误状态中移除 email
    const [errors, setErrors] = useState({
        dob: '',
        phone: '',
        firstName: '',
        lastName: '',
        countrySymbol: '',
    });

    const handleFirstNameChange = (e) => {
        const name = e.target.value;
        setFirstName(name);
        const error = validateFirstName(name);
        setErrors((prevErrors) => ({ ...prevErrors, firstName: error }));
    };

    const handleLastNameChange = (e) => {
        const name = e.target.value;
        setLastName(name);
        const error = validateLastName(name);
        setErrors((prevErrors) => ({ ...prevErrors, lastName: error }));
    };

    const handleImageUpload = async (file, type) => {
        const formData = new FormData();
        formData.append('image', file);

        try {
            const baseUrl = getUserURL();
            const response = await fetch(`${baseUrl}/v1/files/image`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('BITFA:DASHBOARD:AUTH:TOKEN')}`,
                }
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            
            // 从完整URL中提取文件路径
            const filePath = data.filePath;
            
            if (type === 'front') {
                setIdFront({
                    url: data.fileUrl,    // 用于显示预览
                    path: filePath        // 用于提交
                });
            } else {
                setIdBack({
                    url: data.fileUrl,    // 用于显示预览
                    path: filePath        // 用于提交
                });
            }
        } catch (error) {
            console.error('Failed to upload image:', error);
            alert('Failed to upload image');
        }
    };

    const handleSubmit = async () => {
        // 移除 email 验证
        const dobError = validateDOB(dob);
        const phoneError = validatePhone(phone);
        const firstNameError = validateFirstName(firstName);
        const lastNameError = validateLastName(lastName);
        
        setErrors({
            dob: dobError,
            phone: phoneError,
            firstName: firstNameError,
            lastName: lastNameError,
        });

        if (
            dobError ||
            phoneError ||
            firstNameError ||
            lastNameError ||
            !countrySymbol
        ) {
            return;
        }

        const data = {
            firstName,
            lastName,
            middleName,
            dateOfBirth: dob,
            country: countrySymbol,
            phoneNumber: phone,
            idFront: (idFront && idFront.path) || '',
            idBack: (idBack && idBack.path) || '',
        };
        
        try {
            dataProvider('UPDATE', 'user/self', { data }).then(({ response }) => {
                localStorage.setItem('BITFA:DASHBOARD:REGISTER:STEP', "2");
                if(handleDialogChange){
                    handleDialogChange();
                }
            }).catch((error) => {
                console.log("error2", error);
                alert('Failed to update user info.');
            });
        } catch (error) {
            console.log("error1", error);
            alert('Failed to update user info.');
        }
    };

    return (
        <div style={{ 
            width: isMobile ? '100%' : 500, 
            padding: isMobile ? 10 : 20 
        }}>
            <Typography variant="h6" style={{ marginBottom: 16 }}>
                Basic Information
            </Typography>

            <TextField
                label="First Name *"
                value={firstName}
                onChange={handleFirstNameChange}
                fullWidth
                style={{ 
                    marginBottom: isMobile ? 8 : 16,
                    fontSize: isMobile ? 14 : 16 
                }}
                error={!!errors.firstName}
                helperText={errors.firstName}
            />
            
            <TextField
                label="Last Name *"
                value={lastName}
                onChange={handleLastNameChange}
                fullWidth
                style={{ marginBottom: 16 }}
                error={!!errors.lastName}
                helperText={errors.lastName}
            />
            
            <TextField
                label="Middle Name"
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
            />

            <TextField
                label="Date of Birth *"
                type="date"
                value={dob}
                onChange={(e) => {
                    setDob(e.target.value);
                    setErrors({...errors, dob: validateDOB(e.target.value)});
                }}
                fullWidth
                style={{ marginBottom: 16 }}
                InputLabelProps={{
                    shrink: true,
                }}
                error={!!errors.dob}
                helperText={errors.dob}
            />

            <Typography variant="body1" style={{ margin: '8px 0' }}>
                Nationality *
            </Typography>
            <Select
                value={countrySymbol}
                onChange={(e) => setCountrySymbol(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
                displayEmpty
            >
                {COUNTRY_SYMBOL_LIST.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                        {item.name}
                    </MenuItem>
                ))}
            </Select>

            <TextField
                label="Phone Number *"
                value={phone}
                onChange={(e) => {
                    setPhone(e.target.value);
                    setErrors({...errors, phone: validatePhone(e.target.value)});
                }}
                fullWidth
                style={{ marginBottom: 16 }}
                error={!!errors.phone}
                helperText={errors.phone}
            />

            <Typography variant="h6" style={{ margin: '24px 0 16px' }}>
                Photo ID
            </Typography>
            
            <div style={{ marginBottom: 16 }}>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="id-front-upload"
                    type="file"
                    onChange={(e) => handleImageUpload(e.target.files[0], 'front')}
                />
                <label htmlFor="id-front-upload">
                    <Button variant="contained" component="span">
                        Front
                    </Button>
                </label>
                {idFront && (
                    <div>
                        <Typography variant="body2" style={{ margin: '8px 0' }}>
                            Front image uploaded
                        </Typography>
                        <ImagePreview imageData={idFront} />
                    </div>
                )}
            </div>

            <div style={{ marginBottom: 16 }}>
                <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="id-back-upload"
                    type="file"
                    onChange={(e) => handleImageUpload(e.target.files[0], 'back')}
                />
                <label htmlFor="id-back-upload">
                    <Button variant="contained" component="span">
                        Back
                    </Button>
                </label>
                {idBack && (
                    <div>
                        <Typography variant="body2" style={{ margin: '8px 0' }}>
                            Back image uploaded
                        </Typography>
                        <ImagePreview imageData={idBack} />
                    </div>
                )}
            </div>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                style={{
                    marginTop: isMobile ? 8 : 16,
                    height: isMobile ? 36 : 42
                }}
            >
                Save
            </Button>
        </div>
    );
}

export default UserUpdateForm;

