import FundingIcon from '@material-ui/icons/AccountBalance';
import FundingList from './FundingList';
import FundingEdit from './FundingEdit';
import FundingCreate from './FundingCreate';
import FundingShow from './FundingShow';

export default {
    list: FundingList,
    show: FundingShow,
    edit: FundingEdit,
    create: FundingCreate,
    icon: FundingIcon,
};
