import AppVersionList from './AppVersionList'
import Icon from '@material-ui/icons/PermIdentity';
import AppVersionCreate from './AppVersionCreate';
import AppVersionEdit from './AppVersionEdit';

export default {
    list: AppVersionList,
    create: AppVersionCreate,
    edit: AppVersionEdit,
    icon: Icon,
};
