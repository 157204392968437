var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import { addField, translate, FieldTitle } from 'ra-core';
import sanitizeRestProps from './sanitizeRestProps';
var styles = createStyles({
  label: {
    position: 'relative'
  }
});
/**
 * An Input component for a radio button group, using an array of objects for the options
 *
 * Pass possible options as an array of objects in the 'choices' attribute.
 *
 * By default, the options are built from:
 *  - the 'id' property as the option value,
 *  - the 'name' property an the option text
 * @example
 * const choices = [
 *    { id: 'M', name: 'Male' },
 *    { id: 'F', name: 'Female' },
 * ];
 * <RadioButtonGroupInput source="gender" choices={choices} />
 *
 * You can also customize the properties to use for the option name and value,
 * thanks to the 'optionText' and 'optionValue' attributes.
 * @example
 * const choices = [
 *    { _id: 123, full_name: 'Leo Tolstoi', sex: 'M' },
 *    { _id: 456, full_name: 'Jane Austen', sex: 'F' },
 * ];
 * <RadioButtonGroupInput source="author_id" choices={choices} optionText="full_name" optionValue="_id" />
 *
 * `optionText` also accepts a function, so you can shape the option text at will:
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const optionRenderer = choice => `${choice.first_name} ${choice.last_name}`;
 * <RadioButtonGroupInput source="author_id" choices={choices} optionText={optionRenderer} />
 *
 * `optionText` also accepts a React Element, that will be cloned and receive
 * the related choice as the `record` prop. You can use Field components there.
 * @example
 * const choices = [
 *    { id: 123, first_name: 'Leo', last_name: 'Tolstoi' },
 *    { id: 456, first_name: 'Jane', last_name: 'Austen' },
 * ];
 * const FullNameField = ({ record }) => <span>{record.first_name} {record.last_name}</span>;
 * <RadioButtonGroupInput source="gender" choices={choices} optionText={<FullNameField />}/>
 *
 * The choices are translated by default, so you can use translation identifiers as choices:
 * @example
 * const choices = [
 *    { id: 'M', name: 'myroot.gender.male' },
 *    { id: 'F', name: 'myroot.gender.female' },
 * ];
 *
 * However, in some cases (e.g. inside a `<ReferenceInput>`), you may not want
 * the choice to be translated. In that case, set the `translateChoice` prop to false.
 * @example
 * <RadioButtonGroupInput source="gender" choices={choices} translateChoice={false}/>
 *
 * The object passed as `options` props is passed to the material-ui <RadioButtonGroup> component
 */
var RadioButtonGroupInput = /** @class */function (_super) {
  __extends(RadioButtonGroupInput, _super);
  function RadioButtonGroupInput() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.handleChange = function (event, value) {
      _this.props.input.onChange(value);
    };
    _this.renderRadioButton = function (choice) {
      var _a = _this.props,
        optionText = _a.optionText,
        optionValue = _a.optionValue,
        translate = _a.translate,
        translateChoice = _a.translateChoice,
        source = _a.source;
      var choiceName = React.isValidElement(optionText) // eslint-disable-line no-nested-ternary
      ? React.cloneElement(optionText, {
        record: choice
      }) : typeof optionText === 'function' ? optionText(choice) : get(choice, optionText);
      var nodeId = source + "_" + get(choice, optionValue);
      return React.createElement(FormControlLabel, {
        htmlFor: nodeId,
        key: get(choice, optionValue),
        value: get(choice, optionValue),
        control: React.createElement(Radio, {
          id: nodeId,
          color: "primary"
        }),
        label: translateChoice ? translate(choiceName, {
          _: choiceName
        }) : choiceName
      });
    };
    return _this;
  }
  RadioButtonGroupInput.prototype.render = function () {
    var _a = this.props,
      classes = _a.classes,
      className = _a.className,
      label = _a.label,
      resource = _a.resource,
      source = _a.source,
      input = _a.input,
      isRequired = _a.isRequired,
      choices = _a.choices,
      options = _a.options,
      meta = _a.meta,
      rest = __rest(_a, ["classes", "className", "label", "resource", "source", "input", "isRequired", "choices", "options", "meta"]);
    if (typeof meta === 'undefined') {
      throw new Error("The RadioButtonGroupInput component wasn't called within a redux-form <Field>. Did you decorate it and forget to add the addField prop to your component? See https://marmelab.com/react-admin/Inputs.html#writing-your-own-input-component for details.");
    }
    var touched = meta.touched,
      error = meta.error,
      _b = meta.helperText,
      helperText = _b === void 0 ? false : _b;
    return React.createElement(FormControl, __assign({
      component: "fieldset",
      className: className,
      margin: "normal"
    }, sanitizeRestProps(rest)), React.createElement(InputLabel, {
      component: "legend",
      shrink: true,
      className: classes.label
    }, React.createElement(FieldTitle, {
      label: label,
      source: source,
      resource: resource,
      isRequired: isRequired
    })), React.createElement(RadioGroup, __assign({
      name: source,
      value: input.value,
      onChange: this.handleChange
    }, options), choices.map(this.renderRadioButton)), touched && error && React.createElement(FormHelperText, {
      error: true
    }, error), helperText && React.createElement(FormHelperText, null, helperText));
  };
  return RadioButtonGroupInput;
}(Component);
export { RadioButtonGroupInput };
RadioButtonGroupInput.propTypes = {
  choices: PropTypes.arrayOf(PropTypes.object),
  classes: PropTypes.object,
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.element]).isRequired,
  optionValue: PropTypes.string.isRequired,
  resource: PropTypes.string,
  source: PropTypes.string,
  translate: PropTypes.func.isRequired,
  translateChoice: PropTypes.bool.isRequired,
  meta: PropTypes.object
};
RadioButtonGroupInput.defaultProps = {
  classes: {},
  choices: [],
  options: {},
  optionText: 'name',
  optionValue: 'id',
  translateChoice: true
};
export default compose(addField, translate, withStyles(styles))(RadioButtonGroupInput);