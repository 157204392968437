import React from 'react';
import {
    Show,
    Datagrid,
    TextField,
    ReferenceManyField
} from 'react-admin';

const AccountCoinShow = ({ permissions, ...props }) => {

    const handleRowClick = (id, basePath, record) => {
        const currId = window.location.href.split('#')[1].split('/')[2];
        window.location.href = `#/accountcoins/${currId}@${record.symbol}`;
    }

    return (
        <Show {...props} actions={null} title={'coins for account'}>
            <ReferenceManyField reference="accountcoins" addLabel={false}>
                <Datagrid rowClick={handleRowClick}>
                    <TextField source="symbol" key="symbol" />
                    <TextField source="currency" key="currency" />
                </Datagrid>
            </ReferenceManyField>
    
        </Show>
    )
};

export default AccountCoinShow;
