import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    EmailField,
    NumberField,
    ReferenceField, NumberInput,
    CardActions,
    EditButton,

} from 'react-admin';

import Button from '../common/Button'

import CustomMFAField from "./CustomMFAField"
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
    marginLeft: '1rem'
};
const AccountShow = ({permissions, classes, ...props}) => {
    const isAdmin = permissions === 'system_admin';

    return (
    <Show title="Account" {...props} actions={<MyShowActions isAdmin={isAdmin} />}>
        <TabbedShowLayout>
            <Tab label="resources.accounts.tabs.identity">
                <TextField
                    source="companyName" formClassName={classes.inlineBlock}
                />
                <TextField
                    source="registrationNumber" formClassName={classes.inlineBlock}
                />
                <TextField
                    source="formationDate" formClassName={classes.inlineBlock}
                />
                <TextField
                    source="countrySymbol" formClassName={classes.inlineBlock}
                />
                <TextField
                    source="currencySymbol" formClassName={classes.inlineBlock}
                />
                <EmailField
                    source="email"
                />
                <TextField source="accountNumber" />
                {(permissions === 'operator' || permissions === 'system_admin') ? [
                    <TextField source="creditLimit" label="Credit Limit" />,
                    <TextField source="notifyUpperLimit" label="Sell Notify Upper Limit" />
                ] : null
                }
            </Tab>
            <Tab label="resources.accounts.tabs.address">
                <TextField source="address.address1" label="Address"/>
                <TextField source="address.city" label="City" />
                <TextField source="address.state" label="State"/>
                <TextField source="address.postalCode" label="Postal Code"/>
                <TextField source="address.country" label="Country" />
            </Tab>
            <Tab label="resources.accounts.tabs.bank_information">
                <TextField 
                    source="bankAccountName" 
                    label="Bank Account Name"
                    formClassName={classes.inlineBlock}
                />
                <TextField 
                    source="bankAccountNumber" 
                    label="Bank Account Number"
                    formClassName={classes.inlineBlock}
                />
                <TextField 
                    source="bankAccountType" 
                    label="Bank Account Type"
                    formClassName={classes.inlineBlock}
                />
                <TextField 
                    source="bankTransitNumber" 
                    label="Bank Transit Number"
                    formClassName={classes.inlineBlock}
                />
                <TextField 
                    source="bankInstitutionNumber" 
                    label="Bank Institution Number"
                    formClassName={classes.inlineBlock}
                />
            </Tab>
            {/* <Tab label="resources.accounts.tabs.credit">
                <TextField source="countrySymbol" />
                <TextField source="accountNumber" />
                <NumberField source="credit"/>
            </Tab> */}
            <Tab label="resources.accounts.tabs.settings">
                <CustomMFAField source="isMFA" label="Multi Factor Authentication"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
    );
};

export const MyShowActions = ({ isAdmin, basePath, data, resource }) => {

    const onEditCoinRate = () => {
        console.log("onEditCoinRate", data);
        window.location.href = `#/accountcoins/${data.id}/show`;
    }

    return (
        <CardActions style={cardActionStyle}>
            <EditButton basePath={basePath} record={data} />
            {isAdmin && <Button color="primary" variant="contained" size="small" onClick={onEditCoinRate}>Edit coin rate</Button>}
        </CardActions>
    )
}


export default withStyles(styles)(AccountShow);
