import Big from 'big.js';

const coinsProvider = requestHandler => (type, resource, params) => {

    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'coins') {
        console.log("coinsProvider::params.data", params.data);
        const commissionRateSetting = {
            buy: [],
            sell: []
        }
        Object.keys(params.data).forEach(key => {
            if (key.startsWith('commissionRateSetting')) {
                if (key.includes('Buy')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    commissionRateSetting.buy.push(obj);
                }
                if (key.includes('Sell')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    commissionRateSetting.sell.push(obj);
                }
                delete params.data[key];
            }
        });
        params.data.commissionRateSetting = commissionRateSetting;
    }

    return requestHandler(type, resource, params);
}

export default coinsProvider;