import CoinIcon from '@material-ui/icons/Album';

import CoinList from './CoinList';
import CoinEdit from './CoinEdit';
import CoinShow from './CoinShow';
import CoinCreate from './CoinCreate';


export default {
    list: CoinList,
    edit: CoinEdit,
    show: CoinShow,
    create: CoinCreate,
    icon: CoinIcon,
};
