"use strict";

function __export(m) {
  for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", {
  value: true
});
__export(require("./crudCreate"));
__export(require("./crudDelete"));
__export(require("./crudDeleteMany"));
__export(require("./crudGetAll"));
__export(require("./crudGetList"));
__export(require("./crudGetMany"));
__export(require("./crudGetManyReference"));
__export(require("./crudGetMatching"));
__export(require("./crudGetOne"));
__export(require("./crudUpdate"));
__export(require("./crudUpdateMany"));