import React, { useState } from 'react';
import Jquery from 'jquery';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    BooleanInput,
    required,
    email, NumberInput,
} from 'react-admin';

export const validateEmail = [required(), email()];
export const validateRequired = [required()];



export const inputStyle = { width: '20em' }

const UserCreate = ({ permissions, ...props }) => {
    let parentUserCommissionRateSetting = JSON.parse(localStorage.getItem('BITFA:DASHBOARD:AUTH:commissionRateSetting'));
    const [showOperatorCommissionSettings, setShowOperatorCommissionSettings] = useState(false);
    const [showPromoterCommissionSettings, setShowPromoterCommissionSettings] = useState(false);
    const [showStoreCommissionSettings, setShowStoreCommissionSettings] = useState(false);
    const [showSalesCommissionSettings, setShowSalesCommissionSettings] = useState(false);
    const [showAllowCommissionSettings, setShowAllowCommissionSettings] = useState(false);
    const [showMyCommissionRate, setShowMyCommissionRate] = useState(false);

    const isAdmin = permissions === 'system_admin';
    const isOperator = (permissions === 'operator' || permissions === 'operator_staff');
    const isPromoter = (permissions === 'promoter' || permissions === 'promoter_staff');
    let myInitBuyCommissionRate = 0;
    let myInitSellCommissionRate = 0;
    const [myBuyCommissionRate, setMyBuyCommissionRate] = useState(myInitBuyCommissionRate);
    const [mySellCommissionRate, setMySellCommissionRate] = useState(myInitSellCommissionRate);
    if(isOperator && parentUserCommissionRateSetting["AllowSetCommissionRate"]){
        myInitBuyCommissionRate = parentUserCommissionRateSetting["OperatorBuyCommissionRate"];
        myInitSellCommissionRate = parentUserCommissionRateSetting["OperatorSellCommissionRate"];
    }
    if(isPromoter && parentUserCommissionRateSetting["AllowSetCommissionRate"]){
        myInitBuyCommissionRate = parentUserCommissionRateSetting["PromoterBuyCommissionRate"];
        myInitSellCommissionRate = parentUserCommissionRateSetting["PromoterSellCommissionRate"];
    }


    let selectedUserType = "";
    let allowSetCommissionRate = true;
    const handleUserTypeChange = (event) => {
        for(let i in event){
            if (i == "preventDefault"){
                break;
            }
            selectedUserType += event[i];
        }
        if(Jquery("#allowSetCommissionRate").is(":checked")){
            allowSetCommissionRate = true;
        }else{
            allowSetCommissionRate = false;
        }
        setShowCommissionSettings(selectedUserType,allowSetCommissionRate)
    };

    const handleAllowSetCommissionRate = (event) => {
        allowSetCommissionRate = event.target.checked;
        selectedUserType = Jquery("#userType").val();
        setShowCommissionSettings(selectedUserType,allowSetCommissionRate)
        setTimeout(function(){
            updateMyCommissionRate();
        },200);
    }

    function setShowCommissionSettings(selectedUserType,allowSetCommissionRate){
        setShowOperatorCommissionSettings(false);
        setShowPromoterCommissionSettings(false);
        setShowStoreCommissionSettings(false);
        setShowSalesCommissionSettings(false);
        setShowAllowCommissionSettings(false);
        setShowMyCommissionRate(false);

        switch (selectedUserType) {
            case "operator":
                if(isAdmin){
                    setShowAllowCommissionSettings(true);
                    setShowOperatorCommissionSettings(true);
                    if(!allowSetCommissionRate) {
                        setShowPromoterCommissionSettings(true);
                        setShowStoreCommissionSettings(true);
                    }
                }
                break;
            case "promoter":
                if(isAdmin || (isOperator && parentUserCommissionRateSetting["AllowSetCommissionRate"]) ){
                    if(isOperator){
                        setShowMyCommissionRate(true);
                        setTimeout(function() {
                            updateMyCommissionRate();
                        }, 200);
                    }
                    setShowAllowCommissionSettings(true);
                    setShowPromoterCommissionSettings(true);
                    if(!allowSetCommissionRate) {
                        setShowStoreCommissionSettings(true);
                    }
                }
                break;
            case "store_owner":
                if(isAdmin || ((isOperator || isPromoter) && parentUserCommissionRateSetting["AllowSetCommissionRate"])  ){
                    if(isOperator || isPromoter){
                        setShowMyCommissionRate(true);
                        setTimeout(function() {
                            updateMyCommissionRate();
                        }, 200);
                    }
                    setShowStoreCommissionSettings(true);
                }
                break;
            case "sales":
                if(isAdmin){
                    setShowSalesCommissionSettings(true);
                }
                break;
        }
    }
    const updateMyCommissionRate = () => {
        let promoterBuyCommissionRate = Jquery("#promoterBuyCommissionRate").val();
        let promoterSellCommissionRate = Jquery("#promoterSellCommissionRate").val();
        let storeBuyCommissionRate = Jquery("#storeBuyCommissionRate").val();
        let storeSellCommissionRate = Jquery("#storeSellCommissionRate").val();
        if(promoterBuyCommissionRate == undefined || promoterBuyCommissionRate =="" || isNaN(promoterBuyCommissionRate)){
            promoterBuyCommissionRate = 0;
        }
        if(promoterBuyCommissionRate<0){
            return false;
        }
        if(promoterSellCommissionRate == undefined || promoterSellCommissionRate == "" ||  isNaN(promoterSellCommissionRate)){
            promoterSellCommissionRate = 0;
        }
        if(promoterSellCommissionRate<0){
            return false;
        }
        if(storeBuyCommissionRate == undefined || storeBuyCommissionRate == "" ||  isNaN(storeBuyCommissionRate)){
            storeBuyCommissionRate = 0;
        }
        if(storeBuyCommissionRate<0){
            return false;
        }
        if(storeSellCommissionRate == undefined || storeSellCommissionRate == "" ||  isNaN(storeSellCommissionRate)){
            storeSellCommissionRate = 0;
        }
        if(storeSellCommissionRate<0){
            return false;
        }
        let tmpMyBuyCommissionRate = parseFloat(myInitBuyCommissionRate)- parseFloat(promoterBuyCommissionRate) - parseFloat(storeBuyCommissionRate);
        let tmpMySellCommissionRate = parseFloat(myInitSellCommissionRate)- parseFloat(promoterSellCommissionRate) - parseFloat(storeSellCommissionRate);
        if(tmpMyBuyCommissionRate<=0 || tmpMySellCommissionRate<=0){
            return false;
        }
        tmpMyBuyCommissionRate = tmpMyBuyCommissionRate.toFixed(3);
        tmpMySellCommissionRate = tmpMySellCommissionRate.toFixed(3);
        setMyBuyCommissionRate(tmpMyBuyCommissionRate);
        setMySellCommissionRate(tmpMySellCommissionRate);
        return true;
    }

    const validateCommissionRate =  (value,type) => {
        if(value == undefined || value == ""){
            return 'commission rate is required';
        }
        if(value<=0){
            return 'commission rate must be positive';
        }
        if(Jquery("#allowSetCommissionRate").is(":checked")){
            allowSetCommissionRate = true;
        }else{
            allowSetCommissionRate = false;
        }
        selectedUserType = Jquery("#userType").val();
        let promoterBuyCommissionRate = Jquery("#promoterBuyCommissionRate").val();
        let promoterSellCommissionRate = Jquery("#promoterSellCommissionRate").val();
        let storeBuyCommissionRate = Jquery("#storeBuyCommissionRate").val();
        let storeSellCommissionRate = Jquery("#storeSellCommissionRate").val();
        if(promoterBuyCommissionRate == undefined || promoterBuyCommissionRate =="" || isNaN(promoterBuyCommissionRate)){
            promoterBuyCommissionRate = 0;
        }
        if(promoterSellCommissionRate == undefined || promoterSellCommissionRate == "" ||  isNaN(promoterSellCommissionRate)){
            promoterSellCommissionRate = 0;
        }
        if(storeBuyCommissionRate == undefined || storeBuyCommissionRate == "" ||  isNaN(storeBuyCommissionRate)){
            storeBuyCommissionRate = 0;
        }
        if(storeSellCommissionRate == undefined || storeSellCommissionRate == "" ||  isNaN(storeSellCommissionRate)){
            storeSellCommissionRate = 0;
        }
        if(isOperator){
            if(type == "promoterBuyCommissionRate" || type == "storeBuyCommissionRate"){
                if(parseFloat(promoterBuyCommissionRate)+parseFloat(storeBuyCommissionRate)>=parseFloat(myInitBuyCommissionRate)){
                    if(allowSetCommissionRate) {
                        return 'promoter buy promoter commission rate must less than operator buy commission rate';
                    }else{
                        if(selectedUserType == "store_owner"){
                            return 'store buy promoter commission rate must less than operator buy commission rate';
                        }else {
                            return 'sum of promoter & store buy promoter commission rate must less than operator buy commission rate';
                        }
                    }
                }
            }
            if(type == "promoterSellCommissionRate" || type == "storeSellCommissionRate"){
                if(parseFloat(promoterSellCommissionRate)+parseFloat(storeSellCommissionRate)>=parseFloat(myInitSellCommissionRate)){
                    if(allowSetCommissionRate) {
                        return 'promoter sell promoter commission rate must less than operator sell commission rate';
                    }else{
                        if(selectedUserType == "store_owner"){
                            return 'store sell promoter commission rate must less than operator sell commission rate';
                        }else {
                            return 'sum of promoter & store sell promoter commission rate must less than operator sell commission rate';
                        }
                    }
                }
            }
        }else if(isPromoter){
            if(type == "storeBuyCommissionRate"){
                if(parseFloat(storeBuyCommissionRate)>=parseFloat(myInitBuyCommissionRate)){
                    return 'store buy commission rate must less than promoter buy commission rate';
                }
            }
            if(type == "storeSellCommissionRate"){
                if(parseFloat(storeSellCommissionRate)>=parseFloat(myInitSellCommissionRate)){
                    return 'store sell commission rate must less than promoter sell commission rate';
                }
            }
        }
    };


    const commonInputProps = {
        style: inputStyle,
    };

    return (
        <Create title="User" {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" label="Name" validate={validateRequired}   {...commonInputProps} />
                <TextInput source="email" label="Email" validate={validateEmail} {...commonInputProps} />
                <TextInput source="phoneNumber" label="Phone Number" {...commonInputProps} />
                <ReferenceInput
                    source="userType"
                    label="User Type"
                    reference="user/types"
                    validate={validateRequired}
                    // onChange={handleUserTypeChange}
                    id="userType"
                >
                    <SelectInput  defaultValue="store_owner" />
                </ReferenceInput>
                {showAllowCommissionSettings &&
                    <BooleanInput label="Allow current added user to set commission rates" source="allowSetCommissionRate"
                                  defaultValue={false} onChange={handleAllowSetCommissionRate} id="allowSetCommissionRate"  />
                }
                {showMyCommissionRate && (
                    <div>
                        <p>My Buy Commission Rate: {myBuyCommissionRate}</p>
                        <p>My Sell Commission Rate: {mySellCommissionRate}</p>
                    </div>
                )}
                {showOperatorCommissionSettings && (
                    <div>
                        <NumberInput source="operatorBuyCommissionRate" label="Operator Buy Commission Rate"  validate={validateRequired} {...commonInputProps} />
                        <NumberInput source="operatorSellCommissionRate" label="Operator Sell Commission Rate" validate={validateRequired} {...commonInputProps} />
                    </div>
                )}

                {showPromoterCommissionSettings && (
                    <div>
                        <NumberInput onChange={updateMyCommissionRate}  id="promoterBuyCommissionRate" source="promoterBuyCommissionRate" label="Promoter Buy Commission Rate" validate={(value) =>  validateCommissionRate(value,'promoterBuyCommissionRate')} {...commonInputProps} />
                        <NumberInput  onChange={updateMyCommissionRate} id="promoterSellCommissionRate" source="promoterSellCommissionRate" label="Promoter Sell Commission Rate" validate={(value)=> validateCommissionRate(value,'promoterSellCommissionRate')} {...commonInputProps} />
                    </div>
                )}

                {showStoreCommissionSettings && (
                    <div>
                        <NumberInput onChange={updateMyCommissionRate}  id="storeBuyCommissionRate" source="storeBuyCommissionRate" label="Store Buy Commission Rate" validate={(value) =>  validateCommissionRate(value,'storeBuyCommissionRate')} {...commonInputProps} />
                        <NumberInput onChange={updateMyCommissionRate} id="storeSellCommissionRate" source="storeSellCommissionRate" label="Store Sell Commission Rate" validate={(value) => validateCommissionRate(value,'storeSellCommissionRate')} {...commonInputProps} />
                    </div>
                )}

                {showSalesCommissionSettings && (
                    <div>
                        <TextInput source="salesBuyCommissionRate" label="Sales Buy Commission Rate" validate={validateRequired} {...commonInputProps} />
                        <TextInput source="salesSellCommissionRate" label="Sales Sell Commission Rate" validate={validateRequired} {...commonInputProps} />
                    </div>
                )}
            </SimpleForm>
        </Create>
    );
};

export default UserCreate;

