import { getUserURL } from "./api";

const uploadFileByApi = (file) => {
	console.log(file);
	const formdata = new FormData();
	formdata.append("file", file.rawFile, file.title);
	formdata.append("dir", "app");
	const API_URL = getUserURL();
	const token = localStorage.getItem("BITFA:DASHBOARD:AUTH:TOKEN");
	return fetch(API_URL + "/v1/files/file", {
		method: "POST",
		headers: {
			// "Content-Type": "multipart/form-data",
			Authorization: "Bearer " + token,
			Accept: "application/json",
		},
		body: formdata,
	}).then((res) => {
		return res.json();
	});
};

const addAppUpload = (requestHandler) => (type, resource, params) => {
	if (
		(type === "CREATE" || type === "UPDATE") &&
		resource === "system/appversions"
	) {
    console.log("params.data", params.data);
    const url = getUserURL();
		if (params.data.path && params.data.path.startsWith(url)) {
			params.data.path = params.data.path.replace(url + '/', '');
		}
		if (params.data.attachments) {
			const newFile = params.data.attachments;
			console.log("newFile", newFile);
			if (!Object.prototype.hasOwnProperty.call(newFile, "rawFile")) {
				return requestHandler(type, resource, params);
			}
			return uploadFileByApi(newFile)
				.then((fileRes) => {
					return fileRes.filePath || "";
				})
				.then((filePath) =>
					requestHandler(type, resource, {
						...params,
						data: {
							...params.data,
							path: filePath,
						},
					})
				);
		}
	}
	// for other request types and resources, fall back to the default request handler
	return requestHandler(type, resource, params);
};

export default addAppUpload;
