// Send Reset Request
export const SEND_RESET_REQ = 'SEND_RESET_REQ'
export const SET_IS_REQ_SENT = 'SET_IS_REQ_SENT'
export const SET_RESET_REQ_ERR = 'SET_RESET_REQ_ERR'

// Send validation Code
export const SEND_CODE_VAL = 'SEND_CODE_VAL'
export const SET_CODE = 'SET_CODE'
export const SET_VAL_ERR = 'SET_VAL_ERR'
export const SET_IS_VAL= 'SET_IS_VAL'

// Send new password
export const SEND_UPDATE_REQ = 'SEND_UPDATE_REQ'
export const SET_IS_UPDATED = 'SET_IS_UPDATED'
export const SET_UPDATE_ERR = 'SET_UPDATE_ERR'

// ---------------
export const sendResetReq = (email) => ({
  type: SEND_RESET_REQ,
  email,
})

export const setIsReqSent = () => ({
  type: SET_IS_REQ_SENT,
})

export const setResetReqErr = (err) => ({
  type: SET_RESET_REQ_ERR,
  err,
})

// ----------------
export const setCode = (code) => ({
  type: SET_CODE,
  code,
})

export const sendCodeVal = (code) => ({
  type: SEND_CODE_VAL,
  code,
})

export const setValErr = (err) => ({
  type: SET_VAL_ERR,
  err
})

export const setIsVal = () => ({
  type: SET_IS_VAL,
})


// -------------------
export const sendUpdateReq = (code, password) => ({
  type: SEND_UPDATE_REQ,
  code,
  password
})

export const setIsUpdated = () => ({
  type: SET_IS_UPDATED,
})

export const setUpdateErr = (err) => ({
  type: SET_UPDATE_ERR,
  err
})