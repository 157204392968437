import React from "react";
import {
	List,
	Datagrid,
	TextField,
} from "react-admin";

const AppVersionList = ({ permissions, ...props }) => (
	<List {...props} pagination={null} bulkActionButtons={false} exporter={false} title={'App versions'}>
		<Datagrid rowClick="edit">
			<TextField source="version" label="Version" />
			{/* <TextField source="path" title="Path" /> */}
			<TextField source="description" title="Description" />
			<TextField source="status" label="Status" />
		</Datagrid>
	</List>
);

export default AppVersionList;
