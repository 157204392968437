import React, { useState } from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	required,
	BooleanInput,
	ImageInput,
	ImageField,
	Edit,
	SaveButton,
	Toolbar
} from "react-admin";

const CustomToolbar = props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
);

const PaymentMethodEdit = ({ permissions, classes, ...props }) => {
	return (
		<Edit title="Payment Method" {...props}>
			<SimpleForm redirect="list" toolbar={<CustomToolbar />}>
				<TextInput source="code" validate={[required()]} />
				<TextInput source="label" validate={[required()]} />
				<ImageInput source="images" label="Icon" accept="image/svg+xml">
					<ImageField source="src" title="title" />
				</ImageInput>
				<BooleanInput
					label="Status: use or not"
					source="status"
					defaultValue={false}
				/>
			</SimpleForm>
		</Edit>
	);
};

export default PaymentMethodEdit;
