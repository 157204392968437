import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    EmailField,
    NumberField,
    ReferenceField, NumberInput,
} from 'react-admin';

import CustomMFAField from "./CustomMFAField"
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const AccountShow = ({permissions, classes, ...props}) => (
    <Show title="Account" {...props}>
        <TabbedShowLayout>
            <Tab label="resources.accounts.tabs.identity">
                <TextField
                    source="firstName" formClassName={classes.inlineBlock}
                />
                <TextField
                    source="lastName" formClassName={classes.inlineBlock}
                />
                <EmailField
                    source="email"
                />
                <TextField
                    source="phoneNumber"
                />
                <TextField source="accountNumber" />
                {(permissions === 'operator' || permissions === 'system_admin') ? [
                    <TextField source="creditLimit" label="Credit Limit" />,
                    <TextField source="notifyUpperLimit" label="Sell Notify Upper Limit" />
                ] : null
                }
            </Tab>
            <Tab label="resources.accounts.tabs.address">
                <TextField source="address.address1" label="Address"/>
                <TextField source="address.address2" label="Unit" />
                <TextField source="address.city" label="City" />
                <TextField source="address.state" label="State"/>
                <TextField source="address.postalCode" label="Postal Code"/>
                <TextField source="address.country" label="Country" />
            </Tab>
            {/* <Tab label="resources.accounts.tabs.credit">
                <TextField source="countrySymbol" />
                <TextField source="accountNumber" />
                <NumberField source="credit"/>
            </Tab> */}
            <Tab label="resources.accounts.tabs.settings">
                <CustomMFAField source="isMFA" label="Multi Factor Authentication"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default  withStyles(styles)(AccountShow);
