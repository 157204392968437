import React, { useState } from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	required,
	BooleanInput,
	FileInput,
	FileField
} from "react-admin";

const AppVersionCreate = ({ permissions, ...props }) => {
	return (
		<Create title="App Version" {...props}>
			<SimpleForm redirect="list">
				<TextInput source="app" validate={[required()]} />
				<TextInput source="version" validate={[required()]} />
				<TextInput source="description" />
				<FileInput source="attachments">
					<FileField source="src" title="title" />
				</FileInput>
				<BooleanInput
					label="Status: use or not"
					source="status"
					defaultValue={false}
				/>
			</SimpleForm>
		</Create>
	);
};

export default AppVersionCreate;
