import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    CreateButton,
    CardActions, TextField,
} from 'react-admin';
import { validateRequired, validateEmail, inputStyle } from './UserCreate';

export const UserEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput source="name" label="Name" validate={validateRequired} style={inputStyle} />
                <TextField source="email" label="Email" />
                <TextInput source="phoneNumber" label="Phone Number" validate={validateRequired} style={inputStyle} />
                <TextField source="userType" label="User Type" />
            </SimpleForm>
        </Edit>
    )
};


export default UserEdit;