// in src/dataProvider
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    DELETE,
    fetchUtils,
} from 'react-admin';
import {
    BITFA_BASE_URL_PROD_V12,
    BITFA_BASE_URL_DEV,
    BITFA_BASE_URL_LOCAL,
} from '../constants';
import { stringify } from 'query-string';
import { getUserURL } from '../api';
import feeRateParser from '../payment-fee/fee_rate_show.parser';
import currencyParser from '../coins/currency.parser';
import coinsParser from '../coins/coins.parser';
import accountParser from '../accounts/accounts.parser';
import usersParser from '../users/user.parser';
import accountCoinsParser from '../accounts/accountCoins/accountCoins.parser';

// const API_URL = 'http://localhost:8000/v1';
// const API_URL = 'https://makoto-prod.bleevin.org/account/v1
var API_URL;

if (process.env.REACT_APP_ENV === 'production.v12') {   // in production env
    API_URL = BITFA_BASE_URL_PROD_V12 + '/v1';
}
else if (process.env.REACT_APP_ENV === 'development') { // in local dev env
    API_URL = BITFA_BASE_URL_DEV + '/v1';
} else {
    API_URL = BITFA_BASE_URL_LOCAL + '/v1' // in stging env
}

export const API_BASE = API_URL;


/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
    console.log("===== type: " + JSON.stringify(type) + " =====")
    console.log("===== resource: " + JSON.stringify(resource) + " =====")
    console.log("===== data: " + JSON.stringify(params) + " =====")
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
                filter: JSON.stringify(params.filter),
                offset: (page - 1) * perPage,
                limit: perPage,
                location_id: params.filter.location_id,
                date_gte: params.filter.date_gte,
                date_lte: params.filter.date_lte,
                side: params.filter.side,
            };
            return { url: `${API_URL}/${resource}?${stringify(query)}` };
        }
        case GET_ONE:
            return { url: `${API_URL}/${resource}/${params.id}` };
        case GET_MANY: {
            const query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            return { url: `${API_URL}/${resource}?${stringify(query)}` };
        }
        case GET_MANY_REFERENCE: {
            if (resource === 'accountcoins') {
                console.log(params);
                return { url: `${API_URL}/${resource}/${params.id}` };
            }
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
                filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
            };
            return { url: `${API_URL}/${resource}?${stringify(query)}` };
        }
        case UPDATE:
            return {
                url: params.id ? `${API_URL}/${resource}/${params.id}` : `${API_URL}/${resource}`,
                options: { method: 'PUT', body: JSON.stringify(params.data) },
            };
        case CREATE:
            return {
                url: `${API_URL}/${resource}`,
                options: { method: 'POST', body: JSON.stringify(params.data) },
            };
        case DELETE:
            return {
                url: `${API_URL}/${resource}/${params.id}`,
                options: { method: 'DELETE' },
            };
        default:
            throw new Error(`Unsupported fetch action type ${type}`);
    }
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
    const { headers, json } = response;
    // 处理 payementmethods 的 icon 前缀
    const url = getUserURL();
    if (resource === 'system/paymentmethods') {
        if (type === GET_LIST) {
            json.forEach((item) => {
                if (item.icon && !item.icon.startsWith(url)) {
                    item.icon = url + '/' + item.icon;
                }
            });
        } else if (type === GET_ONE) {
            if (json.icon) {
                if (!json.icon.startsWith(url)) {
                    json.icon = url + '/' + json.icon;
                }
                json.images = { src: json.icon };
            }
            console.log("GET_ONE", json);
        }
    }
    if (resource === 'system/appversions') {
        if (type === GET_ONE) {
            if (json.path) {
                if (!json.path.startsWith(url)) {
                    json.path = url + '/' + json.path;
                }
                json.attachments = { src: json.path, title: `app-${json.version}.apk` };
            }
            console.log("GET_ONE", json);
        }
    }
    switch (type) {
        case GET_LIST:
        case GET_MANY_REFERENCE:
            let res = {
                data: json.map(x => x),
                total: parseInt(headers.get('content-range').split('/').pop(), 10),
            };
            console.log(res)
            return res
        case CREATE:
            return { data: { ...params.data, id: json.id } };
        default:
            return { data: json };
    }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
    const { fetchJson } = fetchUtils;
    let { url, options = {} } = convertDataProviderRequestToHTTP(type, resource, params);
    
    if (resource === 'accountcoins' && params.id.includes('@')) {
        url = `${API_URL}/${resource}/${params.id.split('@')[0]}?symbol=${params.id.split('@')[1]}`;
    }

    const token = localStorage.getItem('BITFA:DASHBOARD:AUTH:TOKEN');
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchJson(url, options)
        .then(response => usersParser(response, type, resource, params))
        .then(response => accountCoinsParser(response, type, resource, params))
        .then(response => coinsParser(response, type, resource, params))
        .then(response => currencyParser(response, type, resource, params))
        .then(response => feeRateParser(response, type, resource, params))
        .then(response => accountParser(response, type, resource, params))
        .then(response => convertHTTPResponseToDataProvider(response, type, resource, params));
};