import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MaterialUiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const styles = theme => {
  return {
    progress: {
      position: 'absolute',
    },
    round: {
      borderRadius: 25,
    }  
  }
};

/**
 * For default MUI props:
 * https://material-ui.com/api/button/
 */
class Button extends Component {
  render() {
    const { classes, loading, children, disabled, ...buttonProps } = this.props;
    const isDisabled = loading || this.props.disabled;

    buttonProps.className = buttonProps.className || '';
    buttonProps.className += ' ' + classes.default;
    buttonProps.className += buttonProps.round ? ' ' + classes.round : '';
    delete buttonProps.round;

    const loadingIndicator = loading ? 
      <CircularProgress size={28} className={classes.progress} /> : 
      null

    return (
      <MaterialUiButton {...buttonProps} disabled={isDisabled} >
        {children}
        {loadingIndicator}
      </MaterialUiButton>
    );
  }
}

Button.propTypes = {
  disabled: PropTypes.bool, 
  classes: PropTypes.object,
};

export default withStyles(styles)(Button);