import Big from 'big.js'

const usersParser = (response, type, resource, params) => {

    if (type === 'GET_ONE' && resource === 'users') {
        console.log("usersParser::response", response);
        // const respData = response.json;
        // const keys = [
        //     "systemBuyCommissionRatio", "systemSellCommissionRatio",
        //     "storeBuyCommissionRatio", "storeSellCommissionRatio",
        //     "promoterBuyCommissionRatio", "promoterSellCommissionRatio",
        //     "operatorBuyCommissionRatio", "operatorSellCommissionRatio"
        // ];
        // keys.forEach(key => {
        //     if (respData[key]) {
        //         respData[`${key}_show`] = parseFloat(new Big(respData[key] * 100).toFixed(2));
        //     }
        // });
        return response;
    } else {
        return response;
    }

}

export default usersParser;