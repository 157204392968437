// in src/MyUrlField.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import LaunchIcon from '@material-ui/icons/Launch';

const styles = {
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: '0.5em',
        paddingLeft: 2,
    },
};


const CustomDownloadField = ({ record = {}, source, classes }) =>{

    var LINK_PREFIX;
    if (process.env.REACT_APP_ENV === 'production.v12') {   // in production env
        LINK_PREFIX = 'https://statements.bleevin.org/';
    } else {
        LINK_PREFIX = 'https://statements.bleevin.cloud/' // in stging env
    }


    if (record && record.filePath) {
        console.log(record)

        return (
        // <span style={{color: "grey"}}> Link: &nbsp;
        <a href={LINK_PREFIX + record[source]} className={classes.link} download>
            Download
            {/* <LaunchIcon className={classes.icon} /> */}
        </a>
        // </span>
        )
    }
    else {
        return(
            <span style={{color: "grey"}}> No file  </span>
        )
    }
}
export default withStyles(styles)(CustomDownloadField);