import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
} from 'react-admin';
import CustomActiveField from "../common/CustomActiveField"

const AccountList = ({permissions,...props}) => (
    <List {...props} pagination={null} bulkActionButtons={false}  exporter={false} hasCreate={false} /*hasCreate={permissions == 'store_owner'}*/>
        <Datagrid rowClick="show" >
            <TextField source="accountNumber" label="Account Number" />
            <TextField source="companyName" label="Company Name" />
            <TextField source="registrationNumber" label="Registration Number" />
            <TextField source="formationDate" label="Date of Formation" />
            <TextField source="locationsStr" label="Location" />
            <TextField source="countrySymbol" label="Country" />
            <TextField source="currencySymbol" label="Currency" />
            <TextField source="createdAt" label="Create At" />
            <CustomActiveField source="active" />
            {permissions == "system_admin" || permissions == "operator" ? [
                    <NumberField source="creditLimit" label="Credit Limit" />,
                    <NumberField source="notifyUpperLimit" label="Sell Notify Upper Limit" />
            ] : null}
        </Datagrid>
    </List>
);

export default AccountList;
