import React, { Component } from "react";
import { Admin, Resource } from "react-admin";

import "./App.css";

import authProvider from "./authProvider";
import sagas from "./sagas";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import AccountList from "./accounts/AccountList";
import AccountIcon from "@material-ui/icons/AccountCircle";
import AccountCreate from "./accounts/AccountCreate";
import AccountEdit from "./accounts/AccountEdit";
import AccountShow from "./accounts/AccountShow";

import LocationList from "./locations/LocationList";
import LocationIcon from "@material-ui/icons/Store";
import LocationCreate from "./locations/LocationCreate";
import LocationEdit from "./locations/LocationEdit";
import LocationShow from "./locations/LocationShow";

import CoinList from "./coins/CoinList";
import CoinIcon from "@material-ui/icons/Album";
import CoinCreate from "./coins/CoinCreate";
import CoinEdit from "./coins/CoinEdit";
import CoinShow from "./coins/CoinShow";

import FundingList from "./fundings/FundingList";
import FundingIcon from "@material-ui/icons/AccountCircle";
import FundingCreate from "./fundings/FundingCreate";
import FundingEdit from "./fundings/FundingEdit";
import FundingShow from "./fundings/FundingShow";

import StatementList from "./statements/StatementList";
import StatementIcon from "@material-ui/icons/AccountCircle";
import StatementCreate from "./statements/StatementCreate";
import StatementEdit from "./statements/StatementEdit";

import ReportList from "./reports/ReportList";
import ReportIcon from "@material-ui/icons/AccountCircle";
import ReportCreate from "./reports/ReportCreate";
import ReportEdit from "./reports/ReportEdit";

import transactions from "./transactions";
import users from "./users";
import operators from "./operators";

import dataProvider from "./dataProvider";
import addUploadFeature from "./addUploadFeature";
import addUploadFileFeature from "./addUploadFileFeature";
import addUploadImageInFetch from "./addUploadImageInFetch";
import addAppUpload from "./addAppUpload";
import customizeProvider from "./payment-fee/customize.provider";
import coinsProvider from "./coins/coins.provider";

import passwordReducer from "./password/passwordReducer";
import accountReducer from "./accounts/accountReducer";

import { RolePermission } from "./rolePermission";

const parsedDataProvider = 
coinsProvider(
	customizeProvider(
		addAppUpload(
			addUploadImageInFetch(
				addUploadFileFeature(
					addUploadFeature(
						dataProvider
					)
				)
			)
		)
	)
);

const i18nProvider = (locale) => {
	if (locale === "fr") {
		return import("./i18n/fr").then((messages) => messages.default);
	}

	// Always fallback on english
	return englishMessages;
};

class App extends Component {
	async componentWillMount() {}

	componentWillUnmount() {}

	render() {
		if (!dataProvider) {
			return (
				<div className="loader-container">
					<div className="loader">Loading...</div>
				</div>
			);
		}

		return (
			<Admin
				title=""
				dataProvider={parsedDataProvider}
				customReducers={{
					theme: themeReducer,
					password: passwordReducer,
					account: accountReducer,
				}}
				customSagas={sagas}
				customRoutes={customRoutes}
				authProvider={authProvider}
				dashboard={Dashboard}
				loginPage={Login}
				appLayout={Layout}
				locale="en"
				i18nProvider={i18nProvider}
			>
				{(permissions) => RolePermission(permissions)}
			</Admin>
		);
	}
}

function getPermission(permissions) {
	return [
		permissions === "operator" ? (
			<Resource name="operators" {...operators} />
		) : null,
		<Resource name="users" {...users} />,
		<Resource
			name="accounts"
			list={AccountList}
			create={permissions === "operator" ? null : AccountCreate}
			edit={AccountEdit}
			show={AccountShow}
			icon={AccountIcon}
		/>,
		<Resource
			name="locations"
			list={LocationList}
			create={permissions === "operator" ? null : LocationCreate}
			edit={LocationEdit}
			show={LocationShow}
			icon={LocationIcon}
		/>,
		<Resource name="transactions" {...transactions} />,
		<Resource
			name="coins"
			list={CoinList}
			create={permissions === "operator" ? null : CoinCreate}
			edit={permissions === "operator" ? CoinEdit : null}
			show={CoinShow}
			icon={CoinIcon}
		/>,
		<Resource
			name="fundings"
			list={FundingList}
			create={permissions === "operator" ? FundingCreate : null}
			edit={permissions === "operator" ? FundingEdit : null}
			show={permissions === "operator" ? FundingShow : null}
			icon={FundingIcon}
		/>,
		<Resource
			name="statements"
			list={StatementList}
			create={permissions === "operator" ? StatementCreate : null}
			edit={permissions === "operator" ? StatementEdit : null}
			// show={permissions === 'operator' ? StatementShow : null}
			icon={StatementIcon}
		/>,
		<Resource
			name="reports"
			list={ReportList}
			create={permissions === "operator" ? ReportCreate : null}
			edit={permissions === "operator" ? ReportEdit : null}
			// show={permissions === 'operator' ? ReportShow : null}
			icon={ReportIcon}
		/>,
	];
}

export default App;
