import React, { Fragment } from 'react';
import {
    EditButton,
    List,
    Datagrid,
    TextField,
    NumberField,
    ChipField,
    ReferenceField,
    downloadCSV,
    CardActions,
    ExportButton,
    Filter,
    DateInput,
    SelectInput,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import TransactionShow from './TransactionShow';
import CustomDateTimeField from '../common/CustomDateTimeField';
import CustomSettleField from './CustomSettleField';

import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import moment from 'moment-timezone/builds/moment-timezone-with-data';
import CustomBalanceField from '../common/CustomBalanceField';
import SettleButton from './SettleButton';
import UnsettleButton from './UnsettleButton';

import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const ListFilters = ({ permissions, ...props }) => (
    <Filter {...props}>
        {(permissions === 'system_admin' || permissions === 'operator' || permissions === 'store_owner') &&
        <ReferenceInput source="location_id" reference="locations" alwaysOn>
            <AutocompleteInput
                optionText={choice =>
                    `${choice.nickName}`
                }
            />
        </ReferenceInput>
         }
        <DateInput source="date_gte" alwaysOn label="From"/>
        <DateInput source="date_lte" alwaysOn label="End" />
        <SelectInput source="side" label="Side" choices={[
                { id: 'BUY', name: 'BUY' },
                { id: 'SELL', name: 'SELL' },
            ]} />
    </Filter>
);


const exporter = (records) => {

    // this is only for Production. uuid is location id
    if (records.length === 0) { return ; }

    var csvRows = [];
    for (var i = 0; i < records.length; i++) {
        if (records[i].status == 'COMPLETED' || records[i].status == 'SENT'){
            var row = {}
            row.OrderID = records[i].id;
            row.TransactionID = records[i].transaction_id;
            row.Side = records[i].side;
            row.Amount = records[i].amount;
            row.BTC_Volume = records[i].btcVolStr;
            row.Price = records[i].price;
            //row.Credit = records[i].credit;
            row.Comission = records[i].commission;
            row.Currency = records[i].currency;
            //row.Spread = records[i].spread ? records[i].spread : 0;
            row.Settled = records[i].isSettled;
            var time = moment(records[i].createdAt).format('YYYY-MM-DD  HH:mm');
            row.Time = time;

            csvRows.push(row)
        }
    }

    const csv = convertToCSV({
        data: csvRows,
        fields: ['OrderID', 'TransactionID', 'Time', 'Side', 'Amount', 'BTC_Volume', 'Price',
          'Comission', 'Currency', 'Settled'],
    });

    // var now = moment().format('YYYYMMDD')
    // var storeCode = localStorage.getItem('BITFA:DASHBOARD:LOCATION:STORE_CODE');
    const location_id = records[0].location_id
    const startDate = moment(records[records.length-1].createdAt).tz('America/Los_Angeles').format('YYYYMMDD');
    const endDate = moment(records[0].createdAt).tz('America/Los_Angeles').format('MMDD');

    // const accountNum = storeInfo[location_id] ? storeInfo[location_id].accountNum : "TestAccNum"
    const storeCode =  "Transaction";
    // const fileName = accountNum+storeCode+startDate+'-'+endDate
    const fileName = storeCode+startDate+'-'+endDate;

    downloadCSV(csv, fileName);
};

const longText = `Please refer to this example to see how commission is calculated: bitcoin cost (959.69) + bitcoin cost (959.69) * 0.03 (platform fee) + bitcoin cost (959.69) * 0.01 (store fee) +bitcoin_cost (959.69) * 0.002 (exchange fee)= customer_pay (1000). If you have any questions, please contact us.`;

const TransactionActions = ({
    permissions,
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    currentSort,
    resource,
    selectedIds,
    showFilter,
    exporter,
    total,
}) => (
        <CardActions>
            {bulkActions && React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}

            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filter={filterValues}
                exporter={exporter}
            />
            {/* {permissions === "store_owner" && <CustomBalanceField />} */}
            {/*<Tooltip title={longText}>
              <HelpOutlineIcon />
            </Tooltip>*/}
        </CardActions>
);

const PostBulkActionButtons = props => (
    <Fragment>
        <SettleButton  {...props} />
        <UnsettleButton  {...props} />
    </Fragment>
);


const TransactionList = ({ permissions, ...props }) => (
    <List {...props} bulkActionButtons={permissions === 'system_admin' && <PostBulkActionButtons />} exporter={exporter} actions={<TransactionActions permissions={permissions}  />} filters={<ListFilters permissions={permissions} />} >
        <Datagrid rowClick="expand" expand={<TransactionShow permissions={permissions}/>}>
            <TextField source="id" label="ID"/>
            <TextField source="transaction_id" label="Transaction ID"/>
            {/* <ReferenceField label="Account" source="account_id" reference="accounts" linkType="show">
                <TextField source="firstName" />
            </ReferenceField> */}
            <TextField label="Location" source="location_name" />
            <TextField label="Coin" source="coin_name" />
            <ChipField source="side" label="Side"/>
            <NumberField source="amount" label="Amount"/>
            <NumberField source="btcVolStr" label="Volume"/>
            {permissions === "store_owner" && <NumberField source="credit" label="Credit" />}
            <NumberField source="commission" label="Commission " />
            <TextField label="Currency" source="currency" />
            <CustomDateTimeField source="createdAt" showTime options={{ timeZone:"America/Los_Angeles" }}/>
            <CustomDateTimeField source="updatedAt" showTime options={{ timeZone:"America/Los_Angeles" }}/>
            <TextField source="status" label="Status"/>
            <CustomSettleField source="isSettled"  style={{ color: 'green' }}/>
            {(permissions === "store_owner" || permissions === "store_staff") && <EditButton />}
        </Datagrid>
    </List>
);

export default TransactionList;