// App.js
import React, { Component,createRef } from "react";
import { Admin, Resource } from "react-admin";
import { Typography } from "@material-ui/core";

import "./App.css";

import authProvider from "./authProvider";
import sagas from "./sagas";
import themeReducer from "./themeReducer";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";

import AccountList from "./accounts/AccountList";
import AccountIcon from "@material-ui/icons/AccountCircle";
import AccountCreate from "./accounts/AccountCreate";
import AccountEdit from "./accounts/AccountEdit";
import AccountShow from "./accounts/AccountShow";

import LocationList from "./locations/LocationList";
import LocationIcon from "@material-ui/icons/Store";
import LocationCreate from "./locations/LocationCreate";
import LocationEdit from "./locations/LocationEdit";
import LocationShow from "./locations/LocationShow";

import CoinList from "./coins/CoinList";
import CoinIcon from "@material-ui/icons/Album";
import CoinCreate from "./coins/CoinCreate";
import CoinEdit from "./coins/CoinEdit";
import CoinShow from "./coins/CoinShow";

import FundingList from "./fundings/FundingList";
import FundingIcon from "@material-ui/icons/AccountCircle";
import FundingCreate from "./fundings/FundingCreate";
import FundingEdit from "./fundings/FundingEdit";
import FundingShow from "./fundings/FundingShow";

import StatementList from "./statements/StatementList";
import StatementIcon from "@material-ui/icons/AccountCircle";
import StatementCreate from "./statements/StatementCreate";
import StatementEdit from "./statements/StatementEdit";

import ReportList from "./reports/ReportList";
import ReportIcon from "@material-ui/icons/AccountCircle";
import ReportCreate from "./reports/ReportCreate";
import ReportEdit from "./reports/ReportEdit";

import transactions from "./transactions";
import users from "./users";
import operators from "./operators";

import dataProvider from "./dataProvider";
import addUploadFeature from "./addUploadFeature";
import addUploadFileFeature from "./addUploadFileFeature";
import addUploadImageInFetch from "./addUploadImageInFetch";
import addAppUpload from "./addAppUpload";
import customizeProvider from "./payment-fee/customize.provider";
import coinsProvider from "./coins/coins.provider";
import usersProvider from "./users/user.provider";
import accountCoinsProvider from "./accounts/accountCoins/accountCoins.provider";

import passwordReducer from "./password/passwordReducer";
import accountReducer from "./accounts/accountReducer";

import { RolePermission } from "./rolePermission";

// ====== 新增/导入弹窗所需的组件 ======
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Stepper,
	Step,
	StepLabel,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import UserUpdateForm from './users/UserUpdateForm'; // 新组件
import AccountCreateForm from './accounts/AccountCreateForm'; // 新组件


const parsedDataProvider =
	accountCoinsProvider(
		usersProvider(
			coinsProvider(
				customizeProvider(
					addAppUpload(
						addUploadImageInFetch(
							addUploadFileFeature(
								addUploadFeature(
									dataProvider
								)
							)
						)
					)
				)
  			)
		)
	);
const i18nProvider = (locale) => {
	if (locale === "fr") {
		return import("./i18n/fr").then((messages) => messages.default);
	}
	return englishMessages;
};

const STEPS_LABELS = ["Beneficial Owner Information", "Company Information"];
const TOTAL_STEPS = STEPS_LABELS.length;

class App extends Component {
	/**
	 * 新增：使用 state 管理弹窗开关和 step
	 * 注意此处一定要在组件内声明 state
	 */
	state = {
		openDialog: false,
		step: 0,
		agreementDialogOpen: false,
		hasAgreed: false,
	};
	// 创建 ref 用于滚动控制
	dialogContentRef = createRef();

	async componentWillMount() {}

	componentWillUnmount() {}

	componentDidMount() {
		this.handleDialogChange();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.step !== this.state.step) {
			// 等待下一次 DOM 更新
			setTimeout(() => {
				this.scrollToTop();
			}, 0);
		}
	}

	// 关闭弹窗方法
	handleCloseDialog = () => {
		const { step } = this.state;
		if (step >= TOTAL_STEPS + 1) {
			// step=3 代表前2步都已完成，这里可以真正关闭
			this.setState({ openDialog: false });
		}
	};

	//同意协议
	handleAgree = () => {
		this.setState({
			hasAgreed: true,
			agreementDialogOpen: false,
		});
		this.setState({
			openDialog: true,
			step: 1,
		});
	};

	handleDialogChange = () => {
		const token = localStorage.getItem('BITFA:DASHBOARD:AUTH:TOKEN');
		const { openDialog,hasAgreed } = this.state;
		if (!token) {
			if(openDialog){
				this.setState({ openDialog: false });
			}
			return
		}
		const step = Number(localStorage.getItem('BITFA:DASHBOARD:REGISTER:STEP')) || 0;
		if (step == 1 && !hasAgreed) {
			this.setState({ agreementDialogOpen: true });
			return;
		}
		if ( step > 0 && step <= TOTAL_STEPS) {
			this.setState({
				openDialog: true,
				step: step,
			});
		}else{
			this.setState({
				openDialog: false ,step:0
			});
		}
	};

	scrollToTop = () => {
		if (this.dialogContentRef.current) {
			console.log(
				'scrollHeight:', this.dialogContentRef.current.scrollHeight,
				'clientHeight:', this.dialogContentRef.current.clientHeight
			);
			console.log("Before scroll:", this.dialogContentRef.current.scrollTop);
			this.dialogContentRef.current.scrollTop = 0;
			console.log("After scroll:", this.dialogContentRef.current.scrollTop);
		}
	};

	render() {
		if (!dataProvider) {
			return (
				<div className="loader-container">
					<div className="loader">Loading...</div>
				</div>
			);
		}

		// 从 state 中解构拿到 openDialog 和 step
		const { openDialog,agreementDialogOpen, step } = this.state;
		// 计算当前 Stepper 的高亮：activeStep 从 0 开始
		const activeStepIndex = step > 0 && step <= TOTAL_STEPS ? step - 1 : -1;
		return (
			<>
				{/**  协议弹窗：用复选框 + Continue 按钮 */}
				<Dialog
					open={agreementDialogOpen}
					onClose={this.handleDisagree}
					maxWidth="sm"
					fullWidth
				>
					<AgreementDialogContent
						onAgree={this.handleAgree}
					/>
				</Dialog>

				<Dialog
					scroll="paper"
					open={openDialog}
					onClose={this.handleCloseDialog}
					disableBackdropClick
					disableEscapeKeyDown
					fullWidth
					maxWidth="md"
					PaperProps={{
						style: {
							margin: '10px',
							width: '100%',
							maxHeight: '90vh',
							'@media (max-width: 600px)': {
								margin: '0px',
								maxHeight: '100vh',
								borderRadius: '0',
							}
						}
					}}
				>
					<DialogTitle>
						<Typography style={{
							fontSize: window.innerWidth < 600 ? '18px' : '24px',
							padding: window.innerWidth < 600 ? '8px 0' : '16px 0'
						}}>
							Please fill in the information in order ({TOTAL_STEPS} steps in total)
						</Typography>
					</DialogTitle>
					<div
						dividers
						ref={this.dialogContentRef}
						style={{
							maxHeight: window.innerWidth < 600 ? '100vh' : '800px',
							overflowY: 'auto',
							WebkitOverflowScrolling: 'touch',
						}}
					>
					<DialogContent
						style={{
							height: '100%',
							padding: window.innerWidth < 600 ? '16px' : '24px',
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								width: '100%',
								minHeight: window.innerWidth < 600 ? '200px' : '300px',
							}}
						>
							<Stepper
								style={{
									justifyContent: 'space-around',
									width: '100%',
									padding: window.innerWidth < 600 ? '8px 0' : '24px 0',
								}}
								activeStep={activeStepIndex}
								alternativeLabel
								orientation={window.innerWidth < 600 ? 'horizontal' : 'horizontal'}
							>
								{STEPS_LABELS.map((label) => (
									<Step key={label}>
										<StepLabel>
											<Typography style={{
												fontSize: window.innerWidth < 600 ? '12px' : '14px'
											}}>
												{label}
											</Typography>
										</StepLabel>
									</Step>
								))}
							</Stepper>
							{step === 1 && <UserUpdateForm handleDialogChange={this.handleDialogChange} isMobile={window.innerWidth < 600} />}
							{step === 2 && <AccountCreateForm handleDialogChange={this.handleDialogChange} isMobile={window.innerWidth < 600} />}
						</div>
					</DialogContent>
					</div>
				</Dialog>

				<Admin
					title=""
					dataProvider={parsedDataProvider}
					customReducers={{
						theme: themeReducer,
						password: passwordReducer,
						account: accountReducer,
					}}
					customSagas={sagas}
					customRoutes={customRoutes}
					authProvider={authProvider(this.handleDialogChange)}
					dashboard={Dashboard}
					loginPage={Login}
					appLayout={Layout}
					locale="en"
					i18nProvider={i18nProvider}
				>
					{(permissions) => RolePermission(permissions)}
				</Admin>
			</>
		);
	}
}

function getPermission(permissions) {
	return [
		permissions === "operator" ? (
			<Resource name="operators" {...operators} />
		) : null,
		<Resource name="users" {...users} />,
		<Resource
			name="accounts"
			list={AccountList}
			create={permissions === "operator" ? null : AccountCreate}
			edit={AccountEdit}
			show={AccountShow}
			icon={AccountIcon}
		/>,
		<Resource
			name="locations"
			list={LocationList}
			create={permissions === "operator" ? null : LocationCreate}
			edit={LocationEdit}
			show={LocationShow}
			icon={LocationIcon}
		/>,
		<Resource name="transactions" {...transactions} />,
		<Resource
			name="coins"
			list={CoinList}
			create={permissions === "operator" ? null : CoinCreate}
			edit={permissions === "operator" ? CoinEdit : null}
			show={CoinShow}
			icon={CoinIcon}
		/>,
		<Resource
			name="fundings"
			list={FundingList}
			create={permissions === "operator" ? FundingCreate : null}
			edit={permissions === "operator" ? FundingEdit : null}
			show={permissions === "operator" ? FundingShow : null}
			icon={FundingIcon}
		/>,
		<Resource
			name="statements"
			list={StatementList}
			create={permissions === "operator" ? StatementCreate : null}
			edit={permissions === "operator" ? StatementEdit : null}
			// show={permissions === 'operator' ? StatementShow : null}
			icon={StatementIcon}
		/>,
		<Resource
			name="reports"
			list={ReportList}
			create={permissions === "operator" ? ReportCreate : null}
			edit={permissions === "operator" ? ReportEdit : null}
			// show={permissions === 'operator' ? ReportShow : null}
			icon={ReportIcon}
		/>,
	];
}

export default App;

function AgreementDialogContent({ onAgree, onDisagree }) {
	const [checked, setChecked] = React.useState(false);

	const handleCheckboxChange = (event) => {
		setChecked(event.target.checked);
	};

	const handleContinueClick = () => {
		// 调用父组件回调
		onAgree();
	};

	return (
		<>
			<DialogContent dividers>
				<p>
					Federal law requires financial institutions to obtain, verify and record information that identifies each entity that opens an account.<br/><br/>
					Loko Technologies LLC will not open a trading relationship with the prospective counterparty if Loko Technologies LLC cannot form a reasonable belief that it knows the true identity of a prospective counterparty, or onboarding materials are not provided as requested.
				</p>
				<FormControlLabel
					control={
						<Checkbox
							checked={checked}
							onChange={handleCheckboxChange}
							color="primary"
						/>
					}
					label={
						<span>I have read and agree to the <a href="/terms.html" target="_blank">Terms of Use</a>, and the <a href="/privacy.html" target="_blank">Privacy Policy</a>.</span>
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleContinueClick}
					color="primary"
					disabled={!checked}  // 未勾选时禁用按钮
				>
					Continue
				</Button>
			</DialogActions>
		</>
	);
}
