import PaymentMethodList from './PaymentMethodList'
import PaymentIcon from '@material-ui/icons/Payment';
import PaymentMethodCreate from './PaymentMethodCreate';
import PaymentMethodEdit from './PaymentMethodEdit';
import PaymentMethodShow from './PaymentMethodShow';

export default {
    list: PaymentMethodList,
    create: PaymentMethodCreate,
    edit: PaymentMethodEdit,
    show: PaymentMethodShow,
    icon: PaymentIcon,
};
