import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '../common/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  form: {
    padding: '0 1em 1em 1em',
  },
  submit: {
    marginTop: 30,
    marginBottom: 30
  },
  icon: {
    height: 100,
    width: 100,
    margin: '50px 80px',
  }
});


class LoginFormMessage extends Component {
  render() {
    // const { Icon, classes, message, btnText, onClick } = this.props;
    const { classes, message, btnText, onClick } = this.props;
    return (
      <div className={classes.form}>
        {/* <Icon color="primary" className={classes.icon} /> */}
        <Typography variant="subheading">
          { message }
        </Typography>
        <Button
          fullWidth
          round
          color="primary"
          className={classes.submit}
          onClick={onClick}
        >
          {btnText}
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(LoginFormMessage);