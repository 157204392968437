import Big from 'big.js';

const currencyParser = (response, type, resource, params) => {

    if (type === 'GET_LIST' && resource === 'account/currencies') {
        console.log("currencyParser::response", response);
        const respData = response.json;
        const currencyMap = {};
        respData.forEach(x => {
            const commissionRateSettingBuy = parseSettingRate(x.commissionRateSetting.buy);
            const commissionRateSettingSell = parseSettingRate(x.commissionRateSetting.sell);
            const fixedCommissionRateSettingBuy = parseSettingRate(x.fixedCommissionRateSetting.buy);
            const fixedCommissionRateSettingSell = parseSettingRate(x.fixedCommissionRateSetting.sell);
            currencyMap[x.currency] = {
                commissionRateSettingBuy,
                commissionRateSettingSell,
                fixedCommissionRateSettingBuy,
                fixedCommissionRateSettingSell
            };
        });
        window._currencyMap = currencyMap;
        return response;
    } else {
        return response;
    }


}

export function parseSettingRate(arr) {
    const res = [];
    arr.forEach((y, i) => {
        if (i === 0) {
            res.push({
                key: '>=' + y.max,
                max: y.max,
                rate: parseFloat(new Big(y.rate * 100).toFixed(2))
            });
        } else {
            const prevNode = arr[i - 1];
            res.push({
                key: y.max + '-' + prevNode.max,
                max: y.max,
                rate: parseFloat(new Big(y.rate * 100).toFixed(2))
            });
        }
    });
    return res;
}

export default currencyParser;