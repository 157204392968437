import {
  SET_SETTLE_TYPE,
  SET_CREDIT,
} from './accountActions';


const initialState = {
  settleType: "auto",
  credit: 0,
}

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SETTLE_TYPE:
      localStorage.setItem('BITFA:DASHBOARD:ACCOUNT:SETTLE_TYPE', action.settleType);
      return {
        ...state,
        settleType: action.settleType,
      }
    case SET_CREDIT:
      return {
        ...state,
        credit: action.credit,
      }
    default:
      return state
  }
}

export default accountReducer
