import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import PasswordResetForm from './password/PasswordResetForm';


export default [
    <Route exact path="/configuration" component={Configuration} />,
    <Route exact path="/init-password-reset" component={PasswordResetForm} />,
];
