import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    Pagination,
    EditButton, Filter, ReferenceInput, AutocompleteInput, DateInput, SelectInput,
    // BooleanField,

} from 'react-admin';
import CustomActiveField from "../common/CustomActiveField"

const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />

const UserList = props => (
    <List {...props} pagination={<PostPagination />} bulkActionButtons={false}  exporter={false}>
        <Datagrid rowClick="show" >
            {/* <FunctionField label="Name" render={record => `${record.firstName} ${record.lastName}`} style={{ fontWeight: 'bold' }}/> */}
            {/* <TextField source="id" label="ID" /> */}
            <TextField source="name" label="User ID" />
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name" />
            <TextField source="middleName" label="Middle Name" />
            <TextField source="email" label="Email" />
            <TextField source="phoneNumber" label="Phone Number" />
            <TextField source="country" label="Country" />
            <TextField source="dateOfBirth" label="Date of Birth" />
            <TextField source="userType" label="Type" />
            <CustomActiveField source="active" />
        </Datagrid>
    </List>
);

export default UserList;
