import React from "react";
import {
	List,
	Datagrid,
	TextField,
  ImageField,
} from "react-admin";

const PaymentMethodList = ({ permissions, ...props }) => (
	// {/* <List {...props} pagination={null} bulkActionButtons={false} exporter={false} actions={permissions === 'operator'? false: <FundingActions />} > */}
	<List {...props} pagination={null} bulkActionButtons={false} exporter={false} title={'Payment Method'}>
		<Datagrid rowClick="show">
			{/* <TextField source="id" label="ID" /> */}
			<TextField source="code" label="code" />
			<TextField source="label" label="Label" />
      <ImageField source="icon" title="title" />
			<TextField source="status" label="Status" />

		</Datagrid>
	</List>
);

export default PaymentMethodList;
