import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAccount } from '../accounts/accountActions'


class CustomBalanceField extends Component {

    componentDidMount() {
        const { fetchAccount } = this.props
        fetchAccount()
    }

    render() {
        const { credit } = this.props;
        const credit2decimal = credit.toFixed(2);
        return (
            <div style={{ fontSize: 16, color : "grey", margin: "8px",  }}>
                Credit Balance: {credit2decimal} HKD
            </div>
        );
    }
}
const mapStateToProps = state => ({
    settleType: state.account.settleType,
    credit: state.account.credit,
})

export default connect(mapStateToProps, { fetchAccount })(CustomBalanceField);