
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFileFeature = requestHandler => (type, resource, params) => {
    if ((type === 'CREATE' && resource === 'accounts') || (type === 'CREATE' && resource === 'locations')){
        // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
        // if parameter `multiple` is false, then data.pictures is not an array, but single object
        console.log(params)
        // console.log(params.data.pictures.length)

        console.log("addUploadFileFeature")

        if (params.data.files && params.data.files.length) {
            console.log("params.data.files")
            // only freshly dropped files are instance of File
            const formerFiles = params.data.files.filter(p => !(p.rawFile instanceof File));
            const newFiles = params.data.files.filter(p => p.rawFile instanceof File);

            return Promise.all(newFiles.map(convertFileToBase64))
                .then(base64Pictures => base64Pictures.map((picture64, index) => ({
                    base64: picture64,
                    title: `${newFiles[index].title}`,
                })))
                .then(transformedNewPictures => requestHandler(type, resource, {
                    ...params,
                    data: {
                        ...params.data,
                        files: [...transformedNewPictures, ...formerFiles],
                    },
                }));
        }
    }
    // for other request types and resources, fall back to the default request handler
    return requestHandler(type, resource, params);
};

export default addUploadFileFeature;