import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    ReferenceField,
} from 'react-admin';

const LocationShow = ({classes, ...props}) => (
    <Show title="Location" {...props}>
        <TabbedShowLayout>
            <Tab label="resources.locations.tabs.info">
                <TextField source="staff_name" label="Staff"/>
                <TextField
                    source="nickName"
                    label="Store Name"
                />
                {/* <EmailField
                    source="email"
                />
                <TextField
                    source="phoneNumber"
                /> */}
                <TextField
                    source="description"
                />
                <TextField
                    source="hours" label="Business Hours"
                />
            </Tab>
            <Tab label="resources.locations.tabs.address">
                <TextField source="address.address1" label="Address"/>
                <TextField source="address.address2" label="Unit" />
                <TextField source="address.city" label="City" />
                <TextField source="address.state" label="State"/>
                <TextField source="address.postalCode" label="Postal Code"/>
                <TextField source="address.country" label="Country" />
            </Tab>
            {/* <Tab label="resources.accounts.tabs.doc">
                <ArrayField source="files">
                    <SingleFieldList>
                        <FileField source="base64" title="title" />
                    </SingleFieldList>
                </ArrayField>
             </Tab> */}
        </TabbedShowLayout>
    </Show>
);

export default LocationShow;
