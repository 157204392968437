import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    ReferenceField,
} from 'react-admin';


const LocationList = props => (
    <List {...props} pagination={null} bulkActionButtons={false} exporter={false}>
        <Datagrid rowClick="show" >
            {/* <TextField source="name" /> */}
            <TextField source="staff_name" label="Staff"/>
            <TextField source="nickName" label="Nick Name"/>
            <TextField source="address.city" label="City"/>
            <TextField source="address.state" label="State"/>
            {/* <TextField source="phoneNumber" /> */}
            <BooleanField source="active" label="Active"  style={{ color: 'green' }}/>
        </Datagrid>
    </List>
);

export default LocationList;
