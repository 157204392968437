import React, { useState } from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    NumberInput,
    SelectInput,
    required,
    choices,
} from 'react-admin';



const FundingCreate = ({ permissions, ...props }) => {
    const validateType = [required(), choices(['DEPOSIT', 'PAYOUT'], 'Must be a funding type')];

    const [selectedType, setSelectedType] = useState(false);
    const [selectedMethod, setSelectedMethod] = useState(false);
    
    const types = [
    { id: 'DEPOSIT', name: 'DEPOSIT' },
    { id: 'PAYOUT', name: 'PAYOUT' },
    // Add more types as needed
    ];
    
    const methods = {
        DEPOSIT: [
            { id: 'PAYMENT', name: 'PAYMENT' },
            { id: 'PREPAYMENT', name: 'PREPAYMENT' },
        ],
        PAYOUT: [
            { id: 'WIRE TRANSFER', name: 'WIRE TRANSFER' },
            { id: 'FPS', name: 'FPS' },
        ],
        // Add more methods as needed
    };

    return (
        <Create title="Funding" {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput label="Account" source="account_id" reference="accounts/store" validate={required()}>
                <SelectInput optionText="firstName" />
            </ReferenceInput>
            <SelectInput source="fundingType" label="Type" choices={types} validate={validateType} 
            optionText="name"
            optionValue="id"
            onChange={(event, newValue) => {
                setSelectedType(newValue);
                setSelectedMethod(null); 
            }}/>
            <ReferenceInput source="asset" label="Asset" reference="system/currencies" >
                <SelectInput optionText="symbol" optionValue="symbol" />
            </ReferenceInput>
            <SelectInput source="method" label="Method" choices={methods[selectedType] || []}
            optionText="name"
            optionValue="id"
            disabled={!selectedType} />
            <NumberInput source="amount" label="Amount" />
            <NumberInput source="fee" label="Fee" defaultValue={0} />
            <SelectInput source="status" label="Status" choices={[
                { id: 'SUCCESS', name: 'SUCCESS' },
                { id: 'PENDING', name: 'PENDING' },
                { id: 'CANCELED', name: 'CANCELED' },
            ]} />
        </SimpleForm>
    </Create>
    )
}

export default FundingCreate;