import axios from 'axios'
import { getUserURL } from '../api'

const getAuthToken = () => {
    return localStorage.getItem('BITFA:DASHBOARD:AUTH:TOKEN')
  }
  const getAxiosConfig = () => ({
    headers: { 'Authorization': "Bearer " + getAuthToken() }
  })

export const getAccounts = () => axios.get(getUserURL() + "/v1/accounts",
    getAxiosConfig()
)