import React from "react";
import { Resource } from "react-admin";

import users from "./users";
import transactions from "./transactions";

import AccountList from "./accounts/AccountList";
import AccountIcon from "@material-ui/icons/AccountCircle";
import AccountCreate from "./accounts/AccountCreate";
import AccountEdit from "./accounts/AccountEdit";
import AccountShow from "./accounts/AccountShow";

import LocationList from "./locations/LocationList";
import LocationIcon from "@material-ui/icons/Store";
import LocationCreate from "./locations/LocationCreate";
import LocationEdit from "./locations/LocationEdit";
import LocationShow from "./locations/LocationShow";

import CoinList from "./coins/CoinList";
import CoinIcon from "@material-ui/icons/Album";
import CoinCreate from "./coins/CoinCreate";
import CoinEdit from "./coins/CoinEdit";
import CoinShow from "./coins/CoinShow";

import FundingList from "./fundings/FundingList";
import FundingIcon from "@material-ui/icons/AccountCircle";
import FundingCreate from "./fundings/FundingCreate";
import FundingEdit from "./fundings/FundingEdit";
import FundingShow from "./fundings/FundingShow";

import StatementList from "./statements/StatementList";
import StatementIcon from "@material-ui/icons/AccountCircle";
import StatementCreate from "./statements/StatementCreate";
import StatementEdit from "./statements/StatementEdit";

import ReportList from "./reports/ReportList";
import ReportIcon from "@material-ui/icons/AccountCircle";
import ReportCreate from "./reports/ReportCreate";
import ReportEdit from "./reports/ReportEdit";

import PaymentMethod from './payment-method';
import PaymentFee from './payment-fee';
import AppVersion from './app-version';

/**
 * @description
 * @param {*} { permissions: 'store_owner' | 'operator' }
 */
const RolePermission = (permissions) => {
	if (permissions === "store_owner") {
		return StoreMenu;
	}
	if (permissions === "system_admin") {
		return AdminMenu;
	}
	if (permissions === "operator") {
		return OperatorMenu;
	}
	if (permissions === "promoter") {
		return PromoterMenu;
	}
	if (permissions === "operator_staff" || permissions === "promoter_staff" || permissions === "store_staff") {
		return StaffMenu;
	}
};

// @note reports === documents

const AdminMenu = [
	<Resource name="users" {...users} />,
	<Resource
		name="accounts"
		list={AccountList}
		create={AccountCreate}
		edit={AccountEdit}
		show={AccountShow}
		icon={AccountIcon}
	/>,
	<Resource
		name="locations"
		list={LocationList}
		create={LocationCreate}
		edit={LocationEdit}
		show={LocationShow}
		icon={LocationIcon}
	/>,
	<Resource name="transactions" {...transactions} />,
	<Resource
		name="coins"
		list={CoinList}
		create={CoinCreate}
		edit={CoinEdit}
		show={CoinShow}
		icon={CoinIcon}
	/>,
	<Resource
		name="fundings"
		list={FundingList}
		create={FundingCreate}
		edit={FundingEdit}
		show={FundingShow}
		icon={FundingIcon}
	/>,
	<Resource
		name="statements"
		list={StatementList}
		create={StatementCreate}
		edit={StatementEdit}
		icon={StatementIcon}
	/>,
	<Resource
		name="reports"
		list={ReportList}
		create={ReportCreate}
		edit={ReportEdit}
		icon={ReportIcon}
	/>,
	<Resource
		name="system/paymentmethods"
		list={PaymentMethod.list}
		create={PaymentMethod.create}
		edit={PaymentMethod.edit}
		icon={PaymentMethod.icon}
		show={PaymentMethod.show}
	/>,
	<Resource 
		name="system/appversions"
		list={AppVersion.list}
		create={AppVersion.create}
		edit={AppVersion.edit}
		icon={AppVersion.icon}
	/>,
	<Resource name="system/paymentmethods" />,
	<Resource name="system/appversions" />,
	<Resource name="user/types" />,
	<Resource name="accounts/store" />,
	<Resource name="account/currencies" />,
	<Resource name="system/currencies" />,
	<Resource name="system/coins" />,
];

const OperatorMenu = [
	<Resource name="users" {...users} />,
	<Resource
		name="accounts"
		list={AccountList}
		create={AccountCreate}
		edit={AccountEdit}
		show={AccountShow}
		icon={AccountIcon}
	/>,
	<Resource
		name="locations"
		list={LocationList}
		create={LocationCreate}
		edit={LocationEdit}
		show={LocationShow}
		icon={LocationIcon}
	/>,
	<Resource name="transactions" {...transactions} />,
	<Resource
		name="coins"
		list={CoinList}
		create={CoinCreate}
		edit={CoinEdit}
		show={CoinShow}
		icon={CoinIcon}
	/>,
	<Resource
		name="fundings"
		list={FundingList}
		create={FundingCreate}
		edit={FundingEdit}
		show={FundingShow}
		icon={FundingIcon}
	/>,
	<Resource
		name="statements"
		list={StatementList}
		create={StatementCreate}
		edit={StatementEdit}
		icon={StatementIcon}
	/>,
	<Resource
		name="reports"
		list={ReportList}
		create={ReportCreate}
		edit={ReportEdit}
		icon={ReportIcon}
	/>,
	<Resource name="user/types" />,
	<Resource name="accounts/store" />,
	<Resource name="account/currencies" />,
	<Resource name="system/currencies" />,
	<Resource name="system/coins" />,
];

const StoreMenu = [
	<Resource
		name="accounts"
		list={AccountList}
		create={AccountCreate}
		edit={AccountEdit}
		show={AccountShow}
		icon={AccountIcon}
	/>,
	<Resource
		name="account/paymentfees"
		list={PaymentFee.list}
		create={PaymentFee.create}
		edit={PaymentFee.edit}
		icon={PaymentFee.icon}
	/>,
	<Resource name="users" {...users} />,
	<Resource
		name="accounts"
		list={AccountList}
		create={AccountCreate}
		edit={AccountEdit}
		show={AccountShow}
		icon={AccountIcon}
	/>,
	<Resource
		name="locations"
		list={LocationList}
		create={LocationCreate}
		edit={LocationEdit}
		show={LocationShow}
		icon={LocationIcon}
	/>,
	<Resource name="transactions" {...transactions} />,
	<Resource
		name="coins"
		list={CoinList}
		create={CoinCreate}
		edit={CoinEdit}
		show={CoinShow}
		icon={CoinIcon}
	/>,
	<Resource
		name="fundings"
		list={FundingList}
		create={FundingCreate}
		edit={FundingEdit}
		show={FundingShow}
		icon={FundingIcon}
	/>,
	<Resource
		name="statements"
		list={StatementList}
		create={StatementCreate}
		edit={StatementEdit}
		icon={StatementIcon}
	/>,
	<Resource
		name="reports"
		list={ReportList}
		create={ReportCreate}
		edit={ReportEdit}
		icon={ReportIcon}
	/>,
	<Resource
		name="system/paymentmethods"
		show={PaymentMethod.show}
	/>,
	<Resource name="user/types" />,
	<Resource name="user/staffs" />,
	<Resource name="account/currencies" />,
	<Resource name="system/currencies" />,
	<Resource name="system/coins" />,
	<Resource name="system/paymentmethods" />,
	<Resource name="user/types" />,
	<Resource name="accounts/store" />,
	<Resource name="account/currencies" />,
	<Resource name="system/currencies" />,
	<Resource name="system/coins" />,
];

const PromoterMenu = [
	<Resource name="users" {...users} />,
	<Resource name="transactions" {...transactions} />,
	<Resource
		name="locations"
		list={LocationList}
		create={LocationCreate}
		edit={LocationEdit}
		show={LocationShow}
		icon={LocationIcon}
	/>,
	<Resource
		name="coins"
		list={CoinList}
		create={CoinCreate}
		edit={CoinEdit}
		show={CoinShow}
		icon={CoinIcon}
	/>,
	<Resource name="user/types" />,
];

const StaffMenu = [
	<Resource name="users" {...users} />,
	<Resource name="transactions" {...transactions} />,
	<Resource name="user/types" />,
	<Resource name="account/currencies" />,
	<Resource name="system/currencies" />,
	<Resource name="system/coins" />,
];

export { RolePermission };
