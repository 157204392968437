// in src/MyUrlField.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import LaunchIcon from '@material-ui/icons/Launch';
// import {
//     Labeled
// } from 'react-admin';
import Moment from 'react-moment';
import mtimezone from 'moment-timezone';

const styles = {
};


const CustomDateTimeField = ({ record = {}, source, classes }) => {

    if (record && record.createdAt && source === 'createdAt') {
        return (
            <Moment tz="Asia/Shanghai" format="YYYY-MM-DD HH:mm">
                {mtimezone.tz(record.createdAt, "YYYY-MM-DD HH:mm:ss.SSSSSS Z z", "Asia/Shanghai").toDate()}
            </Moment>
        );
    } 
    else if (record && record.updatedAt && source === 'updatedAt') {
        return (
            <Moment tz="Asia/Shanghai" format="YYYY-MM-DD HH:mm">
                {mtimezone.tz(record.updatedAt, "YYYY-MM-DD HH:mm:ss.SSSSSS Z z", "Asia/Shanghai").toDate()}
            </Moment>
        );
    }
    else {
        return (
            <span style={{ color: "grey" }}> N/A </span>
        )
    }
}
export default withStyles(styles)(CustomDateTimeField);