import axios from 'axios'
import { getUserURL } from '../api'

const getAuthToken = () => {
    return localStorage.getItem('BITFA:DASHBOARD:AUTH:TOKEN')
  }
  const getAxiosConfig = () => ({
    headers: { 'Authorization': "Bearer " + getAuthToken() }
  })

export const settleTransactions = (ids, isSettle) => axios.post(getUserURL() + "/v1/transactions/settle",
  {
    ids,
    isSettle
  },
  getAxiosConfig()
)