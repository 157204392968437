import React from 'react';
import {
    Create,
    TextInput,
    TabbedForm,
    FormTab,
    ReferenceInput,
    SelectInput,
    required,
    minLength,
    maxLength,
    // regex,
    choices,

} from 'react-admin';

const validateBusinessName = [required(), minLength(2), maxLength(50)];
const validateStoreName = [required(), minLength(2), maxLength(20)];
const validateZipCode = [required()];
//const validateZipCode = [required(), regex(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Must be a valid Zip Code')];
const validateCountry = [required()];
const country = [
    { id: 'Hong Kong, China', name: 'Hong Kong, China' },
    { id: 'Taiwan, China', name: 'Taiwan, China' },
    { id: 'Canada', name: 'Canada' },
    { id: 'USA', name: 'USA' },
]
const LocationCreate = props => (
    <Create title="Location" {...props} redirect="list">
        <TabbedForm>
            <FormTab label="resources.locations.tabs.info">
                <ReferenceInput label="Staff" source="staff_id" reference="user/staffs" validate={required()}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <TextInput
                    source="name" label="Company Name" validate={validateBusinessName}
                />
                <TextInput
                    source="nickName" label="Store Name" validate={validateStoreName}
                />
                {/* <TextInput
                    source="email" validate={validateEmail} label="Email"
                />
                <TextInput
                    source="phoneNumber" validate={validatePhoneNumber}
                /> */}
                <TextInput
                    source="description"
                />
            </FormTab>
            <FormTab label="resources.accounts.tabs.address">
                <TextInput source="address.address1" label="Address1" validate={required()}/>
                <TextInput source="address.address2" label="Address2" />
                <TextInput source="address.city" label="City" validate={required()} />
                <TextInput source="address.state" label="State"  validate={required()}/>
                <TextInput source="address.postalCode" label="Postal Code" validate={validateZipCode}/>
                <SelectInput source="address.country" label="Country"  choices={country} validate={validateCountry} />
            </FormTab>
            {/* <FormTab label="resources.accounts.tabs.doc">
                <FileInput source="files" label="Business proof. For example, business license and certificate of coorpation." accept="application/pdf" multiple={true} maxSize={1000000}>
                    <FileField source="base64" title="title" />
                </FileInput>
            </FormTab> */}
        </TabbedForm>
    </Create>
);

export default LocationCreate;
