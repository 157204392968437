var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React, { cloneElement, Component, Children } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LinearProgress from '@material-ui/core/LinearProgress';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { linkToRecord } from 'ra-core';
import Link from '../Link';
var styles = createStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  }
});
// useful to prevent click bubbling in a datagrid with rowClick
var stopPropagation = function stopPropagation(e) {
  return e.stopPropagation();
};
var sanitizeRestProps = function sanitizeRestProps(_a) {
  var currentSort = _a.currentSort,
    setSort = _a.setSort,
    isLoading = _a.isLoading,
    loadedOnce = _a.loadedOnce,
    props = __rest(_a, ["currentSort", "setSort", "isLoading", "loadedOnce"]);
  return props;
};
/**
 * Iterator component to be used to display a list of entities, using a single field
 *
 * @example Display all the books by the current author
 * <ReferenceManyField reference="books" target="author_id">
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * By default, it includes a link to the <Edit> page of the related record
 * (`/books/:id` in the previous example).
 *
 * Set the linkType prop to "show" to link to the <Show> page instead.
 *
 * @example
 * <ReferenceManyField reference="books" target="author_id" linkType="show">
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 *
 * You can also prevent `<SingleFieldList>` from adding link to children by setting
 * `linkType` to false.
 *
 * @example
 * <ReferenceManyField reference="books" target="author_id" linkType={false}>
 *     <SingleFieldList>
 *         <ChipField source="title" />
 *     </SingleFieldList>
 * </ReferenceManyField>
 */
var SingleFieldList = /** @class */function (_super) {
  __extends(SingleFieldList, _super);
  function SingleFieldList() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    // Our handleClick does nothing as we wrap the children inside a Link but it is
    // required fo ChipField which uses a Chip from material-ui.
    // The material-ui Chip requires an onClick handler to behave like a clickable element
    _this.handleClick = function () {};
    return _this;
  }
  SingleFieldList.prototype.render = function () {
    var _this = this;
    var _a = this.props,
      _b = _a.classes,
      classes = _b === void 0 ? {} : _b,
      className = _a.className,
      ids = _a.ids,
      data = _a.data,
      loadedOnce = _a.loadedOnce,
      resource = _a.resource,
      basePath = _a.basePath,
      children = _a.children,
      linkType = _a.linkType,
      rest = __rest(_a, ["classes", "className", "ids", "data", "loadedOnce", "resource", "basePath", "children", "linkType"]);
    if (loadedOnce === false) {
      return React.createElement(LinearProgress, null);
    }
    return React.createElement("div", __assign({
      className: classnames(classes.root, className)
    }, sanitizeRestProps(rest)), ids.map(function (id) {
      var resourceLinkPath = !linkType ? false : linkToRecord(basePath, id, linkType);
      if (resourceLinkPath) {
        return React.createElement(Link, {
          className: classnames(classes.link, className),
          key: id,
          to: resourceLinkPath,
          onClick: stopPropagation
        }, cloneElement(Children.only(children), {
          record: data[id],
          resource: resource,
          basePath: basePath,
          // Workaround to force ChipField to be clickable
          onClick: _this.handleClick
        }));
      }
      return cloneElement(Children.only(children), {
        key: id,
        record: data[id],
        resource: resource,
        basePath: basePath
      });
    }));
  };
  return SingleFieldList;
}(Component);
export { SingleFieldList };
SingleFieldList.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.element.isRequired,
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  ids: PropTypes.array,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  resource: PropTypes.string
};
SingleFieldList.defaultProps = {
  classes: {},
  linkType: 'edit'
};
export default withStyles(styles)(SingleFieldList);