// Send Reset Request
export const SET_SETTLE_TYPE = 'SET_SETTLE_TYPE'
export const SET_CREDIT = 'SET_CREDIT'
export const FETCH_ACCOUNT = 'FETCH_ACCOUNT'

export const setSettleType = (settleType) => ({
  type: SET_SETTLE_TYPE,
  settleType
})

export const setCredit = (credit) => ({
  type: SET_CREDIT,
  credit,
})

export const fetchAccount = () => ({
  type: FETCH_ACCOUNT,
})