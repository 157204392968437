import React from 'react';
import {
    List,
    Datagrid,
    TextField,
} from 'react-admin';


const OperatorList = props => (
    <List {...props} pagination={null} bulkActionButtons={false} exporter={false}>
        <Datagrid rowClick="show">
            <TextField source="name" label="Name" />
            <TextField source="email" label="Email" />
            <TextField source="phone" label="Phone" />
        </Datagrid>
    </List>
);
export default OperatorList;
