import React from 'react';
import {
    Edit,
    ReferenceField,
    TextField,
    SelectInput,
    SimpleForm,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';


const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const ReportEdit = ({ classes, ...props }) => (
    <Edit title="Report" {...props}>
        <SimpleForm>

            {/* <CustomDateTimeField source="createdAt" showTime options={{ timeZone:"America/Los_Angeles" }}/> */}
            <ReferenceField label="Account" source="account_id" reference="accounts" linkType="show">
                <TextField source="firstName" />
            </ReferenceField>
            <SelectInput source="status" label="Status" choices={[
                { id: 'APPROVED', name: 'APPROVED' },
                { id: 'PENDING', name: 'PENDING' },
            ]} />
        </SimpleForm>
    </Edit>
);

export default withStyles(styles)(ReportEdit);
