import { parseSettingRate } from "./currency.parser";
import Big from 'big.js'

const coinsParser = (response, type, resource, params) => {

    if (type === 'GET_ONE' && resource === 'coins') {
        console.log("coinsParser::response", response);
        const respData = response.json;
        const commissionRateSettingBuy = parseSettingRate(respData.commissionRateSetting.buy);
        const commissionRateSettingSell = parseSettingRate(respData.commissionRateSetting.sell);
        const currencyMap = {
            respData: respData,
            commissionRateSettingBuy,
            commissionRateSettingSell,
        };
        window._currentCoinCurrencyMap = currencyMap;
        // 添加上自定义的resource

        respData.commissionRateSetting.buy.forEach(x=>{
            response.json[`commissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        respData.commissionRateSetting.sell.forEach(x=>{
            response.json[`commissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        return response;
    } else {
        return response;
    }

}

export default coinsParser;