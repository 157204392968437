import React, { useState, useEffect } from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    NumberField,
    ReferenceField
} from 'react-admin';

import CustomBuySpreadField from "./CustomBuySpreadField"
import CustomSellSpreadField from "./CustomSellSpreadField"

const inputStyle = { width: "20em" };

const itemStyle = {
    width: '75%',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '8px'
}

const showItemRateStyle = {
    fontSize: "14px",
    color: "#000000DE",
}

const titleStyle = {
    fontSize: "12px",
    color: "#0000008A"

}

let interval = 0;
const CoinShow = props => {

    const [selectSymbol, setSymbol] = React.useState('');
    const [selectCurrency, setCurrency] = React.useState('');

    const [currencyRate, setCurrencyRate] = React.useState(null);

    useEffect(() => {
        interval = setInterval(() => {
            if (window._currentCoinCurrencyMap) {
                onCurrencyChange();
            }
        }, 300);
        return () => {
            clearInterval(interval);
        }
    }, []);

    const onCurrencyChange = () => {
        // console.log('window._currentCoinCurrencyMap', window._currentCoinCurrencyMap);
        if (window._currentCoinCurrencyMap) {
            const _rateObj = window._currentCoinCurrencyMap;
            if (_rateObj) {
                setCurrencyRate(_rateObj);
                setSymbol(_rateObj.respData.symbol);
                setCurrency(_rateObj.respData.currency);
            }
        }
    }

    return (
        <Show title="Coin" {...props}>
            <SimpleShowLayout>
                {/* <ReferenceField label="Location" source="location_id" reference="locations" linkType="show">
                    <TextField source="nickName" />
                </ReferenceField> */}
                <TextField source="symbol" label="Coin Symbol" />
                <TextField source="currency" label="Currency Symbol" />
                {/* <CustomBuySpreadField source="buySpread" />
                <CustomSellSpreadField source="sellSpread" /> */}
                <NumberField source="txMax" label="Tx Max. (HKD)" />
                <NumberField source="txMin" label="Tx Min. (HKD)" />
                {
                    (currencyRate && (
                        <div style={showItemRateStyle}>
                            <p style={titleStyle}>Buy Commission Rate Setting</p>
                            {currencyRate.commissionRateSettingBuy.map((x, i) => {
                                return (
                                    <div key={x.key} style={itemStyle}>
                                        <span>{x.key + ': '}</span>
                                        <span>{x.rate + '%'}</span>
                                    </div>
                                )
                            })}
                            <p style={titleStyle}>Sell Commission Rate Setting</p>
                            {currencyRate.commissionRateSettingSell.map((x, i) => {
                                return (
                                    <div key={x.key} style={itemStyle}>
                                        <span>{x.key + ': '}</span>
                                        <span>{x.rate + '%'}</span>
                                    </div>
                                )
                            })}
                        </div>
                    ))
                }
            </SimpleShowLayout>
        </Show>
    )
};

export default CoinShow;