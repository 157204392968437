import React from 'react';
import {
    Create,
    SelectInput,
    TextInput,
    BooleanInput,
    TabbedForm,
    FormTab,
    required,
    minLength,
    maxLength,
    // regex,
    email,
    choices,
    ReferenceInput,
} from 'react-admin';

const country = [
    { id: 'Hong Kong, China', name: 'Hong Kong, China' },
    { id: 'Taiwan, China', name: 'Taiwan, China' },
    { id: 'Canada', name: 'Canada' },
    { id: 'USA', name: 'USA' },
]
const countrySymbol = [
    { id: 'HKG', name: 'HKG' },
    { id: 'TW', name: 'TW' },
    { id: 'CAN', name: 'CAN' },
    { id: 'USA', name: 'USA' },
]
const validateFirstName = [required(), minLength(2), maxLength(15)];
const validateLastName = [required(), minLength(2), maxLength(15)];
// const validateEmail =[required(), email()];
// const validatePhoneNumber = [required()];
// const validateZipCode = [required()];
//const validatePhoneNumber = [required(), regex(/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/, 'Must be a phone number')];
//const validateZipCode = [required(), regex(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/, 'Must be a valid Zip Code')];
// const validateCountry = [required(), choices(['Hong Kong, China'], 'Must be a country name')];
// const validateCountrySymbol = [required(), choices(['HKG'], 'Must be a country symbol')];
const hiddenStyle = { display: 'none' };
const inputStyle = { width: '20em' };

const AccountCreate = (props) => (
    <Create title="Account" {...props}>
        <TabbedForm redirect="list">
            <FormTab label="resources.accounts.tabs.identity">
                <TextInput
                    source="firstName" validate={validateFirstName}
                />
                <TextInput
                    source="lastName" validate={validateLastName}
                />
                <ReferenceInput source="currencySymbol" label="Default Currency" reference="system/currencies" >
                    <SelectInput optionText="symbol" optionValue="symbol"/>
                </ReferenceInput>
                {/* <SelectInput source="currencySymbol" label="Default Currency"  choices={currency}  /> */}
                <SelectInput source="countrySymbol" label="Country Symbol"  choices={countrySymbol}  />
            </FormTab>
            <FormTab label="resources.accounts.tabs.address">
                <TextInput source="address.address1" label="Street Address" validate={required()} />
                <TextInput source="address.address2" label="Unit" />
                <TextInput source="address.city"  label="City" validate={required()} />
                <TextInput source="address.state"  label="State" placeholder={"BC"} validate={required()}/>
                <TextInput source="address.postalCode" label="Postal Code" validate={required()} />
                <SelectInput source="address.country" label="Country"  choices={country}  />
            </FormTab>
            <FormTab label="resources.accounts.tabs.settings" style={ hiddenStyle}>
                <BooleanInput label="Active" source="active"  defaultValue={false} options={{ disabled: 'true' }} />
                <BooleanInput label="Multi factor authentication" source="active"  defaultValue={false} options={{ disabled: 'true' }} />
            </FormTab>
        </TabbedForm>
    </Create>
);


export default AccountCreate;
