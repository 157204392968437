import React, { useState, useEffect } from "react";
import Jquery from 'jquery';
import {
	Edit,
	SimpleForm,
	TextInput,
	NumberInput,
	ReferenceInput,
	required,
	SelectInput,
	BooleanInput,
	ImageInput,
	ImageField,
	Toolbar,
	SaveButton,
	minValue
} from "react-admin";

const CustomToolbar = props => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

const PaymenmtFeeEdit = ({ permissions, ...props }) => {
	const [customize, setCustomize] = useState(false);

	useEffect(()=>{
		const val = Jquery("#feeRateValue").val();
		console.log("val", val);
		if(val === '-1') {
			setCustomize(true);
		}
	}, [])

	return (
		<Edit title="Payment Fee" {...props}>
			<SimpleForm redirect="list" toolbar={<CustomToolbar />}>
				<ReferenceInput
					reference="system/paymentmethods"
					label="Payment Method"
					source="payment_method_id"
				>
					<SelectInput optionText="label" />
				</ReferenceInput>
				{!customize && <NumberInput source="fee_rate" validate={[required(), minValue(0)]} id="feeRateValue" />}
				<NumberInput source="sort" step={1} validate={[required()]} />
				<BooleanInput
					label="Customize: use or not"
					source="_customize"
					defaultValue={customize}
					onChange={(e) => {
						console.log(e.currentTarget.checked);
						setCustomize(e.currentTarget.checked);
					}}
				/>
				<BooleanInput
					label="Status: use or not"
					source="status"
					defaultValue={false}
				/>
			</SimpleForm>
		</Edit>
	);
};

export default PaymenmtFeeEdit;
