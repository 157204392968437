import React from 'react';
import { Show, SimpleShowLayout, TextField } from 'react-admin';

const OperatorShow = props => (
    <Show title="Operator" {...props}>
        <SimpleShowLayout>
            <TextField source="name" label="Name" />
            <TextField source="email" label="Email" />
            <TextField source="phone" label="Phone" />
        </SimpleShowLayout>
    </Show>
);

export default OperatorShow;