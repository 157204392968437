import React from 'react';
import { BooleanField } from 'react-admin';

const CustomeSettleField = ({ record, ...rest }) =>{
    if (record && record.isSettled) {
        return  (
            <BooleanField source="isSettled" label="Two Factor Authentication"  record={record} {...rest} style={{ color: 'green' }}/>
        )
    }
    else {
        return  (
            <BooleanField source="isSettled" label="Two Factor Authentication"  record={record} {...rest} style={{ color: 'red' }}/>
        )
    }

}


export default CustomeSettleField;