import Big from 'big.js';

const coinsProvider = requestHandler => (type, resource, params) => {

    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'coins') {
        // console.log("coinsProvider::params.data", params.data);
        const storeCommissionRateSetting = {
            buy: [],
            sell: []
        }
        const systemCommissionRateSetting = {
            buy: [],
            sell: []
        }
        const operatorCommissionRateSetting = {
            buy: [],
            sell: []
        }
        const promoterCommissionRateSetting = {
            buy: [],
            sell: []
        }
        Object.keys(params.data).forEach(key => {
            if (key.startsWith('storeCommissionRateSetting')) {
                if (key.includes('Buy')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    storeCommissionRateSetting.buy.push(obj);
                }
                if (key.includes('Sell')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    storeCommissionRateSetting.sell.push(obj);
                }
                delete params.data[key];
            }
            if (key.startsWith('systemCommissionRateSetting')) {
                if (key.includes('Buy')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    systemCommissionRateSetting.buy.push(obj);
                }
                if (key.includes('Sell')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    systemCommissionRateSetting.sell.push(obj);
                }
                delete params.data[key];
            }
            if (key.startsWith('operatorCommissionRateSetting')) {
                if (key.includes('Buy')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    operatorCommissionRateSetting.buy.push(obj);
                }
                if (key.includes('Sell')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    operatorCommissionRateSetting.sell.push(obj);
                }
                delete params.data[key];
            }
            if (key.startsWith('promoterCommissionRateSetting')) {
                if (key.includes('Buy')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    promoterCommissionRateSetting.buy.push(obj);
                }
                if (key.includes('Sell')) {
                    const obj = {
                        max: Number(key.split('_')[1]),
                        rate: parseFloat(new Big(params.data[key] / 100).toFixed(4))
                    };
                    promoterCommissionRateSetting.sell.push(obj);
                }
                delete params.data[key];
            }
        });
        params.data.storeCommissionRateSetting = storeCommissionRateSetting;
        params.data.systemCommissionRateSetting = systemCommissionRateSetting;
        params.data.operatorCommissionRateSetting = operatorCommissionRateSetting;
        params.data.promoterCommissionRateSetting = promoterCommissionRateSetting;
    }

    return requestHandler(type, resource, params);
}

export default coinsProvider;