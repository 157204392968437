import React from 'react';
import { NumberField, Labeled } from 'react-admin';

const CustomSellSpreadField = ({ record, ...rest }) =>{
        var platformSpread = record.systemSellSpread;
        var exchangeSpread = record.exchangeSellSpread;
        var customerSpread = Number(Math.round(record.sellSpread+platformSpread+exchangeSpread+'e3')+'e-3');
        return  (
            <div>
            <Labeled label="Sell Spread">
            <NumberField source="sellSpread" label="Sell Spread (including platform fee)"  record={record} {...rest} />
            </Labeled>
            <span style={ {fontSize : 14 , color: "grey"}}>(Note: sell spread for customer is <span style={ {color : "red"}}> {customerSpread} </span> , which includes {platformSpread} platform fee and {exchangeSpread} exchange fee.)</span>
            </div>
        )
    
}


export default CustomSellSpreadField;