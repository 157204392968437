import englishMessages from 'ra-language-english';

export default {
    ...englishMessages,
    pos: {
        search: 'Search',
        configuration: 'Configuration',
        language: 'Language',
        theme: {
            name: 'Theme',
            light: 'Light',
            dark: 'Dark',
        },
        dashboard: {
            monthly_revenue: 'Monthly Revenue',
            new_orders: 'New Orders',
            transactions: "Transactions",
            account_balance: "Account Balance",
            available_credit: "Avaiable Credit",
            credit_limit: "Credit Limit",
            account_number: "Account Number",
            locations: "Locations",
            total_commi: "Total Commission",
            total_sales: "Total Sales",
            pending_reviews: 'Pending Reviews',
            new_customers: 'New Customers',
            pending_orders: 'Pending Orders',
            order: {
                items:
                    'by %{customer_name}, one item |||| by %{customer_name}, %{nb_items} items',
            },
            welcome: {
                title: 'Welcome to our dashboard',
                subtitle:
                    "Feel free to explore and contact with us if you need anything.",
                aor_button: 'Official Website',
                demo_button: 'Contact US',
            },
        },
        menu: {
            sales: 'Sales',
            catalog: 'Catalog',
            customers: 'Customers',
            business: 'Business',
            docs: 'Documents',
        },
    },
    resources: {
        customers: {
            name: 'Customer |||| Customers',
            fields: {
                commands: 'Orders',
                groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                total_spent: 'Total spent',
            },
            tabs: {
                identity: 'Identity',
                address: 'Address',
                orders: 'Orders',
                reviews: 'Reviews',
                stats: 'Stats',
            },
            page: {
                delete: 'Delete Customer',
            },
        },
        accounts: {
            name: 'Account |||| Accounts',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            tabs: {
                identity: 'Identity',
                address: 'Address',
                credit: 'Credit',
                doc:'Document',
                settings: 'Settings',
            },
            page: {
                delete: 'Delete Account ',
            },
        },
        locations: {
            name: 'Location |||| Locations',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            tabs: {
                info: 'Info',
                address: 'Address',
                // credit: 'Credit',
            },
            page: {
                delete: 'Delete Location ',
            },
        },
        coins: {
            name: 'Coin |||| Coins',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            page: {
                delete: 'Delete Coin ',
            },
        },
        operators: {
            name: 'Operator |||| Operators',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            page: {
                delete: 'Delete Operator ',
            },
        },
        users: {
            name: 'User |||| Users',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            page: {
                delete: 'Delete User ',
            },
        },
        fundings: {
            name: 'Funding |||| Fundings',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            page: {
                delete: 'Delete Funding ',
            },
        },
        paymentfees: {
            name: 'Payment fee'
        },
        reports: {
            name: 'Tax Report |||| Tax Reports',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            page: {
                delete: 'Delete Tax Report ',
            },
        },
        statements: {
            name: 'Sales Statement |||| Sales Statements',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            page: {
                delete: 'Delete Sales Statement ',
            },
        },
        transactions: {
            name: 'Transaction |||| Transactions',
            fields: {
                // commands: 'Orders',
                // groups: 'Segments',
                last_seen_gte: 'Visited Since',
                name: 'Name',
                // total_spent: 'Total spent',
            },
            page: {
                delete: 'Delete Transaction ',
            },
        },
        commands: {
            name: 'Order |||| Orders',
            title: 'Order %{reference}',
            fields: {
                basket: {
                    delivery: 'Delivery',
                    reference: 'Reference',
                    quantity: 'Quantity',
                    sum: 'Sum',
                    tax_rate: 'Tax Rate',
                    total: 'Total',
                    unit_price: 'Unit Price',
                },
                customer_id: 'Customer',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                status: 'Status',
                returned: 'Returned',
            },
        },
        invoices: {
            name: 'Invoice |||| Invoices',
            fields: {
                date: 'Invoice date',
                customer_id: 'Customer',
                command_id: 'Order',
                date_gte: 'Passed Since',
                date_lte: 'Passed Before',
                total_gte: 'Min amount',
                address: 'Address',
            },
        },
        products: {
            name: 'Poster |||| Posters',
            fields: {
                category_id: 'Category',
                height_gte: 'Min height',
                height_lte: 'Max height',
                height: 'Height',
                image: 'Image',
                price: 'Price',
                reference: 'Reference',
                stock_lte: 'Low Stock',
                stock: 'Stock',
                thumbnail: 'Thumbnail',
                width_gte: 'Min width',
                width_lte: 'Max width',
                width: 'Width',
            },
            tabs: {
                image: 'Image',
                details: 'Details',
                description: 'Description',
                reviews: 'Reviews',
            },
        },
        categories: {
            name: 'Category |||| Categories',
            fields: {
                products: 'Products',
            },
        },
        reviews: {
            name: 'Review |||| Reviews',
            detail: 'Review detail',
            fields: {
                customer_id: 'Customer',
                command_id: 'Order',
                product_id: 'Product',
                date_gte: 'Posted since',
                date_lte: 'Posted before',
                date: 'Date',
                comment: 'Comment',
                rating: 'Rating',
            },
            action: {
                accept: 'Accept',
                reject: 'Reject',
            },
            notification: {
                approved_success: 'Review approved',
                approved_error: 'Error: Review not approved',
                rejected_success: 'Review rejected',
                rejected_error: 'Error: Review not rejected',
            },
        },
        segments: {
            name: 'Segments',
            fields: {
                customers: 'Customers',
                name: 'Name',
            },
            data: {
                compulsive: 'Compulsive',
                collector: 'Collector',
                ordered_once: 'Ordered once',
                regular: 'Regular',
                returns: 'Returns',
                reviewer: 'Reviewer',
            },
        },
        payment_method: {
            name: 'Payment Method',
        },
        app_versions: {
            name: 'App Versions'
        }
    },
};
