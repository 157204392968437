import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField,

} from 'react-admin';


const CoinList = props => (
    <List {...props} pagination={null} bulkActionButtons={false} exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="symbol" label="Symbol" />
            <TextField source="currency" label="Currency" />
        </Datagrid>
    </List>
);
export default CoinList;