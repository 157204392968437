import { all,put, call, takeLatest } from 'redux-saga/effects';
import { SEND_RESET_REQ, SEND_CODE_VAL, SEND_UPDATE_REQ } from './passwordActions';
import {
  setIsReqSent,
  setResetReqErr,
  setIsUpdated,
  setUpdateErr,
  setIsVal,
  setValErr
 } from './passwordActions';

import { sendPwdResetReq, valCode, updatePwd } from './passwordServices'

export function* sendPwdReset(action) {
  console.log("Saga send PasswordReset Request ")
  console.log(action.email)
  try {
    const res = yield call(() => sendPwdResetReq(
      action.email
    ))
    console.log(res.data)
    yield put(setIsReqSent())

  } catch (e) {
    console.log(e)
    console.log("email err")
    yield put(setResetReqErr('Email is invalid or server error.'))
  }
}

export function* sendResetCode(action) {
  console.log("Saga sendResetCode ")
  try {
    const res = yield call(() => valCode(
      action.code
    ))
    console.log(res.data)
    yield put(setIsVal())

  } catch (e) {
    console.log(e)
    yield put(setValErr('Reset code is not valid or server error.'))
  }
}

export function* sendUpdatePwd(action) {
  console.log(" saga updatePasswordWithCode ")
  try {
    const res = yield call(() => updatePwd(
      action.code,
      action.password
    ))
    console.log(res.data)
    yield put(setIsUpdated())

  } catch (e) {
    console.log(e)
    yield put(setUpdateErr('Password udpate failed.'))
  }
}


export default function* passwordSaga() {
  yield all([
    takeLatest(SEND_RESET_REQ, sendPwdReset),
    takeLatest(SEND_CODE_VAL, sendResetCode),
    takeLatest(SEND_UPDATE_REQ, sendUpdatePwd),
  ]);
}
