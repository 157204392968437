import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    CreateButton,
    CardActions, TextField,
    DateInput,
} from 'react-admin';
import { validateRequired, validateEmail, inputStyle } from './UserCreate';

export const UserEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextField source="name" label="User ID" />
                <TextInput source="firstName" label="First Name" validate={validateRequired} style={inputStyle} />
                <TextInput source="lastName" label="Last Name" validate={validateRequired} style={inputStyle} />
                <TextInput source="middleName" label="Middle Name" style={inputStyle} />
                <DateInput source="dateOfBirth" label="Date Of Birth" validate={validateRequired} style={inputStyle} />
                <TextField source="email" label="Email" />
                <TextInput source="phoneNumber" label="Phone Number" validate={validateRequired} style={inputStyle} />
                <TextField source="userType" label="User Type" />
            </SimpleForm>
        </Edit>
    )
};


export default UserEdit;