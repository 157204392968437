import React from 'react';
import { WithPermissions, MenuItemLink } from "react-admin";

import accounts from "../../accounts";
import locations from "../../locations";
import coins from "../../coins";
import transactions from "../../transactions";
import fundings from "../../fundings";
import statements from "../../statements";
import SubMenu from "../SubMenu";
import reports from "../../reports";
import paymentfees from "../../payment-fee";

import FileIcon from "@material-ui/icons/InsertDriveFile";
import BusinessIcon from "@material-ui/icons/Business";

// Store Owner / User menu
export const StoreSideMenu = ({ handleToggle, menuBusiness, menuDocs, menuCatalog, open, translate, onMenuClick }) => (
    <WithPermissions
        render={({ permissions }) => (
            permissions === 'store_owner'
                ? <>
                    <MenuItemLink
                        to={`/accounts`}
                        primaryText={translate(`resources.accounts.name`, {
                            smart_count: 2
                        })}
                        leftIcon={<accounts.icon />}
                        onClick={onMenuClick}
                    />
                    
                    <SubMenu
                        handleToggle={() => handleToggle("menuBusiness")}
                        isOpen={menuBusiness}
                        sidebarIsOpen={open}
                        name="pos.menu.business"
                        icon={<BusinessIcon />}
                    >
                        <MenuItemLink
                            to={`/locations`}
                            primaryText={translate(`resources.locations.name`, {
                                smart_count: 2
                            })}
                            leftIcon={<locations.icon />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={`/coins`}
                            primaryText={translate(`resources.coins.name`, {
                                smart_count: 2
                            })}
                            leftIcon={<coins.icon />}
                            onClick={onMenuClick}
                        />
                    </SubMenu>
                    <MenuItemLink
                        to={`/fundings`}
                        primaryText={translate(`resources.fundings.name`, {
                            smart_count: 2
                        })}
                        leftIcon={<fundings.icon />}
                        onClick={onMenuClick}
                    />
                    <MenuItemLink
                        to={`/account/paymentfees`}
                        primaryText={translate(`resources.paymentfees.name`, {
                            smart_count: 2
                        })}
                        leftIcon={<paymentfees.icon />}
                        onClick={onMenuClick}
                    />
                    {/*
                    <SubMenu
                        handleToggle={() => handleToggle("menuDocs")}
                        isOpen={menuDocs}
                        sidebarIsOpen={open}
                        name="pos.menu.docs"
                        icon={<FileIcon />}
                    >
                        <MenuItemLink
                            to={`/reports`}
                            primaryText={translate(`resources.reports.name`, {
                                smart_count: 2
                            })}
                            leftIcon={<reports.icon />}
                            onClick={onMenuClick}
                        />
                        <MenuItemLink
                            to={`/statements`}
                            primaryText={translate(`resources.statements.name`, {
                                smart_count: 2
                            })}
                            leftIcon={<statements.icon />}
                            onClick={onMenuClick}
                        />
                    </SubMenu>
                    */}
                </>
                : null
        )}
    >
    </WithPermissions>
)