"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var actions_1 = require("../../actions");
var initialState = {
  isLoggedIn: false
};
var authReducer = function authReducer(previousState, action) {
  if (previousState === void 0) {
    previousState = initialState;
  }
  switch (action.type) {
    case actions_1.USER_LOGIN_SUCCESS:
      return __assign({}, previousState, {
        isLoggedIn: true
      });
    case actions_1.USER_LOGOUT:
      return __assign({}, previousState, {
        isLoggedIn: false
      });
  }
  return previousState;
};
exports.isLoggedIn = function (state) {
  return state.isLoggedIn;
};
exports.default = authReducer;