import React from 'react';
import {
    Edit,
    TextInput,
    TabbedForm,
    FormTab,
    Toolbar,
    SaveButton,
    TextField,

} from 'react-admin';

// const LocationTitle = ({ record }) => {
//     return <span>Locations {record ? `"${record.nickName}"` : ''}</span>;
// };
const LocationEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
const LocationEdit = props => (
    // <Edit title={<LocationTitle />} {...props}>
    <Edit title="Location" {...props}>
        <TabbedForm toolbar={<LocationEditToolbar />}>
            <FormTab label="resources.locations.tabs.info" >
                <TextField source="staff_name" label="Staff"/>
                <TextInput
                    source="nickName" label="Store Name"
                />
                <TextInput
                    source="description"
                />
                <TextInput
                    source="hours" label="Business Hours"
                />
            </FormTab>
            <FormTab label="resources.locations.tabs.address">
                <TextInput source="address.address1" label="Address1"/>
                <TextInput source="address.address2" label="Address2" />
                <TextInput source="address.city" label="City" />
                <TextInput source="address.state" label="State"/>
                <TextInput source="address.postalCode" label="Postal Code" />
                <TextInput source="address.country"  label="Country"/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

export default LocationEdit;
