import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Button from '../common/Button';
import FullPageLoader from '../common/FullPageLoader';
import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';
// import Error from '@material-ui/icons/Error';
// import CheckCircle from '@material-ui/icons/CheckCircle';
import LoginFormMessage from './LoginFormMessage';
import { ErrorWrapper } from '../common/ErrorWrapper'
// import { validatePassword } from 'js-utils/lib/string';

import {
  sendUpdateReq,
} from './passwordActions';

const styles = theme => ({
  form: {
    padding: '0 1em 1em 1em',
  },
  loadingContainer: {
    position: 'relative',
    height: 300,
  },
  heading: {
    opacity: 0.8,
    textTransform: 'uppercase',
    textAlign: 'left',
    marginTop: 20,
    color: theme.palette.primary['500'],
  },
  submit: {
    marginTop: 30,
    marginBottom: 30
  },
});

class ChangePasswordForm extends Component {

  state = {
    loading: false,
    done: false,
    password: '',
    confirmPassword: '',
  }

  validatePassword = password => {
    const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    return re.test(String(password));
  }

  toggleLoading = () => {
    this.setState({
      loading: !this.state.loading,
    });
  }

  passwordFeedbackError = () => {
    if(this.state.password === '') {
      return false
    }
    return !this.validatePassword(this.state.password)
  }

  confirmPasswordFeedbackError = () => {
    if(this.state.confirmPassword === '') {
      return false
    }
    return this.state.password !== this.state.confirmPassword
  }

  canSubmit = () => {
    return this.state.password === this.state.confirmPassword
      // && this.validatePassword(this.state.password);
  }

  onSubmit = () => {
    this.toggleLoading();
    console.log(this.props.code)
    console.log(this.state.password)

    this.props.sendUpdateReq(
      this.props.code,
      this.state.password,
    )
  }

  componentDidMount() {
    console.log("validating")
    // this.props.validateResetPasswordCodeAction(this.props.code);
  }

  onInputChange = key => event => {
    this.setState({
      [key]: event.target.value,
    });
  }

  renderLoading() {
    const { classes } = this.props;
    return (
      <div className={classes.loadingContainer}>
        <FullPageLoader hideLogo={true} />
      </div>
    );
  }

  renderError() {
    const { updateErr } = this.props
    console.log(updateErr)
    if (updateErr) {
      return (
        <ErrorWrapper>{updateErr}</ErrorWrapper>
      )
    }
    return null
  }


  resetView =() => {
    window.location.assign('/')
  }


  renderDone() {
    // const { translations, resetView } = this.props;
    // const loginTranslations = translations['login-forms'];

    return (
      <LoginFormMessage
        // Icon={CheckCircle}
        // btnText={loginTranslations['return-to-login']}
        // message={loginTranslations['reset-password-success']}
        btnText='return to login'
        message='Your password has been updated'
        onClick={this.resetView}
      />
    )
  }

  render() {
    console.log(this.props)
    const { classes, isUpdated } = this.props;

    // const { loading, error } = passwordResetCode;
    // if (loading) {
    //   return this.renderLoading();
    // }

    // if (error) {
    //   return this.renderError();
    // }

    console.log(isUpdated)
    if (isUpdated) {
      return this.renderDone();
    }

    return (
      <div className={classes.form}>
        {/* <Typography variant="subheading" className={classes.heading}> */}
          {/* { loginTranslations['set-new-password'] } */}
          {/* set new password */}
        {/* </Typography> */}
        <div>
        <TextField
          // error={this.passwordFeedbackError()}
          // fullWidth
          // label={loginTranslations['new-password'].toUpperCase()}
          label='new password'
          placeholder="***********"
          type="password"
          margin="normal"
          name="password"
          onChange={this.onInputChange('password')}
          // helperText={loginTranslations['password-help-text']}
          helperText='avoid password like 123456'
        />
        </div>
        <div>
        <TextField
          error={this.confirmPasswordFeedbackError()}
          // fullWidth
          // label={loginTranslations['confirm-password'].toUpperCase()}
          label='confirm password'
          placeholder="***********"
          type="password"
          margin="normal"
          name="confirm-password"
          onChange={this.onInputChange('confirmPassword')}
          // helperText={this.confirmPasswordFeedbackError() ? loginTranslations['confirm-password-help-text'] : null}
          helperText={this.confirmPasswordFeedbackError() ? 'password is not the same' : null}
        />
        </div>
        <div>
        <Button
          round
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.submit}
          disabled={!this.canSubmit() || this.state.loading}
          loading={this.state.loading}
          onClick={this.onSubmit}
        >
          {/* {loginTranslations['reset-password']} */}
          reset password

        </Button>
        </div>
        {this.renderError()}
      </div>
    )
  }
}

const mapStateToProps = state => (
  {
    code: state.password.code,
    isUpdated: state.password.isUpdated,
    updateErr: state.password.updateErr
  }
);
const mapDispatchToProps = {
  sendUpdateReq,
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm));
