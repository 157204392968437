const feeRateParser = (response, type, resource, params) => {

    if (type === 'GET_LIST' && resource === 'account/paymentfees') {
        // console.log("feeRateParser::params.data", response, type, resource, params);
        const { body, json, ...rest } = response;
        const bodyData = JSON.parse(body).map(x => {
            return {
                ...x,
                fee_rate_show: x.fee_rate === -1 ? 'Customize' : x.fee_rate
            }
        });
        const jsonData = json.map(x => {
            return {
                ...x,
                fee_rate_show: x.fee_rate === -1 ? 'Customize' : x.fee_rate
            }
        });
        const respCopy = {
            ...rest,
            json: jsonData,
            body: JSON.stringify(bodyData)
        }
        return respCopy;
    } else {
        return response;
    }


}

export default feeRateParser;