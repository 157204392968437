import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Logo from './logo';

const styles = theme => ({
  container: {
    position: 'absolute',
    top: '40%',
    left: 0,
    right: 0,
    textAlign: 'center',
    transform: 'translateY(-50%)',
  },
  spinner: {
    marginBottom: 50,
  },
});

class FullPageLoader extends Component {

  renderLogo() {
    const { hideLogo } = this.props;
    if (hideLogo) {
      return null;
    }

    return (
      <div>
        <Logo width={500} />
      </div>
    )
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.spinner}>
          <CircularProgress size={100}/>
        </div>
        { this.renderLogo() }
      </div>
    );
  }
}


export default withStyles(styles)(FullPageLoader);