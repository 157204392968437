import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import {
    Create,
    SimpleForm,
    TextInput,
    SelectInput,
    ReferenceInput,
    BooleanInput,
    required,
    email, NumberInput,
    minValue,
    maxValue
} from 'react-admin';
import { validatePrecision } from "../coins/utils/precisionValidate";
import { API_BASE } from '../dataProvider';

const itemStyle = {
    width: '75%',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '8px'
}

export const validateEmail = [required(), email()];
export const validateRequired = [required()];


export const inputStyle = { width: '20em' }

const UserCreate = ({ permissions, ...props }) => {
    let parentUserCommissionRateSetting = JSON.parse(localStorage.getItem('BITFA:DASHBOARD:AUTH:commissionRateSetting'));

    const isAdmin = permissions === 'system_admin';
    const isOperator = (permissions === 'operator' || permissions === 'operator_staff');
    const isPromoter = (permissions === 'promoter' || permissions === 'promoter_staff');
    const [selectedUserType, setSelectedUserType] = useState('');

    useEffect(() => {
        const url = `${API_BASE}/accountinfo`;
        const getAuthToken = () => {
            return localStorage.getItem('BITFA:DASHBOARD:AUTH:TOKEN')
        }
        if (isOperator) {
            const token = getAuthToken();
            fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
        }

    }, [])


    const handleUserTypeChange = (event) => {
        let s = '';
        for (let i in event) {
            if (i == "preventDefault") {
                break;
            }
            s += event[i];
        }

        setSelectedUserType(s)

    };

    const commonInputProps = {
        style: inputStyle,
    };

    const validatesUserCreation = (values) => {
        const errors = {};
        return errors
    }

    return (
        <Create title="User" {...props}>
            <SimpleForm redirect="list" validate={validatesUserCreation}>
                <TextInput source="name" label="User ID" validate={validateRequired}   {...commonInputProps} />
                <TextInput source="email" label="Email" validate={validateEmail} {...commonInputProps} />
                <ReferenceInput
                    source="userType"
                    label="User Type"
                    reference="user/types"
                    validate={validateRequired}
                    onChange={handleUserTypeChange}
                    id="userType"
                >
                    <SelectInput defaultValue="store_owner" />
                </ReferenceInput>

            </SimpleForm>
        </Create>
    );
};

export default UserCreate;

