import Big from 'big.js'
import { parseSettingRate } from "../../coins/currency.parser";

const accountCoinsParser = (response, type, resource, params) => {
    if (params.id && params.id.includes('@')) {
        params = {
            id: params.id.split('@')[0],
            symbol: params.id.split('@')[1],
        }
    }

    let responseJson = {};
    if (type === "GET_ONE" && resource === "accountcoins") {

        if (Object.prototype.hasOwnProperty.call(params, 'symbol')) {
            responseJson = response.json.find(x => x.symbol === params.symbol);
            const {
                id,
                storeCommissionRateSetting,
                systemCommissionRateSetting,
                operatorCommissionRateSetting,
                promoterCommissionRateSetting,
                ...rest
            } = responseJson;

            const storeCommissionRateSettingBuy = parseSettingRate(storeCommissionRateSetting.buy);
            const storeCommissionRateSettingSell = parseSettingRate(storeCommissionRateSetting.sell);
            const systemCommissionRateSettingBuy = parseSettingRate(systemCommissionRateSetting.buy);
            const systemCommissionRateSettingSell = parseSettingRate(systemCommissionRateSetting.sell);
            const operatorCommissionRateSettingBuy = parseSettingRate(operatorCommissionRateSetting.buy);
            const operatorCommissionRateSettingSell = parseSettingRate(operatorCommissionRateSetting.sell);
            const promoterCommissionRateSettingBuy = parseSettingRate(promoterCommissionRateSetting.buy);
            const promoterCommissionRateSettingSell = parseSettingRate(promoterCommissionRateSetting.sell);
            const currencyMap = {
                respData: responseJson,
                storeCommissionRateSettingBuy,
                storeCommissionRateSettingSell,
                systemCommissionRateSettingBuy,
                systemCommissionRateSettingSell,
                operatorCommissionRateSettingBuy,
                operatorCommissionRateSettingSell,
                promoterCommissionRateSettingBuy,
                promoterCommissionRateSettingSell,
            };
            window._currentAccountCoinCurrencyMap = currencyMap;

            responseJson.storeCommissionRateSetting.buy.forEach(x => {
                responseJson[`storeCommissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
            });
            responseJson.storeCommissionRateSetting.sell.forEach(x => {
                responseJson[`storeCommissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
            });
            responseJson.systemCommissionRateSetting.buy.forEach(x => {
                responseJson[`systemCommissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
            });
            responseJson.systemCommissionRateSetting.sell.forEach(x => {
                responseJson[`systemCommissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
            });
            responseJson.operatorCommissionRateSetting.buy.forEach(x => {
                responseJson[`operatorCommissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
            });
            responseJson.operatorCommissionRateSetting.sell.forEach(x => {
                responseJson[`operatorCommissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
            });
            responseJson.promoterCommissionRateSetting.buy.forEach(x => {
                responseJson[`promoterCommissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
            });
            responseJson.promoterCommissionRateSetting.sell.forEach(x => {
                responseJson[`promoterCommissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
            });

            responseJson.id = `${params.id}@${params.symbol}`;
            responseJson.coinId = id;

        } else {
            responseJson = {
                id: params.id,
                coins: response.json
            }
        }
        response.json = responseJson;

    }
    if (type === "GET_MANY_REFERENCE" && resource === "accountcoins") {
        // console.log(response, type, resource, params);
    }
    return response;
}

export default accountCoinsParser