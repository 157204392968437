var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
var __rest = this && this.__rest || function (s, e) {
  var t = {};
  for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0) t[p[i]] = s[p[i]];
  return t;
};
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import ViewTitle from './ViewTitle';
var styles = createStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});
/**
 * @deprecated
 */
export var Header = function Header(_a) {
  var classes = _a.classes,
    className = _a.className,
    title = _a.title,
    actions = _a.actions,
    actionProps = _a.actionProps,
    rest = __rest(_a, ["classes", "className", "title", "actions", "actionProps"]);
  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-console
    console.warn('<Header> is deprecated, please use <Title> directly instead');
  }
  return React.createElement("div", __assign({
    className: classnames(classes.root, className)
  }, rest), React.createElement(ViewTitle, {
    title: title
  }), actions && React.cloneElement(actions, actionProps));
};
Header.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.any,
  actions: PropTypes.element,
  actionProps: PropTypes.object
};
export default withStyles(styles)(Header);