import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
} from 'react-admin';

export const TransactionEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="note" />
        </SimpleForm>
    </Edit>
);


export default TransactionEdit;