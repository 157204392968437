import React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    Toolbar,
    SaveButton,
} from 'react-admin';

const OperatorEditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const OperatorEdit = props => (
    <Edit title="Operator" {...props}>
        <SimpleForm toolbar={<OperatorEditToolbar />} redirect="show">
            <TextInput source="name" label="Name" />
            <TextInput source="email" label="Email" />
            <TextInput source="phone" label="Phone" />
        </SimpleForm>
    </Edit>
);

export default OperatorEdit;
