import React, { useState, useEffect } from "react";
import Jquery from 'jquery';
import {
	Create,
	SimpleForm,
	TextInput,
	NumberInput,
	required,
	BooleanInput,
	ReferenceInput,
	SelectInput,
	ImageInput,
	ImageField,
	minValue
} from "react-admin";

const PaymenmtFeeCreate = ({ permissions, ...props }) => {
	const [customize, setCustomize] = useState(false);

	useEffect(()=>{
		const val = Jquery("#feeRateValue").val();
		console.log("val", val);
		if(val === '-1') {
			setCustomize(true);
		}
	}, [])
	return (
		<Create title="Payment Fee" {...props}>
			<SimpleForm redirect="list">
				<ReferenceInput
					reference="system/paymentmethods"
					label="Payment Method"
					source="payment_method_id"
				>
					<SelectInput optionText="label" />
				</ReferenceInput>
				{!customize && <NumberInput source="fee_rate" validate={[required(), minValue(0)]} id="feeRateValue" />}
				<NumberInput source="sort" step={1} validate={[required()]} />
				<BooleanInput
					label="Customize: use or not"
					source="_customize"
					defaultValue={customize}
					onChange={(e) => {
						console.log(e.currentTarget.checked);
						setCustomize(e.currentTarget.checked);
					}}
				/>
				<BooleanInput
					label="Status: use or not"
					source="status"
					defaultValue={false}
				/>
			</SimpleForm>
		</Create>
	);
};

export default PaymenmtFeeCreate;
