const customizeProvider = requestHandler => (type, resource, params) => {

    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'account/paymentfees') {
        console.log("customizeProvider::params.data", params.data);
        if(params.data._customize === true) {
            params.data.fee_rate = -1;
            delete params.data._customize;
        } else {
            delete params.data._customize;
        }
    }

    return requestHandler(type, resource, params);
}

export default customizeProvider;