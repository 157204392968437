"use strict";

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function sent() {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (_) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
Object.defineProperty(exports, "__esModule", {
  value: true
});
var effects_1 = require("redux-saga/effects");
var dataActions_1 = require("../actions/dataActions");
var notificationActions_1 = require("../actions/notificationActions");
/**
 * Side effects for fetch responses
 *
 * Mostly corner case handling
 */
function handleResponse(action) {
  var type, requestPayload, payload, _a;
  return __generator(this, function (_b) {
    switch (_b.label) {
      case 0:
        type = action.type, requestPayload = action.requestPayload, payload = action.payload;
        _a = type;
        switch (_a) {
          case dataActions_1.CRUD_GET_ONE_SUCCESS:
            return [3 /*break*/, 1];
        }
        return [3 /*break*/, 4];
      case 1:
        if (!(!('id' in payload.data) || payload.data.id != requestPayload.id) // eslint-disable-line eqeqeq
        ) return [3 /*break*/, 3]; // eslint-disable-line eqeqeq
        return [4 /*yield*/, effects_1.put(notificationActions_1.showNotification('ra.notification.bad_item', 'warning'))];
      case 2:
        return [2 /*return*/, _b.sent()];
      case 3:
        return [3 /*break*/, 6];
      case 4:
        return [4 /*yield*/, effects_1.all([])];
      case 5:
        return [2 /*return*/, _b.sent()];
      case 6:
        return [2 /*return*/];
    }
  });
}

function default_1() {
  return __generator(this, function (_a) {
    switch (_a.label) {
      case 0:
        return [4 /*yield*/, effects_1.takeEvery(function (action) {
          return action.meta && action.meta.fetchResponse;
        }, handleResponse)];
      case 1:
        _a.sent();
        return [2 /*return*/];
    }
  });
}

exports.default = default_1;