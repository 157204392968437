import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import DollarIcon from '@material-ui/icons/Store';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';

import CardIcon from './CardIcon';

const styles = {
    main: {
        flex: '1',
        marginRight: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};



const MonthlyRevenue = ({ txSummary, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={DollarIcon} bgColor="#f44336" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                {translate('pos.dashboard.total_sales')}
            </Typography>
            {txSummary.map(summary => (
                <Typography variant="headline" component="h2" key={summary.id}>
                {summary.currency} : {summary.totalAmt.toFixed(2)}
                </Typography>
            ))}
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(MonthlyRevenue);
