import React from 'react';
import { translate } from "react-admin";
import { AdminSideMenu, OperatorSideMenu, PromoterSideMenu,StoreSideMenu } from './menu-permissions'


const MenuWithPermission = ({ handleToggle, menuCatalog, menuBusiness, menuDocs, open, onMenuClick, translate }) => {
    return (
        <>
            <AdminSideMenu
                handleToggle={handleToggle}
                menuBusiness={menuBusiness}
                menuDocs={menuDocs}
                menuCatalog={menuCatalog}
                open={open}
                translate={translate}
                onMenuClick={onMenuClick}
            ></AdminSideMenu>
            <OperatorSideMenu
                handleToggle={handleToggle}
                menuBusiness={menuBusiness}
                menuDocs={menuDocs}
                menuCatalog={menuCatalog}
                open={open}
                translate={translate}
                onMenuClick={onMenuClick}
            ></OperatorSideMenu>
            <PromoterSideMenu
                handleToggle={handleToggle}
                menuBusiness={menuBusiness}
                menuDocs={menuDocs}
                menuCatalog={menuCatalog}
                open={open}
                translate={translate}
                onMenuClick={onMenuClick}
            ></PromoterSideMenu>
            <StoreSideMenu
                handleToggle={handleToggle}
                menuBusiness={menuBusiness}
                menuDocs={menuDocs}
                menuCatalog={menuCatalog}
                open={open}
                translate={translate}
                onMenuClick={onMenuClick}
            ></StoreSideMenu>
        </>
    )
}


export default translate(MenuWithPermission);