import React, { useEffect } from 'react';
import {
    Create,
    SimpleForm,
    // TextInput,
    TextField,
    ReferenceInput,
    NumberInput,
    SelectInput,
    required,
    choices,
    number,
    minValue,
    maxValue,

} from 'react-admin';
import { validatePrecision } from './utils/precisionValidate';

// const validateSymbol = [required(), choices(['BTC', 'ETH', 'LTC', 'TRCUSDT', 'ERCUSDT'], 'Must be a coin symbol')];
// const validateSymbol = [required(), choices(['BTC'], 'Must be a coin symbol')];
// const validateSpread = [required(), number(), minValue(0.01), maxValue(0.01)];
const validateTxMax = [required(), number()];
const validateTxMin = [required(), number()];
const inputStyle = { width: '20em', display: 'block' }
const symbol = [
    { id: 'BTC', name: 'Bitcoin' },
    { id: 'ETH', name: 'Ethereum' },
    // { id: 'LTC', name: 'Litecoin' },
    { id: 'TRCUSDT', name: 'TRC20-Tether' },
    { id: 'ERCUSDT', name: 'ERC20-Tether' },
]

function parseReferenceInputChange(data) {
    const res = [];
    Object.keys(data).forEach(key => {
        if (!isNaN(Number(key))) {
            res.push(data[key]);
        }
    });
    return res.join('')
}

const CoinCreate = props => {
    const [selectSymbol, setSymbol] = React.useState('');
    const [selectCurrency, setCurrency] = React.useState('');

    const [currencyRate, setCurrencyRate] = React.useState(null);
    const [defaultFormValue, setDefaultFormValue] = React.useState({});

    useEffect(() => {
        const dataObj = {
            txMin: 500,
            txMax: 20000
        };
        setDefaultFormValue(dataObj);
    }, []);

    useEffect(() => {
        console.log('window._currencyMap', window._currencyMap);
        if (window._currencyMap) {
            const _rateObj = window._currencyMap[selectCurrency];
            if (_rateObj) {
                setCurrencyRate(_rateObj);
                const dataObj = {
                    txMin: 500,
                    txMax: 20000
                };
                if (selectSymbol === 'ERCUSDT' || selectSymbol === 'TRCUSDT') {

                    _rateObj.fixedCommissionRateSettingBuy.forEach(x => {
                        dataObj[`commissionRateSettingBuy_${x.max}`] = x.rate
                    });
                    _rateObj.fixedCommissionRateSettingSell.forEach(x => {
                        dataObj[`commissionRateSettingSell_${x.max}`] = x.rate
                    });

                } else {

                    _rateObj.commissionRateSettingBuy.forEach(x => {
                        dataObj[`commissionRateSettingBuy_${x.max}`] = x.rate
                    });
                    _rateObj.commissionRateSettingSell.forEach(x => {
                        dataObj[`commissionRateSettingSell_${x.max}`] = x.rate
                    });

                }
                setDefaultFormValue(dataObj);
            }
        }

    }, [selectCurrency, selectSymbol]);

    return (
        <Create title="Coin" {...props}>
            <SimpleForm redirect="list" defaultValue={defaultFormValue}>
                <ReferenceInput source="symbol" label="Name" reference="system/coins" onChange={val => {
                    const symbol = parseReferenceInputChange(val);
                    setSymbol(symbol);
                }} >
                    <SelectInput optionText="symbol" optionValue="symbol" />
                </ReferenceInput>
                <ReferenceInput source="currency" label="Currency" reference="account/currencies" onChange={val => {
                    const currency = parseReferenceInputChange(val);
                    setCurrency(currency);
                }}>
                    <SelectInput optionText="currency" optionValue="currency" />
                </ReferenceInput>
                <NumberInput
                    style={inputStyle}
                    source="txMax"
                    label="Tx Max. "
                    validate={validateTxMax}
                />
                <NumberInput
                    style={inputStyle}
                    source="txMin"
                    label="Tx Min. "
                    validate={validateTxMin}
                />
                {/* <p>selectSymbol: {selectSymbol}</p>
                <p>selectCurrency: {selectCurrency}</p> */}
                {
                    (selectSymbol === 'ERCUSDT' || selectSymbol === 'TRCUSDT') && currencyRate && (
                        <>
                            {/* TODO 这里需要获取当前选择的curreny里面的相关数据，考虑在获取currencies的时候把配置保存到全局变量中 */}
                            {/* 使用 固定费率并不可编辑 */}
                            <p>Buy Commission Rate Setting</p>
                            {currencyRate.fixedCommissionRateSettingBuy.map((x, i) => {
                                return (
                                    <NumberInput
                                        key={x.key}
                                        style={inputStyle}
                                        id={`commissionRateSettingBuy_${x.max}`}
                                        source={`commissionRateSettingBuy_${x.max}`}
                                        label={x.key + ' (%)'}
                                        readOnly
                                        disabled={true}
                                    />
                                )
                            })}
                            <p>Sell Commission Rate Setting</p>
                            {currencyRate.fixedCommissionRateSettingSell.map((x, i) => {
                                return (
                                    <NumberInput
                                        key={x.key}
                                        style={inputStyle}
                                        id={`commissionRateSettingSell_${x.max}`}
                                        source={`commissionRateSettingSell_${x.max}`}
                                        label={x.key + ' (%)'}
                                        readOnly
                                        disabled={true}
                                    />
                                )
                            })}
                        </>
                    )
                }
                {
                    (selectSymbol !== 'ERCUSDT' && selectSymbol !== 'TRCUSDT') && currencyRate && (
                        <>
                            {/* TODO 这里需要获取当前选择的curreny里面的相关数据，考虑在获取currencies的时候把配置保存到全局变量中 */}
                            {/* 活动费率 可以编辑 */}
                            <p>Buy Commission Rate Setting</p>
                            {currencyRate.commissionRateSettingBuy.map((x, i) => {
                                return (
                                    <NumberInput
                                        key={x.key}
                                        style={inputStyle}
                                        id={`commissionRateSettingBuy_${x.max}`}
                                        source={`commissionRateSettingBuy_${x.max}`}
                                        label={x.key + ' (%)'}
                                        validate={validatePrecision}
                                    />
                                )
                            })}
                            <p>Sell Commission Rate Setting</p>
                            {currencyRate.commissionRateSettingSell.map((x, i) => {
                                return (
                                    <NumberInput
                                        key={x.key}
                                        style={inputStyle}
                                        id={`commissionRateSettingSell_${x.max}`}
                                        source={`commissionRateSettingSell_${x.max}`}
                                        label={x.key + ' (%)'}
                                        validate={validatePrecision}
                                    />
                                )
                            })}
                        </>
                    )
                }
            </SimpleForm>
        </Create>
    )
};

export default CoinCreate;
