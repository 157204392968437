import { all,put, call, takeLatest } from 'redux-saga/effects';
import { FETCH_ACCOUNT } from './accountActions';
import {
  setCredit,
 } from './accountActions';

import { getAccounts } from './accountServices'

export function* fetchAccount(action) {
  console.log("saga fetchAccount")
  try {
    const res = yield call(() => getAccounts())
    console.log('result: ', res)
    const accounts = res.data
    if (accounts.length > 0) {
      if (accounts[0].credit) {
        yield put(setCredit(accounts[0].credit))
      }
    }
  } catch (e) {
    // yield put(setResetReqErr('Email is invalid or server error.'))
    console.log(e)
    console.log('Fetch account error')
  }
}

export default function* accountSaga() {
  yield all([
    takeLatest(FETCH_ACCOUNT, fetchAccount),
  ]);
}
