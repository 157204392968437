import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  image: {
    maxWidth: '90%',
  },
});

class Logo extends Component {
  render() {
    const { classes, logo } = this.props;
    const width = this.props.width || 100;
    if (!logo) {
      return null;
    }
    return (
      <img src={logo} width={width} alt="" className={classes.image} />
    );
  }
}

const mapStateToProps = state => ({
  logo: state && state.dashboard && state.dashboard.logo ? state.dashboard.logo : '',
});

const mapDispatchToProps = {};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Logo));