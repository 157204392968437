import { getUserURL } from './api';

const uploadImageByApi = (file) => {
	console.log(file);
	const formdata = new FormData();
	formdata.append("file", file.rawFile, file.title);
	formdata.append("dir", "icon");
	const API_URL = getUserURL();
	const token = localStorage.getItem('BITFA:DASHBOARD:AUTH:TOKEN');
	return fetch(API_URL + "/v1/files/file", {
		method: "POST",
		headers: {
			// "Content-Type": "multipart/form-data",
			"Authorization": "Bearer " + token,
			"Accept": "application/json"
		},
		body: formdata,
	}).then((res) => {
		return res.json();
	});
};

const addUploadImageInFetch = (requestHandler) => (type, resource, params) => {
	if (type === 'GET_ONE' || type === 'GET_LIST' || type === 'GET_MANY' || type === 'GET_MANY_REFERENCE') {

	}
	if (
		(type === "CREATE" || type === "UPDATE") &&
		resource === "system/paymentmethods"
	) {
		const url = getUserURL();
		if (params.data.icon && params.data.icon.startsWith(url)) {
			params.data.icon = params.data.icon.replace(url + '/', '');
		}
		if (params.data.images) {
			console.log("params.data.pictures", params.data.images);
			// only freshly dropped pictures are instance of File
			const newPictures = params.data.images;
			console.log('newPictures', newPictures);
			if (!Object.prototype.hasOwnProperty.call(newPictures, 'rawFile')) {
				return requestHandler(type, resource, params)
			}
			return uploadImageByApi(newPictures)
				.then((fileRes) => {
					return fileRes.filePath || ""
				})
				.then((filePath) =>
					requestHandler(type, resource, {
						...params,
						data: {
							...params.data,
							icon: filePath,
						},
					})
				);
		}
		
	}
	// for other request types and resources, fall back to the default request handler
	return requestHandler(type, resource, params);
};

export default addUploadImageInFetch;
