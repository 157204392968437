import React from 'react';
import {
    Create,
    SimpleForm,
    ReferenceInput,
    NumberInput,
    SelectInput,
    required,
} from 'react-admin';

const ReportCreate = props => (
    <Create title="Report" {...props}>
        <SimpleForm redirect="list">
            <ReferenceInput label="Account" source="account_id" reference="accounts" validate={required()}>
                <SelectInput optionText="firstName" />
            </ReferenceInput>
            <NumberInput source="year" label="Year" />
            <NumberInput source="month" label="Month" />
        </SimpleForm>
    </Create>
);

export default ReportCreate;