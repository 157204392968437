import React from "react";
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField
} from "react-admin";
import CustomDownloadLink from "./CustomDownloadLink";

const ReportList = props => (
  <List {...props} pagination={null} bulkActionButtons={false} exporter={false}>
    <Datagrid rowClick="edit">
      {/* <TextField source="id" label="ID"/> */}
      {/* <CustomDateTimeField source="createdAt" showTime options={{ timeZone:"America/Los_Angeles" }}/> */}
      <ReferenceField
        label="Account"
        source="account_id"
        reference="accounts"
        linkType="show"
      >
        <TextField source="firstName" />
      </ReferenceField>
      <ReferenceField
        label="Account"
        source="account_id"
        reference="accounts"
        linkType="show"
      >
        <TextField source="accountNumber" />
      </ReferenceField>
      <NumberField source="year" label="Year" />
      <NumberField source="month" label="Month" />
      <CustomDownloadLink source="filePath" />
      {/* <TextField source="status" label="Status"/> */}
      {/* <EditButton /> */}
    </Datagrid>
  </List>
);

export default ReportList;
