import React, { useEffect, useState } from "react";
import $ from 'jquery';
import {
  Edit,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  NumberInput,
  Toolbar,
  SaveButton,
  required,
  number,
  minValue,
  maxValue
} from "react-admin";
import { parseSettingRate } from "./currency.parser";
import { validatePrecision } from "./utils/precisionValidate";

const CoinEditToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const validateBuySpread = [required(), number(), minValue(0.01), maxValue(0.01)];
const validateSellSpread = [
  required(),
  number(),
  minValue(0.01),
  maxValue(0.01)
];
const validateTxMax = [required(), number()];
const validateTxMin = [required(), number()];

const inputStyle = { width: "20em" };
let interval = 0;
const CoinEdit = ({ permissions, ...props }) => {

  const [selectSymbol, setSymbol] = React.useState('');
  const [selectCurrency, setCurrency] = React.useState('');
  const [currencyRate, setCurrencyRate] = React.useState(null);

  const customizeCommissionRateSetting = localStorage.getItem('BITFA:ACCOUNT:CustomizeCommissionRateSetting');

  useEffect(() => {
    interval = setInterval(() => {
      if (window._currentCoinCurrencyMap) {
        onCurrencyChange();
      }
    }, 300);
    return () => {
      clearInterval(interval);
    }
  }, []);

  const onCurrencyChange = () => {
    // console.log('window._currentCoinCurrencyMap', window._currentCoinCurrencyMap);
    if (window._currentCoinCurrencyMap) {
      const _rateObj = window._currentCoinCurrencyMap;
      if (_rateObj) {
        setCurrencyRate(_rateObj);
        setSymbol(_rateObj.respData.symbol);
        setCurrency(_rateObj.respData.currency);
      }
    }
  }

  return (
    <Edit title="Coin" {...props}>
      <SimpleForm toolbar={<CoinEditToolbar />} redirect="show">
        {/* <ReferenceField
          label="Location"
          source="location_id"
          reference="locations"
          linkType="show"
        >
          <TextField source="nickName" />
        </ReferenceField> */}
        <TextField source="symbol" label="Symbol" id="symbol" />
        <TextField source="currency" label="Currency" id="currency" />

        <NumberInput
          style={inputStyle}
          source="txMax"
          label="Tx Max. "
          validate={validateTxMax}
        />
        <NumberInput
          style={inputStyle}
          source="txMin"
          label="Tx Min. "
          validate={validateTxMin}
        />
        {currencyRate && customizeCommissionRateSetting === 'true' && (
            <>
              <p>Store Buy Commission Rate Setting</p>
              {currencyRate.storeCommissionRateSettingBuy.map((x, i) => {
                return (
                    <NumberInput
                        key={x.key}
                        style={inputStyle}
                        id={`storeCommissionRateSettingBuy_${x.max}`}
                        source={`storeCommissionRateSettingBuy_${x.max}`}
                        label={x.key + " (%)"}
                        disabled={
                          selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
                              ? true
                              : false
                        }
                        validate={validatePrecision}
                    />
                );
              })}
              <p>Store Sell Commission Rate Setting</p>
              {currencyRate.storeCommissionRateSettingSell.map((x, i) => {
                return (
                    <NumberInput
                        key={x.key}
                        style={inputStyle}
                        id={`storeCommissionRateSettingSell_${x.max}`}
                        source={`storeCommissionRateSettingSell_${x.max}`}
                        label={x.key + " (%)"}
                        readOnly
                        disabled={
                          selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
                              ? true
                              : false
                        }
                        validate={validatePrecision}
                    />
                );
              })}
            </>
        )}
      </SimpleForm>
    </Edit>
  )
};

export default CoinEdit;
