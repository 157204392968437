import AccountIcon from '@material-ui/icons/AccountCircle';

import AccountCoinEdit from './AccountCoinEdit';
import AccountCoinShow from './AccountCoinShow';

export default {
    show: AccountCoinShow,
    icon: AccountIcon,
    edit: AccountCoinEdit,
};
