import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';
import {
    BITFA_BASE_URL_PROD_V12,
    BITFA_BASE_URL_DEV,
    BITFA_BASE_URL_LOCAL,
} from './constants';
function createAuthProvider(onDialogChange) {
    return (type, params) => {

        var API_URL;
        if (process.env.REACT_APP_ENV === 'production.v12') {   // in production env
            API_URL = BITFA_BASE_URL_PROD_V12 + '/v1';
        } else if (process.env.REACT_APP_ENV === 'development') { // in local dev env
            API_URL = BITFA_BASE_URL_DEV + '/v1';
        } else {
            API_URL = BITFA_BASE_URL_LOCAL + '/v1' // in stging env
        }

        if (type === AUTH_LOGIN) {
            // console.log('auth_login triggered')
            const {email, password} = params;
            const request = new Request(API_URL + '/auth', {
                method: 'POST',
                body: JSON.stringify({email, password}),
                headers: new Headers({'Content-Type': 'application/json'}),
            })
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return response.json();
                })
                .then(({token, userType, commissionRateSetting,registerStep}) => {
                    localStorage.setItem('BITFA:DASHBOARD:AUTH:TOKEN', token);
                    localStorage.setItem('BITFA:DASHBOARD:AUTH:USERTYPE', userType);
                    localStorage.setItem('BITFA:DASHBOARD:AUTH:commissionRateSetting', commissionRateSetting);
                    localStorage.setItem('BITFA:DASHBOARD:REGISTER:STEP', registerStep);
                    if(onDialogChange){
                        onDialogChange();
                    }
                });
        }
        if (type === AUTH_LOGOUT) {
            localStorage.removeItem('BITFA:DASHBOARD:AUTH:TOKEN');
            localStorage.removeItem('BITFA:DASHBOARD:AUTH:USERTYPE');
            localStorage.removeItem('BITFA:DASHBOARD:ACCOUNT:CREDIT_LIMIT');
            localStorage.removeItem('BITFA:DASHBOARD:AUTH:commissionRateSetting');
            localStorage.removeItem('BITFA:DASHBOARD:REGISTER:STEP');
            localStorage.removeItem('BITFA:ACCOUNT:CustomizeCommissionRateSetting');
            if(onDialogChange){
                onDialogChange();
            }
            return Promise.resolve();
        }
        if (type === AUTH_ERROR) {
            const status = params.status;
            if (status === 401 || status === 403) {
                localStorage.removeItem('BITFA:DASHBOARD:AUTH:TOKEN');
                localStorage.removeItem('BITFA:DASHBOARD:AUTH:USERTYPE');
                localStorage.removeItem('BITFA:DASHBOARD:ACCOUNT:CREDIT_LIMIT');
                localStorage.removeItem('BITFA:DASHBOARD:AUTH:commissionRateSetting');
                localStorage.removeItem('BITFA:DASHBOARD:REGISTER:STEP');
                localStorage.removeItem('BITFA:ACCOUNT:CustomizeCommissionRateSetting');
                if(onDialogChange){
                    onDialogChange();
                }
                return Promise.reject();
            }
            return Promise.resolve();
        }

        if (type === AUTH_GET_PERMISSIONS) {
            const role = localStorage.getItem('BITFA:DASHBOARD:AUTH:USERTYPE');
            return role ? Promise.resolve(role) : Promise.reject();
        }
        return Promise.resolve();
    }
}
export default createAuthProvider;