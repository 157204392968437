"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AUTH_LOGIN = 'AUTH_LOGIN';
exports.AUTH_CHECK = 'AUTH_CHECK';
exports.AUTH_ERROR = 'AUTH_ERROR';
exports.AUTH_LOGOUT = 'AUTH_LOGOUT';
exports.AUTH_GET_PERMISSIONS = 'AUTH_GET_PERMISSIONS';
exports.AUTH_SIGNUP = 'AUTH_SIGNUP';