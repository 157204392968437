import PaymentFeeList from './PaymentFeeList'
import PaymentIcon from '@material-ui/icons/PermIdentity';
import PaymentFeeCreate from './PaymentFeeCreate';
import PaymentFeeEdit from './PaymentFeeEdit';

export default {
    list: PaymentFeeList,
    create: PaymentFeeCreate,
    edit: PaymentFeeEdit,
    icon: PaymentIcon,
};
