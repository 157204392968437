import React, { useState } from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	required,
	BooleanInput,
	ImageInput,
	ImageField,
} from "react-admin";

const PaymentMethodCreate = ({ permissions, ...props }) => {

	return (
		<Create title="Payment Method" {...props}>
			<SimpleForm redirect="list">
				<TextInput source="code" validate={[required()]} />
				<TextInput source="label" validate={[required()]} />
				<ImageInput source="images" label="Icon" accept="image/svg+xml">
					<ImageField source="src" title="title" />
				</ImageInput>
				<BooleanInput
					label="Status: use or not"
					source="status"
					defaultValue={false}
				/>
			</SimpleForm>
		</Create>
	);
};

export default PaymentMethodCreate;
