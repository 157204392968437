import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import compose from "recompose/compose";

import { withRouter } from "react-router-dom";
import {
    translate,
    DashboardMenuItem,
    Responsive, MenuItemLink,
} from "react-admin";

import MenuWithPermission from "./MenuWithPermission";
import users from "../users";
import transactions from "../transactions";

class Menu extends Component {
    state = {
        menuDocs: false,
        menuBusiness: false,
        menuCatalog: false
    };

    static propTypes = {
        onMenuClick: PropTypes.func,
        logout: PropTypes.object
    };

    handleToggle = menu => {
        this.setState(state => ({ [menu]: !state[menu] }));
    };

    render() {
        const { onMenuClick, open, logout, translate } = this.props;
        return (
            <div>
                {" "}
                <DashboardMenuItem onClick={onMenuClick} />
                <MenuItemLink
                    to={`/users`}
                    primaryText={translate(`resources.users.name`, {
                        smart_count: 2
                    })}
                    leftIcon={<users.icon />}
                    onClick={onMenuClick}
                />
                <MenuWithPermission
                    handleToggle={this.handleToggle}
                    menuCatalog={this.state.menuCatalog}
                    menuBusiness={this.state.menuBusiness}
                    menuDocs={this.state.menuDocs}
                    open={open}
                    onMenuClick={onMenuClick}
                    translate={translate}
                />
                <MenuItemLink
                    to={`/transactions`}
                    primaryText={translate(`resources.transactions.name`, {
                        smart_count: 2
                    })}
                    leftIcon={<transactions.icon />}
                    onClick={onMenuClick}
                />

                <Responsive
                    small={logout}
                    medium={null} // Pass null to render nothing on larger devices
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    open: state.admin.ui.sidebarOpen,
    theme: state.theme,
    locale: state.i18n.locale
});

const enhance = compose(
    withRouter,
    connect(
        mapStateToProps,
        {}
    ),
    translate
);

export default enhance(Menu);
