import AccountIcon from '@material-ui/icons/AccountCircle';

import AccountList from './AccountList';
import AccountCreate from './AccountCreate';
import AccountShow from './AccountShow';
import AccountEdit from './AccountEdit';

export default {
    list: AccountList,
    show: AccountShow,
    create: AccountCreate,
    icon: AccountIcon,
    edit: AccountEdit,
};
