import React from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout,
    ReferenceField,
    ChipField,
} from 'react-admin';

const UserShow = ({ ...props }) => (
    <Show title="User Info" {...props}>
        <SimpleShowLayout>
            <TextField source="name" label="Name" />
            <TextField source="email" label="Email" />
            <TextField source="phoneNumber" label="Phone Number" />
            <TextField source="userType" label="User Type" />
        </SimpleShowLayout>
    </Show>
);

export default UserShow;
