import Big from 'big.js';

const usersProvider = requestHandler => (type, resource, params) => {

    if ((type === 'CREATE' || type === 'UPDATE') && resource === 'users') {
        // console.log("usersProvider::params.data", params.data);
        // const keys = [
        //     "systemBuyCommissionRatio_show", "systemSellCommissionRatio_show",
        //     "storeBuyCommissionRatio_show", "storeSellCommissionRatio_show",
        //     "promoterBuyCommissionRatio_show", "promoterSellCommissionRatio_show",
        //     "operatorBuyCommissionRatio_show", "operatorSellCommissionRatio_show"
        // ];
        // keys.forEach(key => {
        //     const realKey = key.replace("_show", "");
        //     if (params.data[key]) {
        //         params.data[realKey] = parseFloat(new Big(params.data[key] / 100).toFixed(4));
        //         delete params.data[key];
        //     }
        // });
        // if (params.data["storeBuyCommissionRatio"]) {
        //     params.data["systemBuyCommissionRatio"] = parseFloat(new Big(1 - params.data["storeBuyCommissionRatio"]).toFixed(4));
        // }
        // if (params.data["storeSellCommissionRatio"]) {
        //     params.data["systemSellCommissionRatio"] = parseFloat(new Big(1 - params.data["storeSellCommissionRatio"]).toFixed(4));
        // }

    }

    return requestHandler(type, resource, params);
}

export default usersProvider;