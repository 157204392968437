import LocationIcon from '@material-ui/icons/Store';
import LocationList from './LocationList';
import LocationEdit from './LocationEdit';
import LocationCreate from './LocationCreate';
import LocationShow from './LocationShow';

export default {
    list: LocationList,
    show: LocationShow,
    edit: LocationEdit,
    create: LocationCreate,
    icon: LocationIcon,
};
