import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
// import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PasswordResetForm from '../password/PasswordResetForm'
import ChangePasswordForm from '../password/ChangePasswordForm'


import { Notification, translate, userLogin, TextInput } from 'react-admin';

import { lightTheme } from './themes';

import {
    sendCodeVal, setCode,
  } from '../password/passwordActions'

import { ErrorWrapper } from '../common/ErrorWrapper'

const styles = theme => ({
    main: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        background: 'url(https://source.unsplash.com/random/1600x900)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    card: {
        minWidth: 300,
        marginTop: '6em',
    },
    avatar: {
        margin: '1em',
        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: theme.palette.secondary.main,
    },
    hint: {
        marginTop: '1em',
        display: 'flex',
        justifyContent: 'center',
        color: theme.palette.grey[500],
    },
    form: {
        padding: '0 1em 1em 1em',
    },
    input: {
        marginTop: '1em',
    },
    actions: {
        padding: '0 1em 1em 1em',
    },
});

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

const activatedButtonVriant = 'raised'
// const inactivatedButtonVriant = 'flat'

const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined

class Login extends Component {
    getParameterByName = (name, url) => {
        if (!url) url = window.location.href;
        console.log(url)
        name = name.replace(/[[]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        console.log(results)
        if (!results) return null;
        console.log(results[2])
        if (!results[2]) return '';
        console.log(decodeURIComponent(results[2].replace(/\+/g, " ")))
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    state = {
        forms: ['signin', 'signup', 'password-reset'],
        activatedForm: 'signin',
        passwordResetCode: this.getParameterByName('passwordResetCode'),

    }

    login = auth => {
        this.props.userLogin(
            auth,
            this.props.location.state
                ? this.props.location.state.nextPathname
                : '/'
        );
    }

    handleSwitch = (event) => {
        event.preventDefault()
        switch(this.state.activatedForm) {
            case 'signin':
                this.setState({
                    activatedForm: 'signup'
                })
                break;
            default:
                this.setState({
                    activatedForm: 'signin'
                })
                break;
        }
    }



    renderSigninForm = () => {
        const { handleSubmit, translate, classes, isLoading } = this.props
        return (
            <form onSubmit={handleSubmit(this.login)}>
                <div className={classes.form}>
                    <div className={classes.input}>
                        <Field
                            autoFocus
                            name="email"
                            validate={email}
                            component={renderInput}
                            // label={translate('ra.validation.email')}
                            label="Email"

                            disabled={isLoading}
                        />
                    </div>
                    <div className={classes.input}>
                        <PasswordInput label={translate('ra.auth.password')} name="password" source="password" />
                        {/* <Field
                            name="password"
                            component={renderInput}
                            label={translate('ra.auth.password')}
                            type="password"
                            disabled={isLoading}
                        /> */}
                    </div>
                </div>
                <CardActions className={classes.actions}>
                    <Button
                        name = "signin"
                        variant = {activatedButtonVriant}
                        type="submit"
                        color="primary"
                        disabled={isLoading}
                        className={classes.button}
                        fullWidth
                    >
                        {isLoading && (
                            <CircularProgress size={25} thickness={2} />
                        )}
                        {translate('ra.auth.sign_in')}
                    </Button>
                </CardActions>
                <div>
                    {/* <Typography variant="subheading" className={classes.heading}>
                            Forget passwords?
                    </Typography> */}
                    <Button
                        size="small"
                        className={classes.forgotPassword}
                        onClick={() =>  this.setState({
                            activatedForm: 'password-reset'
                        })}
                    >
                        forgot password?
                    </Button>
                </div>
            </form>
        )
    }

    renderPasswordResetForm = () => {
        // const { handleSubmit, translate, classes, isLoading } = this.props
        return <PasswordResetForm resetView={this.resetView} />;
    }

    formSwitcher = (formName) => {

        const {sendCodeVal, setCode, isVal, valErr} = this.props
        if (this.state.passwordResetCode) {
            console.log(this.state.passwordResetCode)
            console.log("set Code")
            setCode(this.state.passwordResetCode)
            sendCodeVal(this.state.passwordResetCode)
        }

        if(isVal) {
            return <ChangePasswordForm code={this.state.passwordResetCode} resetView={this.resetView} />
        }

        if(valErr) {
            return (
                <ErrorWrapper>{valErr}</ErrorWrapper>
            )
        }

        switch(formName) {
            case 'password-reset':
              return this.renderPasswordResetForm()
            default:
              return this.renderSigninForm()
        }
    }

    render() {
        const { classes } = this.props; //handleSubmit, isLoading, translate
        return (
            <div className={classes.main}>
                <Card className={classes.card}>
                    <div className={classes.avatar}>
                        <Avatar className={classes.icon}>
                            <LockIcon />
                        </Avatar>
                    </div>
                    {this.formSwitcher(this.state.activatedForm)}
                </Card>
                <Notification />
            </div>
        );
    }
}

Login.propTypes = {
    ...propTypes,
    authProvider: PropTypes.func,
    classes: PropTypes.object,
    previousRoute: PropTypes.string,
    translate: PropTypes.func.isRequired,
    userLogin: PropTypes.func.isRequired,
    userSignup: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isLoading: state.admin.loading > 0,
    isVal: state.password.isVal,
    valErr: state.password.valErr,
});

const enhance = compose(
    translate,
    reduxForm({
        form: 'signIn',
        validate: (values, props) => {
            const errors = {};
            const { translate } = props;
            if (!values.email) {
                errors.username = translate('ra.validation.required');
            }
            if (!values.password) {
                errors.password = translate('ra.validation.required');
            }
            return errors;
        },
    }),
    connect(
        mapStateToProps,
        { userLogin, sendCodeVal, setCode },
    ),
    withStyles(styles)
);

const EnhancedLogin = enhance(Login);

// We need to put the MuiThemeProvider decoration in another component
// Because otherwise the withStyles() HOC used in EnhancedLogin won't get
// the right theme
const LoginWithTheme = props => (
    <MuiThemeProvider theme={createMuiTheme(lightTheme)}>
        <EnhancedLogin {...props} />
    </MuiThemeProvider>
);

class PasswordInput extends React.Component {
    state = {
        showPassword: false,
    };

    togglePasswordVisibility = () => {
        this.setState((prevState) => ({
            showPassword: !prevState.showPassword,
        }));
    };

    render() {
        const { label, source, ...props } = this.props;
        const { showPassword } = this.state;

        return (
            <div style={{
                position: "relative"
            }}>
                <TextInput
                    style={{
                        width: '100%',
                    }}
                    label={label}
                    source={source}
                    type={showPassword ? 'text' : 'password'}
                    {...props}
                />
                <Button
                    style={{
                        position: 'absolute',
                        top: "28px",
                        right:"0px"
                    }}
                    variant="text"
                    onClick={this.togglePasswordVisibility}
                    edge="end"
                    aria-label={showPassword ? 'Hide Password' : 'Show Password'}
                >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </Button>
            </div>
        );
    }

}

export default LoginWithTheme;
