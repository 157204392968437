import React from 'react';
import { WithPermissions, MenuItemLink } from "react-admin";

import users from "../../users";
import locations from "../../locations";
import coins from "../../coins";
import transactions from "../../transactions";
import SubMenu from "../SubMenu";

import BusinessIcon from "@material-ui/icons/Business";

// Promoter Menu
export const PromoterSideMenu = ({ handleToggle, menuBusiness, menuDocs, menuCatalog, open, translate, onMenuClick }) => (
    <WithPermissions
        render={({ permissions }) => (
            permissions === 'promoter'
                ? null
                : null
        )}
    >
    </WithPermissions>
)