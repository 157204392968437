import TransactionIcon from '@material-ui/icons/MonetizationOn';
import TransactionList from './TransactionList';
import TransactionEdit from './TransactionEdit';


export default {
    list: TransactionList,
    edit: TransactionEdit,
    icon: TransactionIcon,
};
