import React from 'react';
import {
    Show,
    TextField,
    SimpleShowLayout,
    ReferenceField,
    ImageField,
    ChipField,
} from 'react-admin';

const UserShow = ({ ...props }) => (
    <Show title="Payment Method Detail" {...props}>
        <SimpleShowLayout>
            <TextField source="code" label="Code" />
            <TextField source="label" label="Label" />
            <ImageField source="icon" title="Icon" />
            <TextField source="status" label="Status" />
        </SimpleShowLayout>
    </Show>
);

export default UserShow;
