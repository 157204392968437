// PRIMARY
export const DARK = '#212121'
export const GOLD = '#EAC14E'
export const LIGHT = '#FFFFFF'

export const ERROR = '#FC5479'
export const SUCCESS = '#91E04C'
export const INFO = '#5D8EF9'
export const WARN = '#F2AF5F'

export const DARK_80 = '#444444'
export const DARK_60 = '#7A7A7A'
export const DARK_40 = '#A5A5A5'
export const DARK_20 = '#D6D6D6'

export const GOLD_80 = '#F2D079'
export const GOLD_60 = '#F9DEA0'
export const GOLD_40 = '#FCE7BE'
export const GOLD_20 = '#FFF4E1'
export const GOLD_DISABLED ='#5C502F'
