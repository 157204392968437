import { parseSettingRate } from "./currency.parser";
import Big from 'big.js'

const coinsParser = (response, type, resource, params) => {

    if (type === 'GET_ONE' && resource === 'coins') {
        // console.log("coinsParser::response", response);
        const respData = response.json;
        const storeCommissionRateSettingBuy = parseSettingRate(respData.storeCommissionRateSetting.buy);
        const storeCommissionRateSettingSell = parseSettingRate(respData.storeCommissionRateSetting.sell);
        const systemCommissionRateSettingBuy = parseSettingRate(respData.systemCommissionRateSetting.buy);
        const systemCommissionRateSettingSell = parseSettingRate(respData.systemCommissionRateSetting.sell);
        const operatorCommissionRateSettingBuy = parseSettingRate(respData.operatorCommissionRateSetting.buy);
        const operatorCommissionRateSettingSell = parseSettingRate(respData.operatorCommissionRateSetting.sell);
        const promoterCommissionRateSettingBuy = parseSettingRate(respData.promoterCommissionRateSetting.buy);
        const promoterCommissionRateSettingSell = parseSettingRate(respData.promoterCommissionRateSetting.sell);
        const currencyMap = {
            respData: respData,
            storeCommissionRateSettingBuy,
            storeCommissionRateSettingSell,
            systemCommissionRateSettingBuy,
            systemCommissionRateSettingSell,
            operatorCommissionRateSettingBuy,
            operatorCommissionRateSettingSell,
            promoterCommissionRateSettingBuy,
            promoterCommissionRateSettingSell
        };
        window._currentCoinCurrencyMap = currencyMap;
        // 添加上自定义的resource

        respData.storeCommissionRateSetting.buy.forEach(x=>{
            response.json[`storeCommissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        respData.storeCommissionRateSetting.sell.forEach(x=>{
            response.json[`storeCommissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        respData.systemCommissionRateSetting.buy.forEach(x=>{
            response.json[`systemCommissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        respData.systemCommissionRateSetting.sell.forEach(x=>{
            response.json[`systemCommissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        respData.operatorCommissionRateSetting.buy.forEach(x=>{
            response.json[`operatorCommissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        respData.operatorCommissionRateSetting.sell.forEach(x=>{
            response.json[`operatorCommissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        respData.promoterCommissionRateSetting.buy.forEach(x=>{
            response.json[`promoterCommissionRateSettingBuy_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        respData.promoterCommissionRateSetting.sell.forEach(x=>{
            response.json[`promoterCommissionRateSettingSell_${x.max}`] = parseFloat(new Big(x.rate * 100).toFixed(2));
        });
        return response;
    } else {
        return response;
    }

}

export default coinsParser;