import React, { useEffect } from "react";
import {
	Edit,
	SimpleForm,
	BooleanInput,
	TextField,
	NumberInput,
	required,
	number,
	minValue,
	Toolbar,
	SaveButton,
	SelectInput
} from "react-admin";

const formStyle = {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
};

const formItemStyle = {
    display: 'flex-column',
}

export const validatePrecision = (value) => {
	if (/^\d+(\.\d{1,2})?$/g.test(value)) {
		return undefined;
	}
	return "Error precision";
};

const validateLimit = [required(), number(), minValue(0)];
const inputStyle = { width: "20em" };
const validateTxMax = [required(), number()];
const validateTxMin = [required(), number()];

const CoinEditToolbar = (props) => (
	<Toolbar {...props}>
		<SaveButton />
	</Toolbar>
);

let interval = 0;

const AccountCoinEdit = ({ permissions, ...props }) => {
	const [selectSymbol, setSymbol] = React.useState("");
	const [selectCurrency, setCurrency] = React.useState("");
	const [currencyRate, setCurrencyRate] = React.useState(null);

	useEffect(() => {
		interval = setInterval(() => {
			if (window._currentAccountCoinCurrencyMap) {
				onCurrencyChange();
			}
		}, 300);
		return () => {
			clearInterval(interval);
		};
	}, []);

	const onCurrencyChange = () => {
		if (window._currentAccountCoinCurrencyMap) {
			const _rateObj = window._currentAccountCoinCurrencyMap;
			if (_rateObj) {
				setCurrencyRate(_rateObj);
				setSymbol(_rateObj.respData.symbol);
				setCurrency(_rateObj.respData.currency);
			}
		}
	};

	const redirect = (basePath, id, data) => {
		const realId = id.split("@")[0];
		return `/accountcoins/${realId}/show`;
	};

	return (
		<Edit title="Coins" {...props} actions={null}>
			<SimpleForm
				toolbar={<CoinEditToolbar />}
				redirect={redirect}
				// validate={validateBuySellRatio}
			>
				<TextField source="symbol" label="Symbol" id="symbol" />
				<TextField source="currency" label="Currency" id="currency" />
				<SelectInput source="tradeFlag" label="TradeFlag" choices={[
					{ id: 3, name: 'BUY/SELL' },
					{ id: 1, name: 'BUY' },
					{ id: 2, name: 'SELL' },
				]} />

				{currencyRate && (
					<>
						<p>System Buy Commission Rate Setting</p>
						{currencyRate.systemCommissionRateSettingBuy.map((x, i) => {
							return (
								<NumberInput
									key={x.key}
									style={inputStyle}
									id={`systemCommissionRateSettingBuy_${x.max}`}
									source={`systemCommissionRateSettingBuy_${x.max}`}
									label={x.key + " (%)"}
									disabled={
										selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
											? true
											: false
									}
									validate={validatePrecision}
								/>
							);
						})}
						<p>System Sell Commission Rate Setting</p>
						{currencyRate.systemCommissionRateSettingSell.map((x, i) => {
							return (
								<NumberInput
									key={x.key}
									style={inputStyle}
									id={`systemCommissionRateSettingSell_${x.max}`}
									source={`systemCommissionRateSettingSell_${x.max}`}
									label={x.key + " (%)"}
									readOnly
									disabled={
										selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
											? true
											: false
									}
									validate={validatePrecision}
								/>
							);
						})}
					</>
				)}
				{currencyRate && (
					<>
						<p>Store Buy Commission Rate Setting</p>
						{currencyRate.storeCommissionRateSettingBuy.map((x, i) => {
							return (
								<NumberInput
									key={x.key}
									style={inputStyle}
									id={`storeCommissionRateSettingBuy_${x.max}`}
									source={`storeCommissionRateSettingBuy_${x.max}`}
									label={x.key + " (%)"}
									disabled={
										selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
											? true
											: false
									}
									validate={validatePrecision}
								/>
							);
						})}
						<p>Store Sell Commission Rate Setting</p>
						{currencyRate.storeCommissionRateSettingSell.map((x, i) => {
							return (
								<NumberInput
									key={x.key}
									style={inputStyle}
									id={`storeCommissionRateSettingSell_${x.max}`}
									source={`storeCommissionRateSettingSell_${x.max}`}
									label={x.key + " (%)"}
									readOnly
									disabled={
										selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
											? true
											: false
									}
									validate={validatePrecision}
								/>
							);
						})}
					</>
				)}
				{currencyRate && currencyRate.operatorCommissionRateSettingBuy && (
					<>
						<p>Operator Buy Commission Rate Setting</p>
						{currencyRate.operatorCommissionRateSettingBuy.map((x, i) => {
							return (
								<NumberInput
									key={x.key}
									style={inputStyle}
									id={`operatorCommissionRateSettingBuy_${x.max}`}
									source={`operatorCommissionRateSettingBuy_${x.max}`}
									label={x.key + " (%)"}
									disabled={
										selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
											? true
											: false
									}
									validate={validatePrecision}
								/>
							);
						})}
						<p>Operator Sell Commission Rate Setting</p>
						{currencyRate.operatorCommissionRateSettingSell.map((x, i) => {
							return (
								<NumberInput
									key={x.key}
									style={inputStyle}
									id={`operatorCommissionRateSettingSell_${x.max}`}
									source={`operatorCommissionRateSettingSell_${x.max}`}
									label={x.key + " (%)"}
									readOnly
									disabled={
										selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
											? true
											: false
									}
									validate={validatePrecision}
								/>
							);
						})}
					</>
				)}
				{currencyRate && currencyRate.promoterCommissionRateSettingBuy && (
					<>
						<p>Promoter Buy Commission Rate Setting</p>
						{currencyRate.promoterCommissionRateSettingBuy.map((x, i) => {
							return (
								<NumberInput
									key={x.key}
									style={inputStyle}
									id={`promoterCommissionRateSettingBuy_${x.max}`}
									source={`promoterCommissionRateSettingBuy_${x.max}`}
									label={x.key + " (%)"}
									disabled={
										selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
											? true
											: false
									}
									validate={validatePrecision}
								/>
							);
						})}
						<p>Promoter Sell Commission Rate Setting</p>
						{currencyRate.promoterCommissionRateSettingSell.map((x, i) => {
							return (
								<NumberInput
									key={x.key}
									style={inputStyle}
									id={`promoterCommissionRateSettingSell_${x.max}`}
									source={`promoterCommissionRateSettingSell_${x.max}`}
									label={x.key + " (%)"}
									readOnly
									disabled={
										selectSymbol === "ERCUSDT" || selectSymbol === "TRCUSDT"
											? true
											: false
									}
									validate={validatePrecision}
								/>
							);
						})}
					</>
				)}
			</SimpleForm>
		</Edit>
	);
};

export default AccountCoinEdit;
