import React from "react";
import {
	List,
	Datagrid,
	TextField,
	ReferenceField,
} from "react-admin";

const PaymentFeeList = ({ permissions, ...props }) => (
	<List {...props} pagination={null} bulkActionButtons={false} exporter={false} title={'Payment Fee'}>
		<Datagrid rowClick="edit">
			{/* <TextField source="id" label="ID" /> */}
			<ReferenceField
				reference="system/paymentmethods"
				label="Payment Method"
				source="payment_method_id"
				linkType="show"
			>
				<TextField source="label" label="Payment Method" />
			</ReferenceField>
			<TextField source="fee_rate_show" label="Fee Rate" />
			<TextField source="sort" title="Sort" />
			<TextField source="status" label="Status" />

		</Datagrid>
	</List>
);

export default PaymentFeeList;
