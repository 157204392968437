const accountParser = (response, type, resource, params) => {
    if (type === 'GET_LIST' && resource === 'accounts') {
        const respData = response.json;
        respData.map((item) => {
            if (Object.prototype.hasOwnProperty.call(item, 'locations')) {
                if (!item.locations || item.locations.length === 0 || !item.locations[0].nickName) {
                    item.locationsStr = '';
                } else {
                    item.locationsStr = item.locations[0].nickName;
                }

            } else {
                item.locationsStr = '';
            }
        });
        return response;
    } else {
        return response;
    }
}

export default accountParser