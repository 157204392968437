import React, { useState,useEffect } from 'react';
import {
    TextField,
    Select,
    MenuItem,
    Checkbox,
    FormControlLabel,
    Button,
    Typography,
    FormControl,
    FormHelperText,
} from '@material-ui/core';

// 你需要保证有可用的 dataProvider，路径依你的项目情况而定
import dataProvider from '../dataProvider';

const COUNTRY_LIST = [
    { id: 'Hong Kong, China', name: 'Hong Kong, China',currency: 'HKD',symbol:'HKG' },
    { id: 'Taiwan, China', name: 'Taiwan, China',currency: 'TWD', symbol:'TW' },
    { id: 'Canada', name: 'Canada',currency: 'CAD', symbol:'CAN' },
    { id: 'USA', name: 'USA',currency: 'USD', symbol:'USA' },
];

// 验证函数
const validateCompanyName = (name) => {
    if (!name) {
        return 'Company Name is required.';
    }
    return '';
};

const validateLastName = (name) => {
    if (!name) {
        return 'Last Name is required.';
    }
    if (name.length < 2 || name.length > 15) {
        return 'Last Name must be between 2 and 15 characters.';
    }
    return '';
};

const validateAddress = (address) => {
    if (!address) {
        return 'Street Address is required.';
    }
    return '';
};

const validateCity = (city) => {
    if (!city) {
        return 'City is required.';
    }
    return '';
};

const validateState = (state) => {
    if (!state) {
        return 'State is required.';
    }
    return '';
};

const validatePostalCode = (postalCode) => {
    if (!postalCode) {
        return 'Postal Code is required.';
    }
    return '';
};

const validateCountry = (country) => {
    if (!country) {
        return 'Country is required.';
    }
    return '';
};

// 修改后的AccountCreateForm组件
const AccountCreateForm = ({ handleDialogChange, isMobile }) => {
    // 基本信息
    const [companyName, setCompanyName] = useState('');
    const [registrationNumber, setRegistrationNumber] = useState('');
    const [formationDate, setFormationDate] = useState('');
    
    // 移除 address2
    const [address1, setAddress1] = useState('');
    const [city, setCity] = useState('');
    const [stateVal, setStateVal] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    
    // 银行账户信息
    const [bankAccountName, setBankAccountName] = useState('');
    const [bankAccountNumber, setBankAccountNumber] = useState('');
    const [bankTransitNumber, setBankTransitNumber] = useState('');
    const [bankInstitutionNumber, setBankInstitutionNumber] = useState('');
    const [bankAccountType, setBankAccountType] = useState('');

    // 添加 countrySymbol state
    const [countrySymbol, setCountrySymbol] = useState('');
    const [currency, setCurrency] = useState('');

    const [errors, setErrors] = useState({
        companyName: '',
        registrationNumber: '',
        formationDate: '',
        address1: '',
        city: '',
        stateVal: '',
        postalCode: '',
        country: '',
    });

    const handleCompanyNameChange = (e) => {
        const name = e.target.value;
        setCompanyName(name);
        const error = validateCompanyName(name);
        setErrors((prevErrors) => ({ ...prevErrors, companyName: error }));
    };

    const handleLastNameChange = (e) => {
        const name = e.target.value;
        setRegistrationNumber(name);
        const error = validateLastName(name);
        setErrors((prevErrors) => ({ ...prevErrors, registrationNumber: error }));
    };

    const handleAddress1Change = (e) => {
        const address = e.target.value;
        setAddress1(address);
        const error = validateAddress(address);
        setErrors((prevErrors) => ({ ...prevErrors, address1: error }));
    };

    const handleCityChange = (e) => {
        const cityName = e.target.value;
        setCity(cityName);
        const error = validateCity(cityName);
        setErrors((prevErrors) => ({ ...prevErrors, city: error }));
    };

    const handleStateChange = (e) => {
        const state = e.target.value;
        setStateVal(state);
        const error = validateState(state);
        setErrors((prevErrors) => ({ ...prevErrors, stateVal: error }));
    };

    const handlePostalCodeChange = (e) => {
        const postal = e.target.value;
        setPostalCode(postal);
        const error = validatePostalCode(postal);
        setErrors((prevErrors) => ({ ...prevErrors, postalCode: error }));
    };

    const handleCountryChange = (e) => {
        const countryVal = e.target.value;
        setCountry(countryVal);
        
        // 设置对应的 currency 和 symbol
        const selectedCountry = COUNTRY_LIST.find(item => item.id === countryVal);
        if (selectedCountry) {
            setCurrency(selectedCountry.currency);
            setCountrySymbol(selectedCountry.symbol);
        }
        
        const error = validateCountry(countryVal);
        setErrors((prevErrors) => ({ ...prevErrors, country: error }));
    };

    const validateCompanyName = (name) => {
        if (!name) return 'Company name is required';
        return '';
    };

    const validateRegistrationNumber = (number) => {
        if (!number) return 'Registration number is required';
        return '';
    };

    const validateFormationDate = (date) => {
        if (!date) return 'Formation date is required';
        return '';
    };

    const validateBankFields = (value, fieldName) => {
        if (!value) return `${fieldName} is required`;
        return '';
    };

    const handleSubmit = async () => {
        const newErrors = {
            companyName: validateCompanyName(companyName),
            registrationNumber: validateRegistrationNumber(registrationNumber),
            formationDate: validateFormationDate(formationDate),
            address1: validateAddress(address1),
            city: validateCity(city),
            stateVal: validateState(stateVal),
            postalCode: validatePostalCode(postalCode),
            country: validateCountry(country),
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error)) {
            return;
        }

        const data = {
            companyName,
            registrationNumber,
            formationDate,
            currencySymbol: currency,
            countrySymbol,
            bankAccountName,
            bankAccountNumber,
            bankTransitNumber,
            bankInstitutionNumber,
            bankAccountType,
            address: {
                address1,
                city,
                state: stateVal,
                postalCode,
                country,
            },
        };
        try {
            await dataProvider('CREATE', 'accounts', { data });
            localStorage.setItem('BITFA:DASHBOARD:REGISTER:STEP', "0");
            if(handleDialogChange) {
                handleDialogChange();
            }
        } catch (error) {
            console.error("Failed to create account:", error);
            alert('Failed to create account');
        }
    };

    return (
        <div style={{ 
            width: isMobile ? '100%' : 500, 
            padding: isMobile ? 10 : 20 
        }}>
            {/* 调整标题样式 */}
            <Typography variant="h6" style={{ 
                marginBottom: isMobile ? 8 : 16,
                fontSize: isMobile ? 16 : 20 
            }}>
                Basic Information
            </Typography>
            
            {/* 调整输入框样式 */}
            <TextField
                label="Company Name *"
                value={companyName}
                onChange={handleCompanyNameChange}
                fullWidth
                style={{ 
                    marginBottom: isMobile ? 8 : 16,
                    fontSize: isMobile ? 14 : 16 
                }}
                error={!!errors.companyName}
                helperText={errors.companyName}
            />
            <TextField
                label="Registration Number *"
                value={registrationNumber}
                onChange={(e) => setRegistrationNumber(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
                error={!!errors.registrationNumber}
                helperText={errors.registrationNumber}
            />
            <TextField
                label="Formation Date *"
                type="date"
                value={formationDate}
                onChange={(e) => setFormationDate(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
                InputLabelProps={{ shrink: true }}
                error={!!errors.formationDate}
                helperText={errors.formationDate}
            />

            {/* Address Information */}
            <Typography variant="h6" style={{ margin: '24px 0 16px' }}>
                Primary Business Address
            </Typography>
            <TextField
                label="Street Address *"
                value={address1}
                onChange={handleAddress1Change}
                fullWidth
                style={{ marginBottom: 16 }}
                error={!!errors.address1}
                helperText={errors.address1}
            />
            <TextField
                label="City *"
                value={city}
                onChange={handleCityChange}
                fullWidth
                style={{ marginBottom: 16 }}
                error={!!errors.city}
                helperText={errors.city}
            />
            <TextField
                label="State *"
                onChange={handleStateChange}
                value={stateVal}
                fullWidth
                style={{ marginBottom: 16 }}
                error={!!errors.stateVal}
                helperText={errors.stateVal}
            />
            <TextField
                label="Postal Code *"
                onChange={handlePostalCodeChange}
                value={postalCode}
                fullWidth
                style={{ marginBottom: 16 }}
                error={!!errors.postalCode}
                helperText={errors.postalCode}
            />

            <Typography variant="body1" style={{ margin: '8px 0' }}>
                Country *
            </Typography>
            <FormControl 
                fullWidth 
                error={!!errors.country}
                style={{ marginBottom: 16 }}
            >
                <Select
                    value={country}
                    onChange={handleCountryChange}
                    displayEmpty
                >
                    <MenuItem value="">Select Country</MenuItem>
                    {COUNTRY_LIST.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
                {errors.country && (
                    <FormHelperText>{errors.country}</FormHelperText>
                )}
            </FormControl>

            {/* Bank Account Information */}
            <Typography variant="h6" style={{ margin: '24px 0 16px' }}>
                Bank Account Information
            </Typography>
            <TextField
                label="Bank Account Name"
                value={bankAccountName}
                onChange={(e) => setBankAccountName(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
            />
            <TextField
                label="Bank Account Number"
                value={bankAccountNumber}
                onChange={(e) => setBankAccountNumber(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
            />
            <TextField
                label="Bank Transit Number"
                value={bankTransitNumber}
                onChange={(e) => setBankTransitNumber(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
            />
            <TextField
                label="Bank Institution Number"
                value={bankInstitutionNumber}
                onChange={(e) => setBankInstitutionNumber(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
            />
            <Select
                value={bankAccountType}
                onChange={(e) => setBankAccountType(e.target.value)}
                fullWidth
                style={{ marginBottom: 16 }}
                displayEmpty
            >
                <MenuItem value="">Select Bank Account Type</MenuItem>
                <MenuItem value="Checking">Checking</MenuItem>
                <MenuItem value="Savings">Savings</MenuItem>
            </Select>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                style={{ 
                    marginTop: isMobile ? 8 : 16,
                    height: isMobile ? 36 : 42 
                }}
            >
                Save
            </Button>
        </div>
    );
}

export default AccountCreateForm;

