
import {
    BITFA_BASE_URL_PROD_V12,
    BITFA_BASE_URL_DEV,
    BITFA_BASE_URL_LOCAL,
    BITFA_DASHBOARD_URL_PROD_V12,
    BITFA_DASHBOARD_URL_DEV,
    BITFA_DASHBOARD_URL_LOCAL,
  } from './constants'

const getBaseURL = () => {
  console.log(process.env.NODE_ENV)

  if (process.env.REACT_APP_ENV === 'production.v12') {
    return BITFA_BASE_URL_PROD_V12
  } else if (process.env.REACT_APP_ENV === 'development') {
    return BITFA_BASE_URL_DEV
  }
  else {
    return BITFA_BASE_URL_LOCAL // in stging env
  }
}

export const getUserURL = () => {
    return getBaseURL() // in stging or produciton env
}

export const getDashboardURL = () => {
  if (process.env.REACT_APP_ENV === 'production.v12') {
    return BITFA_DASHBOARD_URL_PROD_V12
  } else if (process.env.REACT_APP_ENV === 'development') {
    return BITFA_DASHBOARD_URL_DEV
  } else {
    return BITFA_DASHBOARD_URL_LOCAL // in stging or produciton env
  }
}