import OperatorIcon from '@material-ui/icons/DirectionsRun';

import OperatorList from './OperatorList';
import OperatorEdit from './OperatorEdit';
import OperatorShow from './OperatorShow';


export default {
    list: OperatorList,
    edit: OperatorEdit,
    show: OperatorShow,
    icon: OperatorIcon,
};
