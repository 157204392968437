import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-admin';
import { getDashboardURL } from '../api'
import { settleTransaction } from './transactionActions'


class SettleButton extends Component {
    handleClick = () => {
        const { selectedIds, settleTransaction } = this.props;
        settleTransaction(selectedIds, true);
        setTimeout(function(){
            window.location.reload(getDashboardURL()+"/#/transactions")
        }, 2000)
    };

    render() {
        return (
            <Button label="Settle Transactions" redirect={false} onClick={this.handleClick} />
        );
    }
}

export default connect(undefined, { settleTransaction })(SettleButton);