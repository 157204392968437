import { all, call, takeLatest } from 'redux-saga/effects';
import {
  SETTLE_TRANSACTION,
 } from './transactionActions';

import { settleTransactions } from './transactionServices'

export function* settleSelectedTransactions(action) {
  console.log("saga settleTransaction")
  try {
    const res = yield call(() => settleTransactions(action.ids, action.isSettle))
    console.log(res)
  } catch (e) {
    // yield put(setResetReqErr('Email is invalid or server error.'))
    console.log(e)
    console.log(' settleTransaction error')
  }
}

export default function* accountSaga() {
  yield all([
    takeLatest(SETTLE_TRANSACTION, settleSelectedTransactions),
  ]);
}
