import React from 'react';
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    CardActions,
    CreateButton,
} from 'react-admin';
import CustomDateTimeField from '../common/CustomDateTimeField';
import CustomBalanceField from '../common/CustomBalanceField';

const FundingActions = ({
    permissions,
    bulkActions,
    basePath,
    displayedFilters,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
}) => (
        <CardActions>
            {bulkActions && React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
            {filters && React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {/* {(permissions === 'operator' || permissions === 'system_admin' ) ? <CreateButton basePath='fundings' /> : <CustomBalanceField />} */}
            {(permissions === 'operator' || permissions === 'system_admin' ) ? <CreateButton basePath='fundings' /> : null}
        </CardActions>
    );

const FundingList = ({ permissions, ...props }) => (
// {/* <List {...props} pagination={null} bulkActionButtons={false} exporter={false} actions={permissions === 'operator'? false: <FundingActions />} > */}
<List {...props} pagination={null} bulkActionButtons={false} exporter={false}  actions={ <FundingActions permissions={permissions} />} >
        <Datagrid rowClick="edit">
            <TextField source="id" label="ID"/>
            <TextField source="transaction_id" label="Transaction ID"/>
            <CustomDateTimeField source="createdAt" showTime options={{ timeZone:"America/Los_Angeles" }}/>
            <TextField source="account_name" label="Account"/>
            <TextField source="fundingType" label="Type"/>
            <TextField source="asset" label="Asset"/>
            <TextField source="method" label="Method"/>
            <TextField source="amount" label="Amount"/>
            <TextField source="fee" label="Fee"/>
            <TextField source="status" label="Status"/>
        </Datagrid>
    </List>
);

export default FundingList;

