import React from 'react';
import { BooleanField } from 'react-admin';

const CustomMFAField = ({ record, ...rest }) =>{
    console.log(record)
    if (record && record.active) {
        return  (
            <BooleanField source="active" label="Active"  record={record} {...rest} style={{ color: 'green' }}/>
        )
    }
    else {
        return  (
            <BooleanField source="active" label="Active"  record={record} {...rest} style={{ color: 'red' }}/>
        )
    }

}


export default CustomMFAField;