// import listReducer from '../reducers/helpers/list-reducer';
// export default listReducer(PASSWORD_RESET_CODE_VALIDATING, PASSWORD_RESET_CODE_INVALID, PASSWORD_RESET_CODE_VALIDATED)
import {
  SET_IS_REQ_SENT,
  SET_RESET_REQ_ERR,
  SET_IS_VAL,
  SET_VAL_ERR,
  SET_IS_UPDATED,
  SET_UPDATE_ERR,
  SET_CODE,
} from './passwordActions';


const initialState = {
  code: null,

  isReqSent: false,
  reqErr: null,

  isVal: false,
  valErr: null,

  isUpdated: false,
  updateErr: null,
}

const passwordReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_REQ_SENT:
      return {
        ...state,
        isReqSent: true,
      }
    case SET_RESET_REQ_ERR:
      return {
        ...state,
        reqErr: action.err,
      }
    case SET_IS_VAL:
      return {
        ...state,
        isVal: true,
      }
    case SET_VAL_ERR:
      return {
        ...state,
        valErr: action.err,
      }
    case SET_IS_UPDATED:
      return {
        ...state,
        isUpdated: true,
      }
    case SET_UPDATE_ERR:
      return {
        ...state,
        updateErr: action.err,
      }
    case SET_CODE:
      return {
        ...state,
        code: action.code,
      }
    default:
      return state
  }
}

export default passwordReducer
