import React from 'react';
import compose from 'recompose/compose';
import Card from '@material-ui/core/Card';
import ShoppingCartIcon from '@material-ui/icons/AccountBalance';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-admin';

import CardIcon from './CardIcon';

const styles = {
    main: {
        flex: '1',
        marginLeft: '1em',
        marginTop: 20,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
    card: {
        overflow: 'inherit',
        textAlign: 'right',
        padding: 16,
        minHeight: 52,
    },
};

const NbNewDeposits = ({ actNum, accountBalances, translate, classes }) => (
    <div className={classes.main}>
        <CardIcon Icon={ShoppingCartIcon} bgColor="#31708f" />
        <Card className={classes.card}>
            <Typography className={classes.title} color="textSecondary">
                {translate('pos.dashboard.account_number')}
            </Typography>
            <Typography variant="headline" component="h2">
                {actNum}
            </Typography>
            {accountBalances.map(balance => (
                <div key={balance.id}>
                    <Typography variant="headline" component="h2">
                        {balance.currency}
                    </Typography>
                    <Typography className={classes.title} color="textSecondary">
                        {translate('pos.dashboard.credit_limit')}: <b>{balance.creditLimit}</b>
                    </Typography>
                    <Typography className={classes.title} color="textSecondary">
                        {translate('pos.dashboard.available_credit')}: <b>{(balance.creditLimit+balance.balance).toFixed(2)}</b>
                    </Typography>
                    <Typography className={classes.title} color="textSecondary">
                        {translate('pos.dashboard.account_balance')}: <b>{balance.balance}</b>
                    </Typography>
                </div>
            ))}
        </Card>
    </div>
);

const enhance = compose(
    withStyles(styles),
    translate
);

export default enhance(NbNewDeposits);
