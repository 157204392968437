import React, { Component } from 'react';
import { GET_LIST, GET_MANY, Responsive, withDataProvider } from 'react-admin';
import compose from 'recompose/compose';
import { connect } from 'react-redux';

import Welcome from './Welcome';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import NbNewDeposits from './NbNewDeposits';
import NbNewCommission from './NbNewCommission';
import NbNewCoinBalance from './NbNewCoinBalance';
import { setSettleType } from '../accounts/accountActions';

const styles = {
    flex: { display: 'flex', flexWrap: 'wrap' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
};

function showAccountInfo() {
    let userType = localStorage.getItem("BITFA:DASHBOARD:AUTH:USERTYPE");
    if (userType == 'store_owner') {
        return true
    }
    return false
}

function showSystemAdminInfo() {
    let userType = localStorage.getItem("BITFA:DASHBOARD:AUTH:USERTYPE");
    if (userType == 'system_admin') {
        return true
    }
    return false
}

class Dashboard extends Component {
    state = {};

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        // handle refresh
        if (this.props.version !== prevProps.version) {
            this.fetchData();
        }
    }

    fetchData() {
        this.fetchSummary();
        let userType = localStorage.getItem("BITFA:DASHBOARD:AUTH:USERTYPE");
        //this.setState({ userType:userType });
        if (showAccountInfo()) {
            this.fetchAccountBalance();
            this.fetchAccounts();
        }
        if (showSystemAdminInfo()) {
            this.fetchSystemCoinBalances();
        }
    }

    async fetchSummary() {
        const { dataProvider } = this.props;
        this.setState({
            txSummary: [],
        })
        const { data: summary } = await dataProvider(GET_LIST, 'user/summary', {
            filter: {},
            sort: { field: 'created_at', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        });
        if (summary.length > 0) {
            this.setState({
                txSummary: summary,
            })
        }
    }

    async fetchAccounts() {
        const { dataProvider, setSettleType } = this.props;
        const { data: accounts } = await dataProvider(GET_LIST, 'accounts', {
            filter: {},
            sort: { field: 'created_at', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        });
        var accountNumber
        if (accounts.length > 0) {
            accountNumber = accounts[0].accountNumber;
            setSettleType(accounts[0].settleType)
        } else {
            accountNumber = "N/A"
        }
        this.setState({ accountNumber });
    }

    async fetchAccountBalance() {
        const { dataProvider, setSettleType } = this.props;
        this.setState({
            accountBalances: [],
        })
        const { data: accountBalances } = await dataProvider(GET_MANY, 'account/balances', {
            ids: '',
        });
        this.setState({ accountBalances: accountBalances });
    }

    async fetchSystemCoinBalances() {
        const { dataProvider, setSettleType } = this.props;
        this.setState({
            systemCoinBalances: [],
        })
        const { data: systemCoinBalances } = await dataProvider(GET_MANY, 'system/coin/balances', {
            ids: '',
        });
        this.setState({ systemCoinBalances: systemCoinBalances });
    }

    render() {
        const {
            accountNumber,
            txSummary,
            accountBalances,
            systemCoinBalances,
        } = this.state;
        return (
            <Responsive
                xsmall={
                    <div>
                        <div style={styles.flexColumn}>
                            <div style={{ marginBottom: '2em' }}>
                                <Welcome />
                            </div>
                            <div style={styles.flex}>
                                <MonthlyRevenue txSummary={txSummary} />
                                <NbNewOrders txSummary={txSummary} />
                                <NbNewCommission txSummary={txSummary} />
                                {showAccountInfo() && <NbNewDeposits actNum={accountNumber} accountBalances={accountBalances} />}
                                {showSystemAdminInfo() && <NbNewCoinBalance systemCoinBalances={systemCoinBalances} />}
                            </div>
                        </div>
                    </div>
                }
                small={
                    <div style={styles.flexColumn}>
                        <div style={styles.singleCol}>
                            <Welcome />
                        </div>
                        <div style={styles.flex}>
                            <MonthlyRevenue txSummary={txSummary} />
                            <NbNewOrders txSummary={txSummary} />
                            <NbNewCommission txSummary={txSummary} />
                            {showAccountInfo() && <NbNewDeposits actNum={accountNumber} accountBalances={accountBalances} />}
                            {showSystemAdminInfo() && <NbNewCoinBalance systemCoinBalances={systemCoinBalances} />}
                        </div>
                    </div>
                }
                medium={
                    <div style={styles.flex}>
                        <div style={styles.leftCol}>
                            <div style={styles.flex}>
                                <MonthlyRevenue txSummary={txSummary} />
                                <NbNewOrders txSummary={txSummary} />
                            </div>
                            <div style={styles.singleCol}>
                                <Welcome />
                            </div>
                        </div>
                        <div style={styles.rightCol}>
                            <div style={styles.flex}>
                                <NbNewCommission txSummary={txSummary} />
                                {showAccountInfo() && <NbNewDeposits actNum={accountNumber} accountBalances={accountBalances} />}
                                {showSystemAdminInfo() && <NbNewCoinBalance systemCoinBalances={systemCoinBalances} />}
                            </div>
                        </div>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    version: state.admin.ui.viewVersion,
});

const mapDispatchToProps = {
    setSettleType,
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withDataProvider
)(Dashboard);