import React from 'react';
import {
    Edit,
    SimpleForm,
    BooleanInput,
    TextField,
    NumberInput,
    DateField,
    required,
    number,
    minValue
} from 'react-admin';

const validateLimit = [required(), number(), minValue(0)];
const inputStyle = { width: "20em" };
const AccountEdit = ({permissions, ...props}) => (
    <Edit title="Account" {...props}>
        <SimpleForm>
            <TextField
                source="companyName"
            />
            <TextField
                source="registrationNumber"
            />
            <DateField
                source="formationDate"
            />
            <BooleanInput label="Multi factor authentication" source="isMFA" />
            {(permissions === "system_admin" || permissions === "operator") ? [
                <NumberInput style={inputStyle} source="creditLimit" label="CreditLimit" validate={validateLimit} />,
                <NumberInput style={inputStyle} source="notifyUpperLimit" label="Sell Notify Upper Limit" validate={validateLimit} />
            ] : null}
        </SimpleForm>
    </Edit>
);

export default AccountEdit;
