import React from 'react';
import { BooleanField, Labeled } from 'react-admin';

const CustomMFAField = ({ record, ...rest }) =>{
    console.log(record)
    if (record && record.isMFA) {
        return  (
            <Labeled label="Multi Factor Authentication">
            <BooleanField source="isMFA" label="Two Factor Authentication"  record={record} {...rest} style={{ color: 'green' }}/>
            </Labeled>
        )
    }
    else {
        return  (
            <Labeled label="Multi Factor Authentication">
            <BooleanField source="isMFA" label="Two Factor Authentication"  record={record} {...rest} style={{ color: 'red' }}/>
            </Labeled>
        )
    }

}


export default CustomMFAField;