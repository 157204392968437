import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '../common/Button';
import LoginFormMessage from './LoginFormMessage'
import CheckCircle from '@material-ui/icons/CheckCircle';
import { ErrorWrapper } from '../common/ErrorWrapper'


import { connect } from 'react-redux';
import {
  sendResetReq,
} from './passwordActions'

const styles = theme => ({
  heading: {
    opacity: 0.8,
    textTransform: 'uppercase',
    textAlign: 'left',
    marginTop: 20,
    color: theme.palette.primary['500'],
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  subheading: {
    textAlign: 'left',
    marginTop: 20,
  },
  submit: {
    marginTop: 30,
    marginBottom: 30
  }
});

class PasswordResetForm extends React.Component {
  state = {
    isLoading: false,
    done: false,
    email: '',
    captchaResponse: '',
  }

  // toggleLoading = () => {
  //     this.setState({
  //       loading: !this.state.loading,
  //     });
  //   }

  onInputChange = event => {
    this.setState({
      email: event.target.value,
    });
  }

  onClick = () => {
    const { sendResetReq } = this.props;
    this.setState({
      isLoading: true,
    }, () => {
      console.log("Sending password reset request")
      console.log(this.state.email)
      sendResetReq(this.state.email);
    })
  }

  resetView =() => {
    window.location.assign('/')
  }

  renderDone() {
    const { classes } = this.props;
    return (
      <div className={classes.form}>
        <LoginFormMessage
          Icon={CheckCircle}
          // btnText={loginTranslations['return-to-login']}
          // message={loginTranslations['reset-password-link-sent']}
          btnText='return to login'
          message='Reset password link has been sent to your email.'
          onClick={this.resetView}
        />
      </div>
    )
  }

  renderError = () => {
    const { reqErr } = this.props
    console.log(reqErr)
    if (reqErr) {
      return (
        <ErrorWrapper>{reqErr}</ErrorWrapper>
      )
    }
    return null
  }

  validateEmail = email => {
    const re = /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  render() {
    const { classes, isReqSent } = this.props

    if (isReqSent) {
      return this.renderDone();
    }
    return (
      <div className={classes.form}>
        <Typography variant="subheading" className={classes.heading}>
          {/* { loginTranslations['forgot-password'] } */}
         {/* Forgot Password */}
                </Typography>
        <Typography variant="subheading" className={classes.subheading}>
          {/* { loginTranslations['reset-password-instructions'] } */}
          Please input your email address:

                </Typography>
        <TextField
          fullWidth
          // label={loginTranslations['email'].toUpperCase()}
          // placeholder={loginTranslations['placeholder-email']}
          label="email"
          placeholder="placeholder-email"
          type="email"
          margin="normal"
          name="email"
          onChange={this.onInputChange}
        />
        <br /><br />
        <Button
          round
          fullWidth
          variant="outlined"
          color="primary"
          className={classes.submit}
          disabled={!this.validateEmail(this.state.email) || this.state.loading}
          loading={this.state.loading}
          onClick={this.onClick}
        >
          {/* {loginTranslations['submit']} */}
          submit

                </Button>
        {this.renderError()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isReqSent: state.password.isReqSent,
  reqErr: state.password.reqErr
});

const mapDispatchToProps = {
  sendResetReq,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PasswordResetForm));