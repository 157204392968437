// in src/MyUrlField.js
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';

const styles = {
    link: {
        textDecoration: 'none',
    },
    icon: {
        width: '0.5em',
        paddingLeft: 2,
    },
};


const CustomUrlField = ({ record = {}, source, classes }) =>{

    var LINK_PREFIX;
    if (process.env.REACT_APP_ENV === 'production.v12') {   // in production env
        if (record.chainName === 'ETH' || record.chainName === 'ERCUSDT') {
            LINK_PREFIX = 'https://etherscan.io/tx/'; 
        } else if (record.chainName === 'LTC') {
            LINK_PREFIX = 'https://blockchair.com/litecoin/transaction/';
        } else if (record.chainName === 'TRCUSDT') {
            LINK_PREFIX = 'https://tronscan.org/#/transaction/';
        } else {
            LINK_PREFIX = 'https://www.blockchain.com/btc/tx/';
        }
    } else if (process.env.REACT_APP_ENV === 'development') { // in local dev env
        if (record.chainName === 'ETH' || record.chainName === 'ERCUSDT') {
            LINK_PREFIX = 'https://sepolia.etherscan.io/tx/'
        } else if (record.chainName === 'LTC') {
            LINK_PREFIX = 'https://sochain.com/tx/LTCTEST/';
        } else if (record.chainName === 'TRCUSDT') {
            LINK_PREFIX = 'https://nile.tronscan.org/#/transaction/';
        } else {
            LINK_PREFIX = 'https://live.blockcypher.com/btc-testnet/tx/';
        }
    } else {
        if (record.chainName === 'ETH' || record.chainName === 'ERCUSDT') {
            LINK_PREFIX = 'https://sepolia.etherscan.io/tx/'
        } else if (record.chainName === 'LTC') {
            LINK_PREFIX = 'https://sochain.com/tx/LTCTEST/';
        } else if (record.chainName === 'TRCUSDT') {
            LINK_PREFIX = 'https://nile.tronscan.org/#/transaction/';
        } else {
            LINK_PREFIX = 'https://live.blockcypher.com/btc-testnet/tx/' // in stging env
        }
    } 


    if (record && record.chainTxHash) {
        return (
        <span style={{color: "grey"}}> Link: &nbsp;
        <a href={LINK_PREFIX + record[source]} className={classes.link}>
            {record[source]}
            <LaunchIcon className={classes.icon} />
        </a>
        </span>
        )
    }
    else {
        return(
            <span style={{color: "grey"}}> No Blockchain Transaction  </span>
        )
    }
}
export default withStyles(styles)(CustomUrlField);