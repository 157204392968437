import React from 'react';
import {
    Edit,
    ReferenceField,
    TextField,
    SelectInput,
    NumberInput,
    SimpleForm,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';


const styles = {
    inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
};

const FundingEdit = ({ classes, ...props }) => (
    <Edit title="Funding" {...props}>
        <SimpleForm>

            {/* <CustomDateTimeField source="createdAt" showTime options={{ timeZone:"America/Los_Angeles" }}/> */}
            <TextField source="account_name" label="Account"/>
            <TextField source="fundingType" label="Type" />
            <TextField source="asset" label="Asset" />
            <TextField source="method" label="Method" />
            <NumberInput source="amount" label="Amount" />
            <NumberInput source="fee" label="Fee" />
            <SelectInput source="status" label="Status" choices={[
                { id: 'SUCCESS', name: 'SUCCESS' },
                { id: 'PENDING', name: 'PENDING' },
                { id: 'CANCELED', name: 'CANCELED' },
            ]} />
        </SimpleForm>
    </Edit>
);

export default withStyles(styles)(FundingEdit);
